import React, { useState, useEffect } from "react";
import "./index.scss";
import FloorPlans from "./components/floor-plans";
import galleryApi from "../../apis/api/gallery";
import { FLOOR_TYPE } from "../../constants/master-data";

const PageView = ({ controls, handleUnitClick, isPresentation }) => {
  const [floor, setFloor] = useState([]);
  useEffect(() => {
    handleGetListFloor(FLOOR_TYPE.ORLA_FLOOR);
  }, []);
  const handleGetListFloor = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloor(res.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };
  return (
    <div className="static-page-wf gallery-landing" style={{ backgroundImage: isPresentation ? "none" : undefined }}>
      <FloorPlans data={floor} controls={controls} handleUnitClick={handleUnitClick} isPresentation={isPresentation} />
    </div>
  );
};
export default PageView;
