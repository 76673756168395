import CMSInput from "./cms-input";

const FirstGroupComponents = [
  {
    label: "*Name",
    placeholder: "Residence###",
    type: "text",
    Component: CMSInput,
    onChange: () => null,
    index: "name",
  },
  {
    label: "*Residence",
    placeholder: "AB",
    type: "text",
    Component: CMSInput,
    onChange: () => null,
    index: "floorplanNumber",
  },
  {
    label: "*Floor",
    placeholder: "##",
    type: "text",
    Component: CMSInput,
    onChange: () => null,
    index: "level",
  },
];

const SecondGroupComponents = [
  {
    label: "*Beds",
    placeholder: "#",
    type: "number",
    Component: CMSInput,
    onChange: () => null,
    index: "bedrooms",
  },
  {
    label: "*Baths",
    placeholder: "#",
    type: "number",
    Component: CMSInput,
    onChange: () => null,
    index: "bathrooms",
  },
  {
    label: "*Powder",
    placeholder: "#",
    type: "number",
    Component: CMSInput,
    onChange: () => null,
    index: "powderrooms",
  },
  {
    label: "*SF (Int.)",
    placeholder: "####",
    type: "text",
    Component: CMSInput,
    onChange: () => null,
    index: "interiorSF",
  },
  {
    label: "*SF (Ext.)",
    placeholder: "####",
    type: "text",
    Component: CMSInput,
    onChange: () => null,
    index: "exteriorSF",
  },
  {
    label: "*Price",
    placeholder: "#########",
    type: "text",
    Component: CMSInput,
    onChange: () => null,
    index: "price",
  },
];

const AvailabilityOptions = [
  { label: "Available", value: "available" },
  { label: "Sold", value: "sold" },
  { label: "Reserved", value: "reserved" },
];

const PurchaseOptions = [
  { label: "Primary Residence", value: "primary_residence" },
];

export {
  FirstGroupComponents,
  SecondGroupComponents,
  AvailabilityOptions,
  PurchaseOptions,
};
