import * as THREE from "three";
import JSONfont from "../../fonts/Roos St Regis Text_Regular.json";
import { HOTSPOT_TEXT } from "../../constants/options";

export const useHotspotConfig = ({ rotation, position }) => {
    const font = new THREE.FontLoader().parse(JSONfont);

    const textOptions = {
        font,
        size: 5,
        height: 0,
    };

    const planeConfig = {
      [HOTSPOT_TEXT.EXPLORE_AVAILABILITY] : {
        position: {
          x: position?.x + 3,
          y: position?.y - 2,
          z: position?.z - 4,
        },
        rotation: {
          x: rotation?.x,
          y: rotation?.y + Math.PI,
          z: rotation?.z + Math.PI / 6.4,
        },
        length: 32,
        height: 8,
      },
      [HOTSPOT_TEXT.EXPLORE_BUILDING]: {
        position: {
          x: position?.x + 6,
          y: position?.y + 1,
          z: position?.z - 6,
        },
        rotation: {
          x: rotation?.x,
          y: rotation?.y,
          z: rotation?.z,
        },
        length: 60,
        height: 8,
      },
      [HOTSPOT_TEXT.EXPLORE_CITY]: {
        position: {
          x: position?.x - 2,
          y: position?.y - 3,
          z: position?.z + 3,
        },
        rotation: {
          x: rotation?.x,
          y: rotation?.y + Math.PI,
          z: rotation?.z + Math.PI / 10.4,
        },
        length: 46,
        height: 8,
      }
    }
  
    const textConfig = {
      [HOTSPOT_TEXT.EXPLORE_AVAILABILITY]: {
        position: {
          x: position?.x + 6,
          y: position?.y - 4,
          z: position?.z + 12,
        },
        rotation: {
          x: rotation?.x,
          y: rotation?.y + Math.PI,
          z: rotation?.z + Math.PI / 6.4,
        }
      },
      [HOTSPOT_TEXT.EXPLORE_BUILDING]: {
        position: {
          x: position?.x + 24,
          y: position?.y,
          z: position?.z + 16,
        },
        rotation: {
          x: rotation?.x,
          y: rotation?.y,
          z: rotation?.z,
        }
      },
      [HOTSPOT_TEXT.EXPLORE_CITY]: {
        position: {
          x: position?.x + 2,
          y: position?.y - 4,
          z: position?.z + 25,
        },
        rotation: {
          x: rotation?.x,
          y: rotation?.y + Math.PI,
          z: rotation?.z + Math.PI / 10.4,
        }
      }
    }
  
    return { textConfig, planeConfig, textOptions };
}
