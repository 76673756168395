import React, { useRef, useState, useEffect } from "react";
import {
  Canvas,
  useThree,
  extend,
  useFrame,
  useLoader,
} from "react-three-fiber";
import * as THREE from "three";
import { Vector3, Euler } from "three";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
  AVAILABILITY_OPTIONS,
} from "../../constants/options";
import { rStats } from "../../helper/rStats";
import { glStats, threeStats } from "../../helper/rStats.extras";
import { gsap } from "gsap";
import { getMediaUrl } from "../../helper/media";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
  reqSetIsShowCenterExploreModal,
  reqSetIsExploreAmenities
} from "../../reduxs/explore-modal/action";
import { reqFilterUnitAvailability, reqSetIsShowFilter } from "../../reduxs/unit-explore/action";
import { reqSetActiveTransportOption } from "../../reduxs/home/action";
import { reqSetPage } from "../../reduxs/home/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import { Sky } from './Sky';
import { OrbitCustomControls } from './OrbitCustomControls';
import socket from "../../helper/socket";
import { useHotspotConfig } from "./useHotspotConfig";
import { HOTSPOT_TEXT } from '../../constants/options';

extend({ OrbitCustomControls });
extend({ Sky })

const mapLinkTypeToText = {
  [HOTSPOT_TEXT.EXPLORE_AVAILABILITY]: "Availability",
  [HOTSPOT_TEXT.EXPLORE_BUILDING]: "Explore the Building",
  [HOTSPOT_TEXT.EXPLORE_CITY]: "Explore the City",
}

const CanvasBox = React.memo(
  React.forwardRef((props, refScene) => {
    const {
      _3dSetting,
      controls,
      fbxs,
      hotspots,
      isIntroduction,
      objects,
      targetPosition,
      activeObjectIds,
      setActiveObjectIds,
      isPresentation,
    } = props;
    const dispatch = useDispatch();

    useEffect(() => {
      if (
        !(
          !!objects.length &&
          !!hotspots.length &&
          Object.keys(_3dSetting).length &&
          fbxs.length
        )
      )
        return <div />;
    }, [
      objects,
      hotspots,
      _3dSetting,
      fbxs,
    ]);

    const light = useRef();
    const mesh = useRef();
    let glS, tS, rS;
    let timeVector3 = new Vector3(0, 0, 0);
    let alphaVector3 = new Vector3(0, 0, 0);

    let isCameraAnimation = false;

    let position = new THREE.Vector3();

    let selectedHotspotId;
    let selectedHotspot;
    const [isCameraAnimated, setCameraAnimated] = useState(false);
    let hotspot3Ds = [];
    let hotspotHasChildren = {};
    let pointerDownId;
    let hotspotPointerDownId;

    let meshInstanceMap = {};
    const authUser = useSelector((state) => state.user.data);
    let lastPosition = new THREE.Vector3();
    let lastTarget = new THREE.Vector3();
    let pointerDown = false;

    populateMeshInstanceMapKeys(fbxs);
    associateModelsToMap(objects);

    const refListenSocket = useRef(false);

    const listenerSharedUIAction = ({ content }) => {
      if (content.action === ACTION_NAME.CLICK_HOTSPOT) {
        refListenSocket.current = true;
        handleClickHotspot(content.data.hotspot, content.data.amenitiyHotspots);
        let timeout = setTimeout(() => {
          refListenSocket.current = false;
          clearTimeout(timeout)
        }, 500);
      }
    };

    useEffect(() => {
      if (isPresentation) {
        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      }
      return () => {
        refListenSocket.current = false;
        socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      };
    }, [isPresentation]);

    const sendCameraPos = () => {
      if (!isPresentation && controls.current?.didUpdate) {
        lastPosition.x = controls.current.object.position?.x;
        lastPosition.y = controls.current.object.position?.y;
        lastPosition.z = controls.current.object.position?.z;

        lastTarget.x = controls.current.target?.x;
        lastTarget.y = controls.current.target?.y;
        lastTarget.z = controls.current.target?.z;

        let quaternion = {
          x: controls.current.object.quaternion?.x,
          y: controls.current.object.quaternion?.y,
          z: controls.current.object.quaternion?.z,
          w: controls.current.object.quaternion?.w,
        };  
        socket.emit(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, {
          content: {
            position: lastPosition,
            target: lastTarget,
            quaternion: quaternion,
            zoom: controls.current.object.zoom,
          },
          to: authUser?.id,
          from: authUser?.id,
        });
      }
    };

    function getFbxFileName(fbx) {
      return _.snakeCase(fbx.name);
    }

    const sky = useRef();
    const SkyModel = React.memo(() => {
      const {
        gl,
      } = useThree();
      return <sky ref={sky} scale={[450000, 450000, 450000]} />;
    });

    SkyModel.displayName = 'SkyModel';

    function getModelFileName(model) {
      return _.snakeCase(model["3d_filename"]);
    }

    function populateMeshInstanceMapKeys(fbxs) {
      fbxs.forEach((fbx) => {
        let entry = { model: fbx, instances: [] };
        let key = getFbxFileName(fbx);
        meshInstanceMap[key] = entry;
      });
    }

    function associateModelsToMap(objects) {
      objects.forEach((obj) => {
        // Make assumption that we can remove .fbx from file_name
        let name = getModelFileName(obj);
        if (!meshInstanceMap[name]) {
          console.warn("No FBX File supplied for", obj);
          return;
        }
        meshInstanceMap[name].instances.push(obj);
      });
    }

    function handleAreaClick(controls, camLookAtPosition, camPosition) {
      return controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition
      );
    }

    function updateHotspot() {
      for (let i = 0; i < hotspot3Ds.length; i++) {
        let hotspot3D = hotspot3Ds[i];
        if (!hotspot3D) {
          continue;
        }
        let hotspot = hotspot3D.userData;
        let isVisible = true;
        let isSubHotspot = hotspot.parent_id != null;
        if (isSubHotspot) {
          isVisible = hotspot.parent_id == selectedHotspotId;
        } else if (hotspotHasChildren[hotspot.id]) {
          isVisible = hotspot.id != selectedHotspotId;
        }
        hotspot3D.visible = isVisible;
        if (!isVisible) {
          hotspot3D.layers.set(LAYERS.DISABLE);
        } else {
          hotspot3D.layers.set(hotspot.layer);
        }
      }
    }

    function threePosition(data) {
      return new Vector3(data.x, data.z, -data.y);
    }

    function threePosition2(data, vector) {
      vector.x = data.x;
      vector.y = data.z;
      vector.z = -data.y;
    }

    function setColor(color, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.color.set(color);
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].color.set(color);
            }
          } else {
            child.material.color.set(color);
          }
        }
      });
    }

    function animateAlpha(alpha, object3d) {
      if (alpha == object3d.userData.alpha) {
        return;
      }

      alphaVector3.x = object3d.userData.alpha;
      alphaVector3.y = 0;
      alphaVector3.z = 0;

      gsap.to(alphaVector3, {
        duration: 0.2,
        x: alpha,
        y: 0.0,
        z: 0.0,
        onUpdate: function () {
          setAlpha(alphaVector3.x, object3d);
        },
        onComplete: function () {
          object3d.userData.alpha = alpha;
        },
      });
    }

    function setAlpha(alpha, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.opacity = alpha;
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].opacity = alpha;
            }
          } else {
            child.material.opacity = alpha;
          }
        }
      });
    }

    const handleClickHotspot = async (hotspot, amenitiyHotspots) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_HOTSPOT, {
          hotspot,
          amenitiyHotspots
        });
      }
      // const hotspot = e.object.userData;
      // if (selectedHotspot != null) {
      //   if (selectedHotspot.userData.id != hotspot.id) {
      //     selectedHotspot.material.map = selectedHotspot.userData.texture;
      //   }
      // }
      // if (hotspot.activeTexture) {
      //   e.object.material.map = hotspot.activeTexture;
      // }
      // selectedHotspot = e.object;
      // if (controls.current != null) {
      //   controls.current.selectedHotspot = selectedHotspot;
      // }
      if (hotspot.layer && hotspot.layer[0] === 1) {
        if (hotspot.link) {
          dispatch(reqSetExploreModal(hotspot.link));
          dispatch(reqSetIsExploreAmenities({
            isShown: true,
            modalItems: amenitiyHotspots ? amenitiyHotspots : []
          }));
          return
        }
      }

      switch (hotspot.link_type) {
        case HOTSPOT_TYPES.FUTURE_ITEM:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowCenterExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT_DETAIL:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.AMENITY:
          dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
          break;
        case HOTSPOT_TYPES.LOCATION_PAGE:
          dispatch(reqSetPage(PAGES.LOCATION_PAGE));
          break;
        case HOTSPOT_TYPES.UNIT_EXPLORE:
          dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
          break;
        case HOTSPOT_TYPES.EXPLORE_BUILDING:
          dispatch(reqSetPage(PAGES.EXPLORE_BUILDING_PAGE));
          break;
        case HOTSPOT_TYPES.EXPLORE_CITY:
          dispatch(reqSetPage(PAGES.LOCATION_PAGE));
          break;
        case HOTSPOT_TYPES.EXPLORE_AVAILABILITY:
          dispatch(reqSetIsShowFilter(true));
          dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
          dispatch(reqFilterUnitAvailability([AVAILABILITY_OPTIONS[0]])); // set default to available
          break;
        default:
          break;
      }

      if (hotspot.cam_position && hotspot.cam_focus_point_position) {
        controls.current.lookAtAndMovePosition(
          threePosition(hotspot.cam_focus_point_position),
          threePosition(hotspot.cam_position)
        );
      }

      switch (hotspot.layer) {
        case LAYERS.D_HOTSPOT:
          controls.current.hideLayer(LAYERS.D_HOTSPOT);
          controls.current.showAndEnableLayer(LAYERS.EXPLORE_DISTRICT_HOTSPOT);
        default:
          break;
      }

      if (hotspot.parent_id) {
        return;
      }
      selectedHotspotId = hotspot.id;
      updateHotspot();
    };
    const Hotspot = React.memo((props) => {
      const onPointerOver = () =>
        controls.current && controls.current.setCursorStyle("pointer");
      const onPointerOut = () =>
        controls.current && controls.current.setCursorStyle("grab");
      const webglHotspots = hotspots.map((hotspot) => {
        hotspot.texture = useLoader(
          THREE.TextureLoader,
          getMediaUrl(hotspot.image_path)
        );
        if (hotspot.active_image_path) {
          hotspot.activeTexture = useLoader(
            THREE.TextureLoader,
            getMediaUrl(hotspot.active_image_path)
          );
        }
        return hotspot;
      });
      const { selectedHotspotId } = props;
      hotspot3Ds = [];
      hotspotHasChildren = {};
      let rotation;
      const amenitiyHotspots = hotspots.filter(hotspot => {
        return hotspot.layer[0] === 1
      })
      return (
        <group>
          {webglHotspots.map((hotspot, index) => {
            threePosition2(hotspot.position, position);
            let isVisible = true;
            let isSubHotspot = hotspot.parent_id != null;
            if (isSubHotspot) {
              isVisible = hotspot.parent_id == selectedHotspotId;
              hotspotHasChildren[hotspot.parent_id] = true;
            } else {
              isVisible = hotspot.id != selectedHotspotId;
            }

            const hasLine = hotspot.line_position;
            if (hasLine) {
              const v0_x = hotspot?.line_position_v0?.x || position?.x;
              const v0_y = hotspot?.line_position_v0?.y || position?.y;
              const v0_z = hotspot?.line_position_v0?.z || position?.z;
              hotspot.lineGeometry = new THREE.BufferGeometry().setFromPoints([
                new THREE.Vector3(v0_x, v0_y, v0_z),
                new THREE.Vector3().copy(hotspot.line_position),
              ]);

              const direction = new THREE.Vector3().subVectors(
                new THREE.Vector3().copy(hotspot.line_position),
                new THREE.Vector3(v0_x, v0_y, v0_z)
              );
              rotation = new THREE.Euler().setFromQuaternion(
                new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1, 0, 0), direction.normalize())
              );
            }

            const { textConfig, planeConfig, textOptions } = useHotspotConfig({ rotation, position })

            // Check which hotspots have text line
            const hotspotWithLine = Object.keys(mapLinkTypeToText).includes(hotspot.link_type);

            return (
              <group key={`hotspot-${hotspot?.id}`}>
                {hasLine && (
                  <line
                    geometry={hotspot.lineGeometry}
                    visible={isVisible}
                    layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                  >
                    <lineBasicMaterial color={"black"} />
                  </line>
                )}
                {hotspotWithLine && 
                  <group
                    onPointerOver={() => onPointerOver()}
                    onPointerOut={() => onPointerOut()}
                    userData={hotspot}
                    onPointerDown={() => {
                      hotspotPointerDownId = hotspot.id;
                    }}
                    onPointerUp={(e) => {
                      if (hotspotPointerDownId == hotspot.id) {
                        e.stopPropagation();
                        handleClickHotspot(e?.object.userData);
                      }
                      hotspotPointerDownId = null;
                    }}
                  >
                    {/* The hotspot has text line will render an invisible plane which is clickable to increase the size of clickable hotspot */}
                    <mesh 
                      visible={false}
                      userData={hotspot}
                      rotation={[planeConfig[hotspot.link_type].rotation.x, planeConfig[hotspot.link_type].rotation.y , planeConfig[hotspot.link_type].rotation.z]}
                      position={[planeConfig[hotspot.link_type].position.x, planeConfig[hotspot.link_type].position.y, planeConfig[hotspot.link_type].position.z]}
                    >
                      <planeGeometry args={[planeConfig[hotspot.link_type].length, planeConfig[hotspot.link_type].height]} />
                      <meshBasicMaterial color={"red"} />
                    </mesh>
                    <mesh
                      rotation={[textConfig[hotspot.link_type].rotation.x, textConfig[hotspot.link_type].rotation.y , textConfig[hotspot.link_type].rotation.z]}
                      position={[textConfig[hotspot.link_type].position.x, textConfig[hotspot.link_type].position.y , textConfig[hotspot.link_type].position.z]}
                      {...props}
                      ref={mesh}
                      visible={isVisible}
                      layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                    >
                      {/* Text geometry replaces sprite used due to the default behavior of sprite is to toward the camera */}
                      <textGeometry attach="geometry" args={[mapLinkTypeToText[hotspot.link_type], textOptions]} />
                      <meshStandardMaterial color={"#1A1A1A"} attach="material" />
                    </mesh>
                  </group>
                }
                {!hotspotWithLine && <sprite
                  ref={(r) => {
                    if (r && r.material) {
                      r.material.map.minFilter = THREE.LinearMipMapNearestFilter;
                      r.material.map.magFilter = THREE.LinearFilter;
                      r.material.precision = "highp";
                      r.material.map.needsUpdate = true;
                      r.renderOrder = 1;
                    }
                    hotspot3Ds.push(r);
                  }}
                  visible={isVisible}
                  layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                  onPointerOver={() => onPointerOver()}
                  onPointerOut={() => onPointerOut()}
                  userData={hotspot}
                  onPointerDown={() => {
                    hotspotPointerDownId = hotspot.id;
                  }}
                  onPointerUp={(e) => {
                    if (hotspotPointerDownId == hotspot.id) {
                      e.stopPropagation();
                      handleClickHotspot(e?.object.userData, amenitiyHotspots);
                    }
                    hotspotPointerDownId = null;
                  }}
                  key={index}
                  position={[position?.x, position?.y, position?.z]}
                  scale={[
                    hotspot?.scale?.x || 1,
                    hotspot?.scale?.y || 1,
                    hotspot?.scale?.z || 1,
                  ]}
                >
                  <spriteMaterial
                    sizeAttenuation={false}
                    fog={false}
                    precision="highp"
                    attach="material"
                    map={hotspot.texture}
                  />
                </sprite>}
              </group>
            );
          })}
        </group>
      );
    });
    Hotspot.displayName = "Hotspot";

    const RenderInstance = (instance, model) => {
      let isClickable = false;

      let use_color =
        instance.type == OBJECT_TYPES.DO || instance.type == OBJECT_TYPES.FD;
      let use_texture = instance.use_texture;
      let isActive = activeObjectIds.includes(instance.id);

      model.children.map((mesh_, index) => {
        if (mesh_?.material?.color != null && !use_color) {
          // comment auto selected available filter 
          // let hexString = mesh_.material.color.getHexString();
          // Object.assign(instance, { color: `#${hexString}` });
        }

        const userData = {
          alpha: instance.alpha != null ? instance.alpha / 100.0 : 1.0,
          hover_alpha:
            instance.hover_alpha != null ? instance.hover_alpha / 100.0 : 1,
          active_alpha:
            instance.active_alpha != null ? instance.active_alpha / 100.0 : 1.0,
          color: instance.color ?? "#999999",
          hover_color: instance.hover_color ?? instance.color,
          active_color: instance.active_color ?? instance.color,
          isActive: isActive,
          layer: instance.layer,
        };

        Object.assign(mesh_, { userData: userData, name: instance.id });
      });

      return model.children.map((mesh_, index) => {
        let isTransparency =
          (instance.alpha != null && instance.alpha <= 80.0) ||
          (instance.hover_alpha != null && instance.hover_alpha <= 80.0) ||
          (instance.active_alpha != null && instance.active_alpha <= 80.0);

        if (isActive) {
          setColor(mesh_.userData.active_color, mesh_);
          isTransparency && setAlpha(mesh_.userData.active_alpha, mesh_);
        } else {
          if (!use_texture || use_color) {
            setColor(mesh_.userData.color, mesh_);
          }
          if (!use_texture || isTransparency) {
            setAlpha(mesh_.userData.alpha, mesh_);
          }
        }

        const onPointerOver =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("pointer");
              instance.hover_color &&
                setColor(mesh_.userData.hover_color, mesh_);
              animateAlpha(mesh_.userData.hover_alpha, mesh_);
              mesh_.userData.isHover = true;
            }
            : null;

        const onPointerOut =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("grab");
              if (mesh_.userData.isHover) {
                setColor(mesh_.userData.color, mesh_);
                animateAlpha(mesh_.userData.alpha, mesh_);
                mesh_.userData.isHover = false;
              }
            }
            : null;

        const onPointerDown = (e) => {
          e.stopPropagation();
          pointerDownId = instance.id;
        };

        const onPointerUp = () => {
          pointerDownId == instance.id && onClick != null && onClick();
          pointerDownId = null;
        };

        const onClick = isClickable
          ? async () => {
            setActiveObjectIds([instance.id]);
            dispatch(reqSetActivePrecinctID(null));

            if (instance.cam_position) {
              const camPosition = threePosition(instance.cam_position);
              const camLookAtPosition =
                instance.cam_focus_point_position != null
                  ? threePosition(instance.cam_focus_point_position)
                  : position;
              handleAreaClick(controls, camLookAtPosition, camPosition);
            }
            if (instance?.modal) {
              dispatch(reqSetIsShowExploreModal(true));
              dispatch(reqSetExploreModal(instance?.modal));
            }
            if (instance?.sub_precinct) {
              dispatch(reqSetActiveTransportOption([instance?.sub_precinct]));
            } else {
              dispatch(reqSetActiveTransportOption([]));
            }
          }
          : null;

        let meshInstance = (
          <mesh
            key={index}
            {...mesh_}
            layers={instance.layer != null ? instance.layer : null}
            userData={mesh_.userData}
            name={instance.id}
            onPointerDown={instance.type && onPointerDown}
            onPointerUp={instance.type && onPointerUp}
            onPointerOut={instance.type && onPointerOut}
            onPointerOver={instance.type && onPointerOver}
          />
        );
        return meshInstance;
      });
    };

    function FbxModel() {
      if (!isIntroduction) {
        return <group />;
      }

      if (light.current != null) {
        light.current.layers.enableAll();
      }

      return (
        <group ref={refScene}>
          {Object.keys(meshInstanceMap).map((entry) => {
            const targetMap = meshInstanceMap[entry];
            if (!targetMap) return;
            const model = targetMap.model;
            const instances = targetMap.instances;
            return instances.map((instance) => {
              return RenderInstance(instance, model);
            });
          })}
        </group>
      );
    }

    const AnimationCamera = React.memo((props) => {
      const { animation3dSetting, controls } = props;
      const { camera } = useThree();

      new THREE.Vector3(
        -102.89578369966134,
        -1.1178292546754195e-14,
        131.5388245709879
      );
      const targetPosition =
        animation3dSetting != null && animation3dSetting.cam_position != null
          ? threePosition(animation3dSetting.cam_position)
          : new THREE.Vector3(
            -92.46747002504912,
            260.2837561175679,
            391.6135906913746
          );
      const delta = new Vector3(
        -200 - targetPosition.x,
        270 - targetPosition.y,
        -630 - targetPosition.z
      );

      const pipeSpline = new THREE.CatmullRomCurve3([
        new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z),
        new THREE.Vector3(200 - delta.x, 330 - delta.y, -226 - delta.z),
        new THREE.Vector3(0 - delta.x, 285 - delta.y, -331 - delta.z),
        targetPosition,
      ]);

      setCameraAnimated(true);

      camera.position.copy(
        new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z)
      );
      camera.updateProjectionMatrix();

      timeVector3.x = 0;
      timeVector3.y = 0;
      timeVector3.z = 0;

      return <group />;
    });
    AnimationCamera.displayName = "AnimationCamera";

    const CameraControls = React.memo(() => {
      const { camera, gl, raycaster } = useThree();
      const domElement = gl.domElement;

      gl.localClippingEnabled = true;
      gl.info.autoReset = false;
      // Set max canvas resolution to 1080p without forcing container style updates
      useThree().gl.setSize(
        Math.min(window.innerWidth, 1280),
        Math.min(window.innerHeight, 720),
        false
      );

      if (isIntroduction) {
        glS = new glStats(); // init at any point
        tS = new threeStats(gl); // init after WebGLRenderer is created

        rS = new rStats({
          userTimingAPI: true,
          values: {
            frame: { caption: "Total frame time (ms)", over: 16 },
            fps: { caption: "Framerate (FPS)", below: 30 },
            calls: { caption: "Calls (three.js)", over: 3000 },
            raf: { caption: "Time since last rAF (ms)" },
            rstats: { caption: "rStats update (ms)" },
          },
          groups: [
            { caption: "Framerate", values: ["fps", "raf"] },
            {
              caption: "Frame Budget",
              values: ["frame", "texture", "setup", "render"],
            },
          ],
          fractions: [{ base: "frame", steps: ["action1", "render"] }],
          plugins: [tS, glS],
        });

        useFrame(({ gl, scene, camera }) => {
          rS("frame").start();
          glS.start();

          rS("frame").start();
          rS("rAF").tick();
          rS("FPS").frame();

          rS("action1").start();
          rS("action1").end();

          rS("render").start();
          gl.render(scene, camera);
          rS("render").end();

          rS("frame").end();
          rS().update();

          gl.info.reset();
        }, 1);
      }

      useFrame(() => {
        if (controls.current?.needReloadSelectedHotspotId) {
          selectedHotspotId = "";
          updateHotspot();
          controls.current.needReloadSelectedHotspotId = false;
        }

        sendCameraPos();

        if (!isCameraAnimation && isCameraAnimated) {
          if (controls != null && controls.current != null && !isPresentation) {
            controls.current.update();
          }
          return;
        }
      });

      let x = targetPosition?.x;
      let y = targetPosition?.y;
      let z = targetPosition?.z;

      if (camera.targetPosition) {
        x = camera.targetPosition?.x;
        y = camera.targetPosition?.y;
        z = camera.targetPosition?.z;
      }

      return (
        <orbitCustomControls
          ref={controls}
          args={[camera, domElement, [0, 0, 0], [0, 0, 0]]}
          raycaster={raycaster}
          disabledUpdate={isIntroduction && !isCameraAnimated}
          // disabledUpdate={false}
          neverUpdate={false}
          autoRotate={false}
          enableDamping={true}
          maxDistance={1000}
          minDistance={2}
          zoomSpeed={2}
          rotateSpeed={0.8}
          minZoom={_3dSetting.minZoom ?? 0.2}
          maxZoom={_3dSetting.maxZoom ?? 8}
          minHeight={10}
          maxHeight={1000}
          movingCurveSpeed={1 ?? 0.5}
        />
      );
    });
    CameraControls.displayName = 'CameraControls';

    return (
      <>
        <Canvas
          style={{ backgroundColor: "#dbdbdb" }}
          gl={{
            outputEncoding: THREE.sRGBEncoding,
            logarithmicDepthBuffer: true,
            outputEncoding: THREE.sRGBEncoding,
          }}
          pixelRatio={Math.max(window.devicePixelRatio, 2)}
          camera={{
            position: [
              1020 + _3dSetting.cam_position.x,
              540 + _3dSetting.cam_position.z,
              630 - _3dSetting.cam_position.y,
            ],
            fov: _3dSetting.FOV,
            near: 1,
            far: 10000,
          }}
          onPointerEnter={(e) => pointerDown = true}
          onPointerLeave={(e) => pointerDown = false}
          onTouchStart={(e) => pointerDown = true}
          onTouchEnd={(e) => pointerDown = false}
          onWheel={(e) => sendCameraPos()}
        >
          {isIntroduction && !isCameraAnimated && !isPresentation && (
            <AnimationCamera
              animation3dSetting={_3dSetting}
              controls={controls}
            />
          )}
          <ambientLight intensity={10} color={0x2e2e2a} />
          {true && (
            <hemisphereLight
              intensity={0.4}
              skyColor={0xb1e1ff}
              groundColor={0x2e2e2a}
              position={[0, -10, 0]}
            />
          )}
          <directionalLight
            ref={light}
            intensity={1.6}
            color={0xffffff}
            position={[-1500, 600, 250]}
          />
          {true && (
            <directionalLight
              intensity={0.7}
              color={0xffffff}
              position={[1500, 600, -250]}
            />
          )}
          <React.Suspense fallback={null}>
            <FbxModel />
            <Hotspot selectedHotspotId={selectedHotspotId} />
          </React.Suspense>
          <CameraControls />
          <SkyModel />
        </Canvas>
      </>
    );
  })
);

CanvasBox.displayName = "CanvasBox";

export default CanvasBox;
