import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import "./index.scss";

const VideoIntro = () => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();

  const onSkipVideo = () => {
    dispatch(reqSetIsShowReplayVideo(false));
  };

  return (
    <div className="wrap-video-intro">
      <video
        ref={videoRef}
        autoPlay={true}
        onEnded={onSkipVideo}
        preload="auto"
        id="intro-video-2"
        style={{ backgroundImage: `url("/images/video_bg.png")` }}
      >
        <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
      </video>
      <div className="btn-skip-video ">
        <button onClick={onSkipVideo} className="skip-video">
          SKIP VIDEO
          <img src="./images/icons/arrow-left.svg" />
        </button>
      </div>
    </div>
  );
};

export default VideoIntro;
