import * as yup from 'yup';
import Select from 'react-select';
import { toast } from "react-toastify";
import './submit-support-ticket-modal.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransparentModal } from "./transparent-modal";
import supportApi from '../../apis/api/request-support';
import { reqGetUserProfile } from "../../reduxs/user/action";
import { SUPPORT_QUESTION_TYPES } from '../../constants/master-data';

const validationSchema = yup.object().shape({
  questionType: yup.string().trim().required("Question type is a required field"),
  subject: yup.string().trim().required("Subject is a required field"),
  message: yup.string().trim().required("Message cannot be empty"),
});

export const SubmitSupportTicketModal = (
  { show, setShow }
) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);

  const [questionType, setQuestionType] = useState();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(reqGetUserProfile());
    setQuestionType('');
  }, []);

  const onClose = () => {
    setQuestionType(undefined);
    setSubject('');
    setMessage('');
    setErrors({})
    setShow(false);
  }

  const onSubmit = async () => {
    try {
      const data = {
        questionType,
        subject,
        message,
      };
      setErrors({});
      const result = await validationSchema.validate(data, { abortEarly: false });
      const payload = {
        request: {
          requester: {
            name: authUser?.name,
          },
          subject: result.subject,
          comment: {
            body: `${result.questionType}\n${result.message}`,
          },
        },
      };
      const res = await supportApi.sendSupportTicket(payload);
      if (res?.request?.id) {
        toast.success("Support ticket submitted!");
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      if (err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      } else {
        toast.error("Failed to submit ticket. Please try again!");
      }
    }
  };

  return (
    <TransparentModal
      style={{ backgroundColor: '#1A1A1ABF' }}
      open={show} onClose={onClose} fill={'white'}>
      <div className="sst-wrapper">
        <div className="sst-container">
          <div className="sst-title">
            Submit Support Ticket
          </div>
          <div className="sst-form">
            <div className="form-row">
              <div className="form-input">
                <span>*Question Type</span>
                {/* <input
                  value={questionType}
                  onChange={(e) => setQuestionType(e.target.value)}
                  className="form-control"
                  type="text"
                  placeholder="Select a question type"
                /> */}
                <Select
                  placeholder="Select a question type"
                  className="question-type-select"
                  classNamePrefix="select"
                  value={questionType ? { value: questionType, label: `About ${questionType} ?` } : null}
                  onChange={(e) => setQuestionType(e.value)}
                  options={SUPPORT_QUESTION_TYPES}
                  menuPortalTarget={document.getElementsByClassName("modal-content")[0]}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
                <span className="error">{errors?.questionType}</span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-input">
                <span>*Subject</span>
                <input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="form-control"
                  type="text"
                  placeholder="Subject"
                />
                <span className="error">{errors?.subject}</span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-input">
                <span>*Message</span>
                <input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="form-control"
                  type="text"
                  placeholder="What would you like help with"
                />
                <span className="error">{errors?.message}</span>
              </div>
            </div>
          </div>
          <div className="sst-footer">
            <button className="cms-search-btn" onClick={onSubmit}>
              <span className="cms-search-btn-text">
                SUBMIT
              </span>
            </button>
          </div>
        </div>
      </div>
    </TransparentModal>
  )
};
