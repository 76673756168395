import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reqSetIsExpandNav,
} from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetIsShowFilter,
  reqSetIsShowUnitList,
} from "../../reduxs/unit-explore/action";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { PAGES, ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";

import "./index.scss";
import { MAX, MIN } from "../unit-explore/UnitFilter";

library.add(faSearch);

const TopNav = ({
  isShow,
  isLoading,
  isPresentation,
  handleClickResidence,
  handleClickCube,
  handleClickExperience,
  handleLifeStyle,
  handleTeam,
  handleSearch,
  handleAmenities,
  handleStory,
  handleClickGallery,
  handleClickLocation,
  handleClickVision,
  handleClickView,
  
}) => {
  const dispatch = useDispatch();
  const [isCollapse, setIsCollapse] = useState(true);
  const [firstShow, setFirstShow] = useState(true);
  const [isDragDown, setIsDragDown] = useState(false);
  const [isHideCubeOnDrag, setIsHideCubeOnDrag] = useState(true);
  const pageActive = useSelector((state) => state.home.page);
  const [linksDisabled, setLinksDisabled] = useState(true);
  const isShowVideo = useSelector((state) => state.home.isShowVideo);
  const page = useSelector((state) => state.home.page);

  useEffect(() => {
    if (isPresentation) {
-      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_LOGO) {
          handleClickLogo();
        }
      });
    }
  }, []);

  const setClickTimer = (localCollapsed) => {
    if (localCollapsed) {
      setLinksDisabled(true);
      return;
    }
    setTimeout(() => {
      setLinksDisabled(false);
    }, 700);
  };

  const onClickCube = () => {
    handleClickCube();
    setIsCollapse(false);
    setClickTimer(false);
  };

  const startDrag = () => {
    setIsHideCubeOnDrag(false);
  };

  const endDrag = () => {
    setIsHideCubeOnDrag(true);
    setIsDragDown(true);
    setFirstShow(false);
    setIsCollapse(!isCollapse);
    setClickTimer(!isCollapse);
  };

  const isActive = (menu) => {
    return pageActive === menu ? "active" : "";
  };

  const handleClickLogo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LOGO);
    }
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitPrice({
        min: 0,
        max: 0,
      }));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    dispatch(unitExploreAct.reqFilterUnitLotSize({
        min: MIN,
        max: MAX,
      }));
    dispatch(reqSetIsExpandNav(true));
    dispatch(reqSetIsShowFilter(false));
    dispatch(reqSetIsShowUnitList(false));
    onClickCube();
  };

  return (
    <div className={`wrap-header-navigation ${isPresentation ? "hide": ""}`}>
      <img
        src="./images/logo-brickell.png"
        className="img-logo"
        onClick={!isLoading ? handleClickLogo : () => {}}
      />
      {((pageActive !== PAGES.ONBOARD_PAGE || isShowVideo) && !isLoading) && (
        <div className="menu-items">
          <ul>
            <li
              className={isActive(PAGES.VISION_PAGE)}
              onClick={handleClickVision}
            >
              <span>VISION</span>
            </li>
            <li
              onClick={handleClickResidence}
              className={isActive(PAGES.RESIDENCES_PAGE)}
            >
              <span>RESIDENCES</span>
            </li>
            <li
              className={isActive(PAGES.AMENITIES_PAGE)}
              onClick={handleAmenities}
            >
              <span>AMENITIES</span>
            </li>
            <li
              className={isActive(PAGES.EXPERIENCE_PAGE)}
              onClick={handleClickExperience}
            >
              <span>EXPERIENCE</span>
            </li>
            <li className={isActive(PAGES.VIEW_PAGE)} onClick={handleClickView}>
              <span>VIEWS</span>
            </li>
            <li
              className={isActive(PAGES.LOCATION_PAGE)}
              onClick={handleClickLocation}
            >
              <span>MIAMI</span>
            </li>
            <li className={isActive(PAGES.TEAM_PAGE)} onClick={handleTeam}>
              <span>TEAM</span>
            </li>
            <li
              className={isActive(PAGES.GALLERY_LANDING_PAGE)}
              onClick={handleClickGallery}
            >
              <span>MEDIA</span>
            </li>
            {/* <li onClick={handleSearch}>
              <span>SEARCH</span>
            </li> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TopNav;
