import React from "react";

export const ChevronLeft = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" {...rest}>
      <path
        d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/>
    </svg>
  )
};
export const ChevronDown = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" {...rest}>
      <path
        d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"/>
    </svg>
  )
};
export const ChevronUp = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" {...rest}>
      <path
        d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z"/>
    </svg>
  )
};
export const NavChevronLeft = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 50" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M31.8525 13.5375L29.406 11L17.8726 22.9625L15.4261 25.5L17.8726 28.0375L29.406 40L31.8525 37.4625L20.3191 25.5L31.8525 13.5375Z"
            fill="#1A1A1A"/>
    </svg>
  )
};
export const NavChevronRight = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 50" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16.3537 13.5375L18.8002 11L30.3336 22.9625L32.78 25.5L30.3336 28.0375L18.8002 40L16.3537 37.4625L27.8871 25.5L16.3537 13.5375Z"
            fill="#1A1A1A"/>
    </svg>
  )
};

export const IconSwitchLevels = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20" {...rest}>
      <g id="Floor stack">
        <path id="Vector"
              d="M5.15727e-05 7.48527C-0.00159379 7.33939 0.0361586 7.19576 0.109327 7.06955C0.182496 6.94333 0.288369 6.8392 0.415782 6.76813L12.0562 0.116455C12.1815 0.0402842 12.3253 0 12.4719 0C12.6186 0 12.7624 0.0402842 12.8877 0.116455L24.5281 6.76813C24.6532 6.84149 24.7568 6.94627 24.8289 7.07206C24.9009 7.19786 24.9388 7.3403 24.9388 7.48527C24.9388 7.63023 24.9009 7.77268 24.8289 7.89847C24.7568 8.02427 24.6532 8.12904 24.5281 8.2024L12.8877 14.8541C12.762 14.9292 12.6183 14.9688 12.4719 14.9688C12.3256 14.9688 12.1819 14.9292 12.0562 14.8541L0.415782 8.2024C0.288369 8.13134 0.182496 8.0272 0.109327 7.90099C0.0361586 7.77477 -0.00159379 7.63115 5.15727e-05 7.48527ZM23.6967 10.9254L12.4719 17.3381L1.24724 10.9254C1.05854 10.8349 0.842654 10.8189 0.642664 10.8806C0.442675 10.9422 0.273296 11.0771 0.168335 11.2581C0.063374 11.4392 0.0305516 11.6532 0.0764191 11.8573C0.122287 12.0615 0.24347 12.2409 0.415782 12.3597L12.0562 19.0114C12.1819 19.0865 12.3256 19.1261 12.4719 19.1261C12.6183 19.1261 12.762 19.0865 12.8877 19.0114L24.5281 12.3597C24.6315 12.3101 24.7235 12.2397 24.7984 12.1528C24.8732 12.066 24.9293 11.9646 24.9631 11.855C24.9969 11.7454 25.0076 11.6301 24.9947 11.5161C24.9817 11.4022 24.9454 11.2922 24.8879 11.193C24.8304 11.0938 24.753 11.0076 24.6605 10.9397C24.5681 10.8719 24.4626 10.8239 24.3508 10.7988C24.2389 10.7736 24.123 10.7719 24.0105 10.7937C23.8979 10.8155 23.7911 10.8604 23.6967 10.9254Z"
              fill={fill || '#01344B'}/>
      </g>
    </svg>
  )
};

export const IconCloseButton = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#FFFFFF'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" {...rest}>
      <path
        d="M29 2.5375L26.4625 0L14.5 11.9625L2.5375 0L0 2.5375L11.9625 14.5L0 26.4625L2.5375 29L14.5 17.0375L26.4625 29L29 26.4625L17.0375 14.5L29 2.5375Z"
        fill={fill || '#FFFFFF'}/>
    </svg>
  )
};

export const IconArrowThin = ({fill = '', style = null, ...rest}) => {
  return (
    <svg
      style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" width="33" height="17"
      viewBox="0 0 33 17"  {...rest}>
      <line y1="-0.5" x2="30" y2="-0.5" transform="matrix(-1 0 0 1 31.6289 8.84741)" stroke="#B2B2B2"/>
      <path d="M0.628493 8.70784L8.8394 0.497763" stroke="#B2B2B2"/>
      <path d="M0.629319 7.9969L8.8394 16.2078" stroke="#B2B2B2"/>
    </svg>
  )
};

export const IconSearch = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" width="26" height="26"
         viewBox="0 0 26 26" {...rest}>
      <path
        d="M9.57924 18.1429C14.3131 18.1429 18.1507 14.3053 18.1507 9.57143C18.1507 4.83756 14.3131 1 9.57924 1C4.84537 1 1.00781 4.83756 1.00781 9.57143C1.00781 14.3053 4.84537 18.1429 9.57924 18.1429Z"
        stroke="#B2B2B2" strokeWidth="1.19048" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.1284 25.0008L15.5781 15.5723" stroke="#B2B2B2" strokeWidth="1.19048" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  )
};
export const IconPlusSign = ({fill = '', style = null, ...rest}) => {
  return (
    <svg style={style} fill={fill || '#0000'} xmlns="http://www.w3.org/2000/svg" width="24" height="24"
         viewBox="0 0 24 24" {...rest}>
      <path
        d="M12.0078 22.5C9.22304 22.5 6.55232 21.3938 4.58319 19.4246C2.61406 17.4555 1.50781 14.7848 1.50781 12C1.50781 9.21523 2.61406 6.54451 4.58319 4.57538C6.55232 2.60625 9.22304 1.5 12.0078 1.5C14.7926 1.5 17.4633 2.60625 19.4324 4.57538C21.4016 6.54451 22.5078 9.21523 22.5078 12C22.5078 14.7848 21.4016 17.4555 19.4324 19.4246C17.4633 21.3938 14.7926 22.5 12.0078 22.5ZM12.0078 24C15.1904 24 18.2427 22.7357 20.4931 20.4853C22.7435 18.2348 24.0078 15.1826 24.0078 12C24.0078 8.8174 22.7435 5.76516 20.4931 3.51472C18.2427 1.26428 15.1904 0 12.0078 0C8.82521 0 5.77297 1.26428 3.52253 3.51472C1.27209 5.76516 0.0078125 8.8174 0.0078125 12C0.0078125 15.1826 1.27209 18.2348 3.52253 20.4853C5.77297 22.7357 8.82521 24 12.0078 24Z"
        fill="#B2B2B2"/>
      <path
        d="M12.0078 6C12.2067 6 12.3975 6.07902 12.5381 6.21967C12.6788 6.36032 12.7578 6.55109 12.7578 6.75V11.25H17.2578C17.4567 11.25 17.6475 11.329 17.7881 11.4697C17.9288 11.6103 18.0078 11.8011 18.0078 12C18.0078 12.1989 17.9288 12.3897 17.7881 12.5303C17.6475 12.671 17.4567 12.75 17.2578 12.75H12.7578V17.25C12.7578 17.4489 12.6788 17.6397 12.5381 17.7803C12.3975 17.921 12.2067 18 12.0078 18C11.8089 18 11.6181 17.921 11.4775 17.7803C11.3368 17.6397 11.2578 17.4489 11.2578 17.25V12.75H6.75781C6.5589 12.75 6.36813 12.671 6.22748 12.5303C6.08683 12.3897 6.00781 12.1989 6.00781 12C6.00781 11.8011 6.08683 11.6103 6.22748 11.4697C6.36813 11.329 6.5589 11.25 6.75781 11.25H11.2578V6.75C11.2578 6.55109 11.3368 6.36032 11.4775 6.21967C11.6181 6.07902 11.8089 6 12.0078 6Z"
        fill="#B2B2B2"/>
    </svg>
  )
};

export const IconStar = ({fill = '', style = null, ...rest}) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill={fill || "#767676"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.25"
        d="M28.0627 9.8596L19.3691 8.58214L15.4828 0.616237C15.3766 0.398135 15.202 0.221576 14.9863 0.114257C14.4453 -0.155774 13.7879 0.0692515 13.5174 0.616237L9.63108 8.58214L0.937449 9.8596C0.697766 9.89422 0.478627 10.0085 0.310849 10.1816C0.108015 10.3923 -0.00375586 10.6759 9.63727e-05 10.97C0.0039486 11.264 0.123109 11.5445 0.331393 11.7498L6.62135 17.9501L5.13532 26.7054C5.10047 26.909 5.12276 27.1185 5.19967 27.31C5.27657 27.5015 5.405 27.6674 5.57041 27.7889C5.73581 27.9103 5.93157 27.9825 6.13548 27.9972C6.33939 28.0119 6.5433 27.9685 6.72408 27.872L14.5001 23.7385L22.2761 27.872C22.4884 27.9863 22.7349 28.0244 22.9711 27.9828C23.5669 27.879 23.9675 27.3077 23.8648 26.7054L22.3788 17.9501L28.6687 11.7498C28.8399 11.5802 28.9529 11.3586 28.9872 11.1163C29.0796 10.5104 28.6619 9.94961 28.0627 9.8596ZM19.732 17.0777L20.9681 24.3582L14.5001 20.9239L8.03206 24.3616L9.26814 17.0812L4.0362 11.9229L11.2678 10.8601L14.5001 4.23742L17.7324 10.8601L24.9639 11.9229L19.732 17.0777Z"
        fill={fill || "#767676"}
      />
    </svg>
  )
};

export const IconStarFill = ({fill = '', style = null, ...rest}) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0627 9.8596L19.3691 8.58214L15.4828 0.616237C15.3766 0.398135 15.202 0.221577 14.9863 0.114257C14.4453 -0.155774 13.7879 0.0692515 13.5174 0.616237L9.63108 8.58214L0.937449 9.8596C0.697766 9.89422 0.478627 10.0085 0.310849 10.1816C0.108015 10.3923 -0.00375586 10.6759 9.63728e-05 10.97C0.0039486 11.264 0.123109 11.5445 0.331393 11.7498L6.62135 17.9501L5.13532 26.7054C5.10047 26.909 5.12276 27.1185 5.19967 27.31C5.27657 27.5015 5.405 27.6674 5.57041 27.7889C5.73581 27.9103 5.93157 27.9825 6.13548 27.9972C6.33939 28.0119 6.5433 27.9685 6.72408 27.872L14.5001 23.7385L22.2761 27.872C22.4884 27.9863 22.7349 28.0244 22.9711 27.9828C23.5669 27.879 23.9675 27.3077 23.8648 26.7054L22.3788 17.9501L28.6687 11.7498C28.8399 11.5802 28.9529 11.3586 28.9872 11.1163C29.0796 10.5104 28.6619 9.94961 28.0627 9.8596Z"
        fill={fill || "#FFD494"}
      />
    </svg>
  );
}
