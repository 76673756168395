import React, { useRef, useEffect } from "react";
import SideNav from "../sidenav";
import { useDispatch, useSelector } from "react-redux";
import { reqSetIsShowFilter } from "../../reduxs/unit-explore/action";
import { reqSetPage } from "../../reduxs/home/action";
import {
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
  PAGES,
} from "../../constants/options";
import socket from "../../helper/socket";

const UnitList = (props) => {
  const { handleUnitClick, isPresentation } = props;
  const refs = useRef({});
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const units = useSelector((state) => state.unitExplore.units);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_FILTER) {
          onShowFilter();
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollPercentage * (pageRef.current.scrollHeight - pageRef.current.clientHeight);
          }
        }
      });
    }
  }, []);

  const onShowFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_FILTER);
    }
    dispatch(reqSetIsShowFilter(true));
  };

  const checkNumber = (char) => !isNaN(parseInt(char));

  const sortedUnits = units.sort((a, b) => {
    const unitA = /^\d/.test(a.name);
    const unitB = /^\d/.test(b.name);

    if (unitA && unitB) {
      return parseInt(a.name) - parseInt(b.name); // Both start with numbers, sort numerically
    } else if (unitA) {
      return -1;  // A starts with a number, comes before B
    } else if (unitB) {
      return 1; // B starts with a number, comes before A
    } else {
      // Both start with characters, sort alphabetically
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  });

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  }

  const handleShowFilter = () => {
    !isShowFilter && onShowFilter();
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  }

  if (isPresentation) {
    return null
  }

  return (
    <SideNav heading="Residences" position="left" id="lots" isDark={false}>
      <ul className="list-group scroll-unit" ref={pageRef} onScroll={onScroll}>
        {sortedUnits.map((item, key) => {
          return (
            <li
              className={`list-group-item ${selectedUnit?.id == item.id ? "active" : ""}`}
              key={key}
              onClick={() => handleUnitClick(item)}
              ref={(ref) => (refs.current[item.id] = ref)}
            >
              {`Residences ${item.name}`}
            </li>
          );
        })}
      </ul>
      <div
        className={`wrap-show-filter-btn ${isShowFilter ? "text-gray" : ""}`}
      >
        <div
          className="btn-bottom"
          onClick={handleShowFilter}
        >
          <span className={!isShowFilter && "txt-active"}>Show filter</span>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitList;
