import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUploadedMediaUrl, getUploadedServerMediaUrl } from "../../helper/media";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import galleryApi from "../../apis/api/gallery";
import { useDispatch } from "react-redux";
import {
  reqSetActivePatourId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import socket from "../../helper/socket";

const Gallery = ({ isPresentation }) => {
  const [media, setMedia] = useState([]);
  const dispatch = useDispatch();
  const galleryRef = useRef();
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
    beforeChange: (current, next) => {
      if (!isPresentation && socket) {
        socket.emitUIActionEvent(ACTION_NAME.GO_TO_SLIDE_GALLERY_UNIT_DETAIL, {
          next
        });
      }
    }
  };

  const HOTSPOT_TYPES = {
    PANO_TOUR: "360_pano_tour",
    URL: "url",
    VIDEO: "video",
  };

  useEffect(async () => {
    if (!selectedUnit?.id) {
      return;
    }
    const staticGallery = await galleryApi.getGalleryDetail(activeGalleryId);
    const staticMedia = staticGallery.data?.media.map(({path, ...rest}) => ({...rest, path: getUploadedMediaUrl(path)})) || []

    const res = await galleryApi.getListGallery({"unitId[equal]": selectedUnit.id});
    const galleries = res.data;
    const galleryMedia = (galleries?.map((gallery) => gallery.media[0]) || []).filter(e => e.isActive).map(({path, ...rest}) => ({...rest, path: getUploadedServerMediaUrl(path)})) || []

    setMedia([...galleryMedia, ...staticMedia]);
  }, [selectedUnit?.id]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLOSE_GALLERY_UNIT_DETAIL) {
          hideGallery();
        }
        if (content.action === ACTION_NAME.REOPEN_FILTER_GALLERY_UNIT_DETAIL) {
          showFilter();
        }
        if (content.action === ACTION_NAME.GO_TO_SLIDE_GALLERY_UNIT_DETAIL) {
          onGoToSlideIndex(content?.data?.next)
        }
      });
    }
  }, []);

  const handleClickHotspot = async (data) => {
    const hotpotData = data;

    if (hotpotData.link_type == HOTSPOT_TYPES.PANO_TOUR) {
      dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
      dispatch(reqSetActivePatourId(hotpotData.link));
    }
  };

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.REOPEN_FILTER_GALLERY_UNIT_DETAIL);
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_UNIT_DETAIL);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  }

  
  return (
    <>
      <div className="wrap-gallery-image" key={media?.[0]?.id}>
        <Slider ref={galleryRef} {...settings}>
          {(media || []).map((item) => {
            return (
              <div key={item.id}>
                <div
                  className="gallery-item"
                  style={{
                    backgroundImage: `url("${item.path}")`,
                  }}
                >
                  {(item?.hotspots || []).map((item, index) => {
                    return (
                      <img
                        onClick={() => handleClickHotspot(item)}
                        key={index}
                        style={{
                          left: `${item.position?.x}%`,
                          top: `${item.position?.y}%`,
                        }}
                        className="gallery-hotspot"
                        src={item.path}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
        {/*{*/}
        {/*  <span className="btn-gallery prev-slide">*/}
        {/*    <img*/}
        {/*      width="50"*/}
        {/*      className="img-fluid"*/}
        {/*      src="/icons/right-arrow-2.svg"*/}
        {/*      alt=""*/}
        {/*      onClick={() => galleryRef.current.slickPrev()}*/}
        {/*    />*/}
        {/*  </span>*/}
        {/*}*/}
        {/*{*/}
        {/*  <span className="btn-gallery next-slide">*/}
        {/*    <img*/}
        {/*      width="50"*/}
        {/*      className="img-fluid"*/}
        {/*      src="/icons/right-arrow-2.svg"*/}
        {/*      alt=""*/}
        {/*      onClick={() => galleryRef.current.slickNext()}*/}
        {/*    />*/}
        {/*  </span>*/}
        {/*}*/}
      </div>
      {isTransparent && (
        <div className="wrap-close-btn">
          <div onClick={hideGallery}>
            <img src="./images/icons/arrow-left.svg" alt="" className="rotate"/>
            <span className="txt-white">Close gallery</span>
          </div>
          <div onClick={showFilter}>
            <img src="/icons/add.svg" alt="" />
            <span className="txt-white">Reopen panels</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
