import * as type from './type';

const initialState = {
  modal: '',
  isShowExploreModal: false,
  isShowCenterPanel: false,
  isExploreAmenities: {
    isShown: false,
    modalItems: []
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_EXPLORE_MODAL:
      return {
        ...state,
        modal: action.data.data,
      };
    case type.SET_IS_SHOW_EXPLORE_MODAL:
      return {
        ...state,
        isShowExploreModal: action.data,
      };
      case type.SET_IS_SHOW_CENTER_EXPLORE_MODAL:
        return {
          ...state,
          isShowCenterPanel: action.data,
        };

      case type.SET_IS_EXPLORE_AMENITIES: 
        return {
          ...state,
          isExploreAmenities: action.data
        }
    default:
      return state;
  }
};
