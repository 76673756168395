import React, { useEffect, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  reqSetIsShowExploreModal,
  reqSetIsShowCenterExploreModal,
  reqSetIsExploreAmenities,
  reqSetExploreModal
} from "../../reduxs/explore-modal/action";
import parse from "html-react-parser";
import {
  reqSetActiveAreaId,
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
} from "../../reduxs/home/action";
import { PAGES, WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import { reqFilterUnitPrecinct } from "../../reduxs/unit-explore/action";
import { reqSetIsShowPrecinctExploreDetail } from "../../reduxs/precinct-explore/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import {
  reqSetActiveGalleryModal,
  reqSetIsShowGalleryModal,
} from "../../reduxs/district-future-detail/action";
import GalleryDetail from "../gallery/_gallery-detail";
import { USER_GROUP } from "../../constants/master-data";
import socket from "../../helper/socket";

const RightPanel = (props) => {
  const { handleClickTransportOptions, page, isPresentation } = props;
  const [dataModal, setDataModal] = useState();
  const isShowRightPanel = useSelector(
    (state) => state.exploreModal.isShowExploreModal
  );
  const isShowCenterPanel = useSelector(
    (state) => state.exploreModal.isShowCenterPanel
  );
  const isExploreAmenities = useSelector(
    (state) => state.exploreModal.isExploreAmenities.isShown
  );

  const totalAmenities = useSelector(
    (state) => state.exploreModal.isExploreAmenities.modalItems
  );

  const modal = useSelector((state) => state.exploreModal.modal);
  const dispatch = useDispatch();
  const isShowGallery = useSelector(
    (state) => state.districtdetail.isShowGalleryModal
  );
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);

  useEffect(() => {
    if(modal){
      setDataModal(modal)
    }
  }, [modal])

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {

        if (content.action === ACTION_NAME.CLOSE_CENTER_MODAL_RIGHT_PANEL) {
          handleCloseCenterModal();
        }

        if (content.action === ACTION_NAME.CLOSE_AMENITIES_MODAL) {
          handleCloseAmentities();
        }
        if (content.action === ACTION_NAME.NAVIGATE_AMENITY_GALLERY) {
          onChangeModal(content.data.isNext, content.data.totalAmenities, content.data.dataModal)
        }
      });

    }
  }, []);

  const onClose = () => {
    handleClickTransportOptions([], {});
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetActivePrecinctID());
    dispatch(reqSetIsShowExploreModal(false));
  };

  const handleButtonInContent = (event) => {
    const clickedEl = event.target;

    if (clickedEl.id == "btn_close_right_panel") {
      dispatch(reqSetIsShowGalleryModal(false));
      return onClose();
    }

    if (clickedEl.id == "btn_explore_precinct") {
      if (clickedEl.dataset?.area_id) {
        dispatch(reqSetActiveAreaId(clickedEl.dataset.area_id));
      } else {
        dispatch(reqSetActiveAreaId());
      }

      if (clickedEl.dataset?.precinct) {
        dispatch(reqFilterUnitPrecinct(clickedEl.dataset.precinct));
      } else {
        dispatch(reqFilterUnitPrecinct());
      }

      dispatch(reqSetIsShowPrecinctExploreDetail(true));
      onClose();
    }

    if (clickedEl.id === "btn_view_gallery") {
      if (clickedEl.innerHTML?.toLowerCase() === "view gallery") {
        clickedEl.innerHTML = "CLOSE GALLERY";
        dispatch(reqSetActiveGalleryModal(clickedEl.dataset?.gallery_id));
        dispatch(reqSetIsShowGalleryModal(true));
      } else {
        clickedEl.innerHTML = "VIEW GALLERY";
        dispatch(reqSetActiveGalleryModal());
        dispatch(reqSetIsShowGalleryModal(false));
      }
    }
  };

  const renderModalContent = () => {
    if (
      customer?.userGroup?.name === USER_GROUP.INVESTOR ||
      authUser?.userGroup?.name === USER_GROUP.INVESTOR
    ) {
      return parse(dataModal?.content || "");
    }

    return parse(dataModal?.content || "", {
      replace: (domNode) => {
        if (domNode.attribs?.id === "investor-details") return <></>;
      },
    });
  };

  const handleCloseOverlay = (event) => {
    // Check if the click is on the overlay and not on the modal content
    if (event.target.className.includes('gallery-item')) {
      handleCloseCenterModal();
    }
  };

  const handleCloseCenterModal = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_CENTER_MODAL_RIGHT_PANEL);
    }
    setDataModal({});
    dispatch(reqSetIsShowCenterExploreModal(false));
  }

  const handleCloseAmentities = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_AMENITIES_MODAL);
    }
    setDataModal({});
    dispatch(reqSetIsExploreAmenities({
      isShown: false,
      modalItems: []
    }));
  }

  const currentId = dataModal?.id
  const currentIdx = totalAmenities ? totalAmenities.findIndex(a => a.link === currentId) : null

  const shouldShowPrev = currentIdx && currentIdx > 0
  const shouldShowNext = currentIdx < totalAmenities.length - 1

  const onChangeModal = (isNext, totalAmenities, dataModal) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NAVIGATE_AMENITY_GALLERY, {
        isNext,
        totalAmenities,
        dataModal
      });
    }
    const currentId = dataModal?.id
    const currentIdx = totalAmenities ? totalAmenities.findIndex(a => a.link === currentId) : null
    if (currentIdx >= 0) {
      const nextIdx = isNext ? currentIdx + 1 : currentIdx - 1
      const nextData = totalAmenities[nextIdx]
      if (nextData) {
        dispatch(reqSetExploreModal(nextData.link));
      }
    } 
  }

  return (
    <TransitionGroup>
      {isShowRightPanel && (
        <CSSTransition timeout={1000} classNames="fade-item">
          <div
            className={`wrap-right-panel ${page === PAGES.EXPLORE_PRECINCT_PAGE && "above-timeline"
              }`}
            onClick={(e) => handleButtonInContent(e)}
          >
            {renderModalContent()}
          </div>
        </CSSTransition>
      )}
      {isShowGallery && (
        <CSSTransition timeout={1000} classNames="fade-item">
          <div
            className={`wrap-gallery-modal`}
            onClick={(e) => handleButtonInContent(e)}
          >
            <GalleryDetail />
          </div>
        </CSSTransition>
      )}
      {isShowCenterPanel && (
        <CSSTransition timeout={1000} classNames="fade-item">
          <div 
          className="wrap-gallery-center"
          onClick={handleCloseOverlay}
          >
            {renderModalContent()}
            <img
              width="30"
              className="img-close"
              src="/icons/icClose.svg"
              alt=""
              onClick={handleCloseCenterModal}
            />
          </div>
        </CSSTransition>
      )}

      {isExploreAmenities && (
        <CSSTransition timeout={1000} classNames="fade-item">
          <div 
          className="wrap-gallery-center--amenities"
          >
            {renderModalContent()}
            <img
              width="30"
              className="img-close"
              src="/icons/icClose.svg"
              alt=""
              onClick={handleCloseAmentities}
            />
            {shouldShowPrev && (
              <div className="icon icon-left" onClick={() => onChangeModal(false, totalAmenities, dataModal)}>
                <img src="icons/chevron-left-2.png" alt="" />
              </div>
            )}
            {shouldShowNext && (
              <div className="icon icon-right"  onClick={() => onChangeModal(true, totalAmenities, dataModal)}>
                <img src="icons/chevron-right-2.png" alt="" />
              </div>
            )}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default RightPanel;
