import React, { useState } from "react";
import * as yup from 'yup';
import Select from 'react-select';
import { toast } from "react-toastify";
import customerApi from '../../apis/api/customer';
import { useHistory } from "react-router-dom";
import { phoneRegExp } from "../../helper/validation";
import './index.scss';

const CreateRemoteGuideCustomer = (props) => {
  const { setStepSession, onStartSession, isRemoteGuideCustomer } = props;

  const history = useHistory();

  const [firstname, setFirstName] = useState("");
  const [surname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [purchaseReason, setPurchaseReason] = useState('');
  const [error, setError] = useState({});

  const onCancel = () => {
    setError({})
    setStepSession(2);
  }

  const onSave = async () => {
    const validattionSchema = yup.object().shape({
      firstname: yup.string().trim().required("Please fill out this field."),
      surname: yup.string().trim().required("Please fill out this field."),
      email: yup.string().trim().required("Please fill out this field.").email("Please enter a valid email."),
      mobile: yup.string().trim().required().matches(phoneRegExp, "Please enter a valid phone number."),
      city: yup.string().trim().required("Please fill out this field."),
      country: yup.string().trim().required("Please fill out this field."),
      nationality: yup.string().trim().required("Please fill out this field."),
      purchaseReason: yup.string().trim().required("Please fill out this field."),
    });

    try {
      const data = {
        firstname: firstname,
        surname: surname,
        email: email,
        mobile: phone,
        city: city,
        userGroup: '606d6f356af09f50f367ee23',
        country,
        nationality,
        purchaseReason,
      };

      await validattionSchema.validate(data, { abortEarly: false });
      setError({})
      const res = await customerApi.createCustomer(data);

      if (res && res?.message?.includes("Error")) {
        return toast.error("Email exist, Please try again!");
      }

      history.push({
        search: `?customer=${res?.data?.id}`,
      });
      toast.success('Create customer successfully!');

      onStartSession();
    } catch (err) {
      if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setError(errs)
      }
    }
  }

  return (
    <div className="wrap-customer-page" style={{ backgroundImage: `url(./images/bg-onboard.png)` }}>
      <div className="wrap-create-customer">
        <div className="header-booking">
          <div className="wrap-dot">
            <div className="dot" />
            <div className="dot active" />
          </div>
          <span className="title-booking">   
            { isRemoteGuideCustomer ? "Remote Guided Session" : "Sales Gallery Session" }
          </span>
          <span className="des-booking">
            Create a new customer for today’s session.
          </span>
        </div>
        <div className="bottom-booking">
          <div className="line-login">
            <div className="form-input">
              <span>*First Name</span>
              <input
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control mb-1"
                type="text"
                placeholder="First Name"
              />
              <span className={error?.firstname ? "error" : "text-white"}>{error?.firstname ?? "."}</span>
            </div>
            <div className="form-input">
              <span>*Last Name</span>
              <input
                value={surname}
                onChange={(e) => setLastName(e.target.value)}
                className="form-control mb-1"
                type="text"
                placeholder="Last Name"
              />
              <span className={error?.surname ? "error" : "text-white"}>{error?.surname ?? "."}</span>
            </div>
          </div>
          <div className="line-login">
            <div className="form-input">
              <span>*Email</span>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control mb-1"
                type="email"
                placeholder="Email"
              />
              <span className={error?.email ? "error" : "text-white"}>{error?.email ?? "."}</span>
            </div>
            <div className="form-input">
              <span>*Phone</span>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control mb-1"
                type="text"
                placeholder="Phone"
              />
              <span className={error?.mobile ? "error" : "text-white"}>{error?.mobile ?? "."}</span>
            </div>
          </div>
          <div className="line-login">
            <div className="form-input">
              <span>*City</span>
              <input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="form-control mb-1"
                type="text"
                placeholder="City"
              />
              <span className={error?.city ? "error" : "text-white"}>{error?.city ?? "."}</span>
            </div>
            <div className="form-input">
              <span>*Country</span>
              <input
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="form-control mb-1"
                type="text"
                placeholder="Country"
              />
              <span className={error?.country ? "error" : "text-white"}>{error?.country ?? "."}</span>
            </div>
            <div className="form-input">
              <span>*Nationality</span>
              <input
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                className="form-control mb-1"
                type="text"
                placeholder="Nationality"
              />
              <span className={error?.nationality ? "error" : "text-white"}>{error?.nationality ?? "."}</span>
            </div>
          </div>
          <div className="line-login">
            <div className="form-input w-100">
              <span>*Reason for Purchase</span>
              <Select
                className={`${purchaseReason ? "purchase-selected": ""} mb-1`}
                classNamePrefix="select"
                value={{ value: purchaseReason || '', label: purchaseReason || 'Select a reason for purchase'}}
                options={[
                  {
                    value: 'Primary Residence',
                    label: 'Primary Residence',
                  },
                ]}
                name="purchaseReason"
                isSearchable={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({...base, zIndex: 9999}),
                }}
                onChange={(item) => setPurchaseReason(item?.value)}
              />
              <span className={error?.purchaseReason ? "error" : "text-white"}>{error?.purchaseReason ?? "."}</span>
            </div>
          </div>
        </div>
        <div className="wrap-continues">
          <div className="left-buttons">
            <button
              type="submit"
              onClick={onCancel}
              className="btn-enter-line"
            >
              Cancel
            </button>
          </div>
          <button
            type="submit"
            onClick={onSave}
            className="btn-enter"
          >
            CREATE AND START SESSION
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateRemoteGuideCustomer
