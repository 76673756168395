import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";

import { getMediaUrl } from "../../helper/media";
import preferenceApi from "../../apis/api/preference";
import { useQuery } from "../bottom-navigation";
import { reqGetAmenitiesList } from "../../reduxs/amenities/action";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import { reqGetCustomerProfile } from "../../reduxs/user/action";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import socket from "../../helper/socket";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

const GallerySecondary = (props) => {
  const galleryRef = useRef();
  const dispatch = useDispatch();
  const customerId = useQuery().get("customer");
  const preference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const preferenceAmenitiesID = preference?.amenities?.map(
    (amenity) => amenity.id
  ) || [];
  const page = useSelector((state) => state.home.page);
  const [currentIndex, setCurrentIndex] = useState(props.indexInit);
  const [activeAmenity, setActiveAmenity] = useState(props.data[currentIndex]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
    initialSlide: props.indexInit,
  };

  const { isPresentation } = props

  useEffect(() => {
    setActiveAmenity(props.data[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    dispatch(reqGetAmenitiesList());
  }, []);

  useEffect(() => {
    if (customerId) {
      getCustomerPreference();
      getCustomerDetail();
    }
  }, [customerId]);

  useEffect(() => {
    if (props.isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_NEXT_GALLERY_SECONDARY) {
          slickNext();
        }
        if (content.action === ACTION_NAME.CLICK_PREV_GALLERY_SECONDARY) {
          slickPrev();
        }
      });
    }
  }, [props.isPresentation]);

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // const renderStar = () => {
  //   const checkFavoriteStatus = preferenceAmenitiesID?.includes(
  //     activeAmenity?.id
  //   );

  //   return (
  //     <span
  //       className="btn-item"
  //       onClick={() =>
  //         checkFavoriteStatus ? unFavoriteAmenity() : saveFavoriteAmenity()
  //       }
  //     >
  //       <svg
  //         width="45"
  //         height="45"
  //         viewBox="0 0 45 45"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <circle cx="22.5" cy="22.5" r="22.5" fill="white" />
  //         <path
  //           d="M36.0627 17.8596L27.3691 16.5821L23.4828 8.61624C23.3766 8.39814 23.202 8.22158 22.9863 8.11426C22.4453 7.84423 21.7879 8.06925 21.5174 8.61624L17.6311 16.5821L8.93745 17.8596C8.69777 17.8942 8.47863 18.0085 8.31085 18.1816C8.10802 18.3923 7.99624 18.6759 8.0001 18.97C8.00395 19.264 8.12311 19.5445 8.33139 19.7498L14.6214 25.9501L13.1353 34.7054C13.1005 34.909 13.1228 35.1185 13.1997 35.31C13.2766 35.5015 13.405 35.6674 13.5704 35.7889C13.7358 35.9103 13.9316 35.9825 14.1355 35.9972C14.3394 36.0119 14.5433 35.9685 14.7241 35.872L22.5001 31.7385L30.2761 35.872C30.4884 35.9863 30.7349 36.0244 30.9711 35.9828C31.5669 35.879 31.9675 35.3077 31.8648 34.7054L30.3788 25.9501L36.6687 19.7498C36.8399 19.5802 36.9529 19.3586 36.9872 19.1163C37.0796 18.5104 36.6619 17.9496 36.0627 17.8596Z"
  //           fill={checkFavoriteStatus ? "#FFD494" : "#1A1A1A"}
  //         />
  //       </svg>
  //     </span>
  //   );
  // };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (content.data.id) { 
            const element = document.getElementById(content.data.id);
            if (element && content.data) {
              element.scrollTop = content.data.scrollTop * element.scrollHeight
            }
          }
        }
      });
    }
  }, []);

  const onScroll = (event, elemenTitle) => {
    const id = `content-${elemenTitle.split(' ').join('')}`;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
        id
      });
    }
  }

  const sendToast = (message, type) => {
    if (!props.isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type
      })
    }
    toast[type](message, {
      toastId: "socket-toast"
    });
  }

  const saveFavoriteAmenity = () => {
    if (!preferenceAmenitiesID.includes(activeAmenity?.id)) {
      const data = {};
      data.customer = customerId;

      data.amenities = [...preferenceAmenitiesID, activeAmenity?.id];

      preferenceApi
        .savePreference(data)
        .then(() => {
          sendToast("Saved Preference successfully!", "success");
          getCustomerPreference();
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const unFavoriteAmenity = () => {
    if (preferenceAmenitiesID.includes(activeAmenity?.id)) {
      const data = {};
      data.customer = customerId;

      data.amenities = preferenceAmenitiesID?.filter(
        (amenityID) => amenityID !== activeAmenity?.id
      );
      preferenceApi
        .savePreference(data)
        .then(() => {
          sendToast("Unsaved Preference successfully!", "success");
          getCustomerPreference();
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? props.data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === props.data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const slickPrev = () => {
    if (!props.isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_PREV_GALLERY_SECONDARY);
    }
    galleryRef?.current?.slickPrev();
  }

  const slickNext = () => {
    if (!props.isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_NEXT_GALLERY_SECONDARY);
    }
    galleryRef?.current?.slickNext();
  }

  const onSwipe = (e) => {
    switch (e) {
      case 'left':
        handleNext();
        slickNext();
        break;
      case 'right':
        handlePrev();
        slickPrev();
        break;
      default:
        return;
    }
  }

  return (
    <div className="wrap-gallery-secondary">
      <Slider onSwipe={onSwipe} ref={galleryRef} {...settings}>
        {props.data?.map((item, idx) => {
          const descriptionList = item.description.split('</br></br>');
          return (
            <>
              <div key={idx} className="gallery-item">
                <img src={`${getMediaUrl(item.urlImg)}`} />
                <div
                  id={`content-${item.title.split(' ').join('')}`}
                  onScroll={e => onScroll(e, item.title)}
                  className={`gallery-content left-15`}
                >
                  <span>{item.title}</span>
                  {descriptionList.map((description, index) => (
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  ))}
                </div>
                {item?.isCustom && (
                  <div
                    id={`content-${item.title1.split(' ').join('')}`}
                    onScroll={e => onScroll(e, item.title1)}
                    className="gallery-content right-15"
                  >
                    <span>{item.title1}</span>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.description1 }}
                    />
                  </div>
                )}
              </div>
            </>
          );
        })}
      </Slider>
      <img
        width="30"
        className="img-close"
        src="/icons/icClose.svg"
        alt=""
        onClick={props.onClose}
      />
      {
        props.data?.length > 1 &&
        (
          <>
            <span className="btn-gallery prev-slide" onClick={() => handlePrev()}>
              <img
                width="30"
                className="img-fluid"
                src="/icons/arrow.svg"
                alt=""
                onClick={slickPrev}
              />
            </span>
            <span className="btn-gallery next-slide" onClick={() => handleNext()}>
              <img
                width="30"
                className="img-fluid"
                src="/icons/arrow.svg"
                alt=""
                onClick={slickNext}
              />
            </span>
          </>
        )
      }
      {/* {page == PAGES.AMENITIES_PAGE && !props.isPresentation && renderStar()} */}
    </div>
  );
};

export default GallerySecondary;
