import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import TopNavCMS from "./cms-top-nav";
import CMSCustomers from "./customers";
import CMSContent from "./content";
import CMSFrequentlyAQS from "./faq";
import CMSResidence from "./residences";
import { CMSFaqSearch } from "./search";
import ContentGallery from "./content-gallery";
import CMSAmenities from "./amenities";
import "./index.scss";

const CMS = (props) => {
  const { roles, authMiddleware } = props;
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  return (
    <div className="wrap-cms-page">
      <TopNavCMS />
      <Switch>
        <Route
          exact
          path="/cms"
          render={() => {
            return <Redirect to="/cms/residences" />;
          }}
        />
        <Route path="/cms/content">
          <CMSContent />
        </Route>
        <Route path="/cms/residences">
          <CMSResidence />
        </Route>
        <Route path="/cms/amenities">
          <CMSAmenities />
        </Route>
        <Route path="/cms/customers">
          <CMSCustomers />
        </Route>
        <Route path="/cms/frequently-asked-questions">
          <CMSFrequentlyAQS />
        </Route>
        <Route path="/cms/faq-search">
          <CMSFaqSearch />
        </Route>
        <Route path="/cms/galleries">
          <ContentGallery />
        </Route>
      </Switch>
    </div>
  );
};
export default CMS;
