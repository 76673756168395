import React, { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";
import appointment from "../../apis/api/appointment";
import Calendar from "./Calendar";
import "./index.scss";
import * as regex from "../../constants/regex";
import { useEffect } from "react";
import { reqGetListAppointment } from "../../reduxs/cms/action";
import { useSelector } from "react-redux";
import moment from "moment";
import { reqSetIsShowBookingAppointmentForm } from "../../reduxs/home/action";

dayjs.extend(customParseFormat);
const timeOptions = [
  {
    id: 1,
    value: "10:00",
    label: "10:00 am",
  },
  {
    id: 2,
    value: "11:00",
    label: "11:00 am",
  },
  {
    id: 3,
    value: "12:00",
    label: "12:00 am",
  },
  {
    id: 4,
    value: "13:00",
    label: "1:00 pm",
  },
  {
    id: 5,
    value: "14:00",
    label: "2:00 pm",
  },
  {
    id: 6,
    value: "15:00",
    label: "3:00 pm",
  },
  {
    id: 7,
    value: "16:00",
    label: "4:00 pm",
  },
  {
    id: 8,
    value: "17:00",
    label: "5:00 pm",
  },
  {
    id: 9,
    value: "18:00",
    label: "6:00 pm",
  },
];

const BookingAppointment = () => {
  const [bookingDate, setBookingDate] = useState(new Date());
  const [bookingHour, setBookingHour] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [errors, setErrors] = useState({});
  const [usedTime, setUsedTime] = useState([]);
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.cms.appointments);
  const [stepBooking, setStepBooking] = useState(0); // 0: choose time; 1: input info; 2: input content booking; 3: thank you
  const refFirstCheck = useRef(false);
  const timeBooking = useRef('');

  useEffect(() => {
    if (refFirstCheck.current) {
      if (firstName || lastName || email || phone || bookingHour) {
        validate();
      }
    }
  }, [firstName, lastName, email, phone, bookingHour]);

  useEffect(() => {
    bookingDate && getListUsedTime();
  }, [bookingDate]);

  useEffect(() => {
    setUsedTime(
      appointments.map((item) => dayjs(item.bookedTime).format("HH:mm"))
    );
  }, [appointments]);

  const getActiveClass = (value) => {
    return bookingHour === value ? "active" : "";
  };

  const isNotAvailableTime = (value) => {
    return usedTime.includes(value) ? "disabled" : "";
  };

  const getListUsedTime = async () => {

    const timeFormat = moment(bookingDate).format('L')
    dispatch(
      reqGetListAppointment({
        startTime: moment(timeFormat).utc().startOf('day').format(),
        endTime: moment(timeFormat).utc().endOf('day').format(),
      })
    );
  };

  const handleCreateAppointment = async () => {
    try {
      bookingDate.setHours(bookingHour.split(":")[0]);
      bookingDate.setMinutes(bookingHour.split(":")[1]);
      bookingDate.setSeconds(0, 0);
      timeBooking.current = bookingDate;
      const data = {
        firstName,
        lastName,
        email,
        phone,
        bookedTime: bookingDate.toISOString(),
        city,
      };

      const res = await appointment.createAppointment(data);
      if (res?.message) throw Error(res.message);
      resetForm();
      setStepBooking(3);
      toast.success("Created appointment successfully.");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const validate = () => {
    setErrors({});
    let err = {};

    if (!firstName) {
      err.firstName = "First Name is required.";
    }

    if (!lastName) {
      err.lastName = "Last Name is required.";
    }

    if (!email) {
      err.email = "Email is required.";
    }

    if (!phone) {
      err.phone = "Phone is required.";
    }

    if (!city) {
      err.city = "City is required.";
    }

    if (!bookingHour) {
      err.bookingHour = "Appointment time is required.";
    }

    if (!regex.EMAIL_REGEX.test(email)) {
      err.email = "Email format is invalid.";
    }

    if (!regex.PHONE_REGEX.test(phone)) {
      err.phone = "Phone format is invalid.";
    }

    if (Object.keys(err).length) {
      setErrors(err);
    }

    return !Object.keys(err).length;
  };

  const resetForm = () => {
    setErrors({});
    setBookingHour("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    refFirstCheck.current = false
  };

  const handleShowInputInfo = () => {
    setStepBooking(1);
  };
  const handleCancelBooking = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(false));
  };
  const goBack = () => {
    setStepBooking((s) => s - 1);
  };
  const handleShowContentBooking = () => {
    refFirstCheck.current = true;
    if (!validate()) return;
    setStepBooking(2);
  };
  const handleShowThankYou = () => {
    handleCreateAppointment();
  };

  const renderStepBooking = () => {
    switch (stepBooking) {
      case 0:
        return (
          <div className="wrap-booking">
            <div className="header-booking">
              <div className="wrap-dot">
                <div className="dot active" />
                <div className="dot" />
                <div className="dot" />
              </div>
              <span className="title-booking">Request An Appointment</span>
              <span className="des-booking">
                Please select a date and time to experience The St. Regis
                Residences, Miami.
              </span>
            </div>
            <div className="bottom-booking">
              <div className="wrap-calendar">
                <Calendar
                  selectedDate={bookingDate}
                  setSelectedDate={setBookingDate}
                />
              </div>
              <div className="wrap-available-time">
                <span className="available-title">
                  {`Available times (EST) ${bookingDate && "for "}`}
                  <span className="time-selected">
                    {bookingDate && moment(bookingDate).format("MMM D,YYYY")}
                  </span>
                </span>
                <div className="wrap-times">
                  {timeOptions.map((item) => (
                    <button
                      onClick={() => setBookingHour(item.value)}
                      className={`time-item ${getActiveClass(item.value)}`}
                      key={item.id}
                      disabled={isNotAvailableTime(item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="wrap-btn-submit">
              <button
                type="submit"
                onClick={handleShowInputInfo}
                className={`btn-enter ${bookingHour === '' && 'btn-disable'}`}
              >
                RESERVE TIME SLOT
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="wrap-booking">
            <div className="header-booking">
              <div className="wrap-dot">
                <div className="dot" />
                <div className="dot active" />
                <div className="dot" />
              </div>
              <span className="title-booking">Tell Us More</span>
              <span className="des-booking">
                {`You are making an appointment for ${bookingDate && moment(bookingDate).format("MMM D,YYYY")
                  } at ${bookingHour}.`}
              </span>
            </div>
            <div className="bottom-booking">
              <div className="login-form">
                <div className="line-login">
                  <div className="form-input">
                    <span>*First Name</span>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                    />
                    {errors?.firstName && (
                      <small className="text-danger">{errors.firstName}</small>
                    )}
                  </div>
                  <div className="form-input">
                    <span>*Last Name</span>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                    />
                    {errors?.lastName && (
                      <small className="text-danger">{errors.lastName}</small>
                    )}
                  </div>
                </div>
                <div className="line-login">
                  <div className="form-input">
                    <span>*Email</span>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      type="email"
                      placeholder="Email"
                    />
                    {errors?.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="form-input">
                    <span>*Phone</span>
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Phone"
                    />
                    {errors?.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>
                <div className="form-input">
                  <span>*City</span>
                  <input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control"
                    type="text"
                    placeholder="City"
                  />
                  {errors?.city && (
                    <small className="text-danger">{errors.city}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="wrap-continues">
              <div className="left-buttons">
                <button
                  type="submit"
                  onClick={handleCancelBooking}
                  className="btn-enter-line"
                >
                  Cancel booking
                </button>
                <button
                  type="submit"
                  onClick={goBack}
                  className="btn-enter-line"
                >
                  Go back
                </button>
              </div>
              <button
                type="submit"
                onClick={handleShowContentBooking}
                className="btn-enter"
              >
                Continues
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="wrap-booking">
            <div className="header-booking">
              <div className="wrap-dot">
                <div className="dot " />
                <div className="dot" />
                <div className="dot active" />
              </div>
              <span className="title-booking">
                Review Your Appointment Request
              </span>
              <span className="des-booking">
                Please confirm that your details are correct.
              </span>
            </div>
            <div className="bottom-booking">
              <div className="login-form">
                <div className="line-login">
                  <div className="form-input">
                    <span>Appointment Date</span>
                    <input
                      disabled
                      value={moment().format('dddd') + " " + moment(bookingDate).format('LL')}
                      className="form-control"
                      type="text"
                      placeholder="Appointment Date"
                    />
                  </div>
                  <div className="form-input">
                    <span>Appointment Date</span>
                    <input
                      disabled
                      value={bookingHour}
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="line-login">
                  <div className="form-input">
                    <span>*First Name</span>
                    <input
                      disabled
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form-input">
                    <span>*Last Name</span>
                    <input
                      disabled
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="line-login">
                  <div className="form-input">
                    <span>*Email</span>
                    <input
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-input">
                    <span>*Phone</span>
                    <input
                      disabled
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className="form-input">
                  <span>*City</span>
                  <input
                    disabled
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control"
                    type="text"
                    placeholder="City"
                  />
                </div>
              </div>
            </div>
            <div className="wrap-continues">
              <div className="left-buttons">
                <button
                  type="submit"
                  onClick={handleCancelBooking}
                  className="btn-enter-line"
                >
                  Cancel booking
                </button>
                <button
                  type="submit"
                  onClick={goBack}
                  className="btn-enter-line"
                >
                  Edit Details
                </button>
              </div>
              <button
                type="submit"
                onClick={handleShowThankYou}
                className="btn-enter"
              >
                Schedule appointment
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="wrap-booking">
            <div className="header-booking">
              <span className="title-booking">Thank you.</span>
              <span className="des-booking">
                Welcome, here are your requested appointment details.
              </span>
              <span className="des-booking">
                We look forward to introducing you to The St. Regis Residences,
                Miami. Please arrive 10 minutes ahead of your scheduled
                appointment time.
              </span>
            </div>
            <div className="bottom-booking">
              <div className="txt-content">
                <span className="appointment-detail">Appointment Details</span>
                <span className="appointment-detail">
                 {moment(timeBooking.current).format('LLLL')}
                </span>
                <div style={{ height: 10, width: 1 }} />
                <span className="txt-underline">ADD TO CALENDAR</span>
                <div style={{ height: 50, width: 1 }} />
                <span className="appointment-detail">Location</span>
                <div style={{ height: 10, width: 1 }} />
                <span className="content-location">
                  1809 Brickell Avenue Miami, Florida 33129
                </span>
                <div style={{ height: 10, width: 1 }} />
                <span className="txt-underline">GET DIRECTIONS</span>
              </div>
            </div>
            <div className="wrap-btn-submit">
              <button
                type="submit"
                onClick={handleCancelBooking}
                className="btn-enter"
              >
                BACK TO THE ST. REGIS RESIDENCES, MIAMI
              </button>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  const renderOldComponent = () => {
    return (
      <div className="body-container">
        <div className="header">
          <h3 className="title">Make An Appointment</h3>
          <hr />
        </div>
        <div className="body">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="wrap-calendar">
                <h3 className="title">SELECT DATE</h3>
                <Calendar
                  selectedDate={bookingDate}
                  setSelectedDate={setBookingDate}
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="wrap-form">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">FIRST NAME</h3>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your first name ..."
                    />
                    {errors?.firstName && (
                      <small className="text-danger">{errors.firstName}</small>
                    )}
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">LAST NAME</h3>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your first name ..."
                    />
                    {errors?.lastName && (
                      <small className="text-danger">{errors.lastName}</small>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">ENTER EMAIL</h3>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      type="email"
                      placeholder="Enter your email ..."
                    />
                    {errors?.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">PHONE</h3>
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your phone number ..."
                    />
                    {errors?.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>
                <h3 className="title">SELECT TIME (EST)</h3>
                <div className="wrap-times">
                  {timeOptions.map((item) => (
                    <button
                      onClick={() => setBookingHour(item.value)}
                      className={`time-item ${getActiveClass(item.value)}`}
                      key={item.id}
                      disabled={isNotAvailableTime(item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                {errors?.bookingHour && (
                  <small className="text-danger">{errors.bookingHour}</small>
                )}
              </div>
            </div>
          </div>
          <div className="row wrap-btn d-flex justify-content-center mt-5">
            <button
              onClick={handleCreateAppointment}
              className="btn-make-appointment"
            >
              MAKE APPOINTMENT
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="wrap-booking-appointment"
      style={{ backgroundImage: `url(./images/bg-onboard.png)` }}
    >
      {renderStepBooking()}
    </div>
  );
};

export default BookingAppointment;
