import {
  IS_INTRODUCTION,
  RECEIVE_3D_SETTINGS,
  RECEIVE_PAGES_SETTINGS,
  RECEIVE_HOTSPOTS_LIST,
  RECEIVE_ASSETS_LIST,
  RECEIVE_FBX_FILES,
  RECEIVE_HOTSPOT_TEXTURES,
  SET_IS_LOADING,
  SET_UNIT_VIEW,
  SET_WHERE_VIEW
} from './type';

const initialState = {
  isLoading: true,
  isIntroduction: true,
  settings: {},
  pagesSettings: [],
  hotspots: [],
  assets: [],
  fbxs: [],
  unitView: null,
  whereView: 'residence' | 'view',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case IS_INTRODUCTION:
      return {
        ...state,
        isIntroduction: action.data,
      };
    case RECEIVE_3D_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case RECEIVE_PAGES_SETTINGS:
      return {
        ...state,
        pagesSettings: action.payload,
      };
    case RECEIVE_HOTSPOTS_LIST:
      return {
        ...state,
        hotspots: action.payload,
      };
    case RECEIVE_HOTSPOT_TEXTURES:
      return {
        ...state,
        hstexts: action.payload,
      };
    case RECEIVE_ASSETS_LIST:
      return {
        ...state,
        assets: action.payload,
      };
    case RECEIVE_FBX_FILES:
      return {
        ...state,
        fbxs: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_UNIT_VIEW:
      return {
        ...state,
        unitView: action.payload,
      };
    case SET_WHERE_VIEW:
      return {
        ...state,
        whereView: action.payload,
      };
    default:
      return state;
  }
};
