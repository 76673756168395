export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const handleCollapse = (ev) => {
  const selector =
    ev.target.getAttribute('data-target') || ev.target.getAttribute('href');
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach((target) => {
    target.classList.toggle('show');
  });
};

export const isTouchDevice = () => {
  return "ontouchstart" in window || "onmsgesturechange" in window;
};
