import React from 'react';
import './transparent-modal.scss';
import { IconCloseButton } from "../svgs/icons";
import { ReactComponent as Close } from "../../assets/images/close.svg";

export const TransparentModal = (
  {
    open, onClose, children = null, fill = '#2D2927',
    classNames = '',
    isShowBtnClose = true,
    isOutSideClose = false,
    style = null

  }
) => {
  return (
    <div
      className={`modal-container ${classNames}`}
      style={{display: open ? "flex" : "none", ...style}}
      onClick={isOutSideClose ? onClose : () => {
      }}
    >
      {isShowBtnClose && (
        <div className="btn-close-modal" onClick={onClose}>
          <IconCloseButton style={{width: 26}} fill={fill}/>
        </div>
      )}
      <div className="modal-base-content">
        <div className="modal-base-body">{children}</div>
      </div>
    </div>
  )
};
