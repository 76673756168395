import React from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';
import PostSession from '../../components/session/PostSession';

const CustomerSession = () => {
  const { customerId } = useParams();

  return (
    <div className="wrap-customer-session">
      <PostSession isPostEngagement={true} customerId={customerId} />
    </div>
  );
};

export default CustomerSession;
