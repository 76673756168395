import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import Divider from "./common/Divider";
import customerApi from '../../apis/api/customer';
import preferenceApi from '../../apis/api/preference';
import UnitDetailOne from "./common/UnitDetailOne";
import UnitDetailTwo from "./common/UnitDetailTwo";
import Footer from "./common/Footer";
import {
  WEBSOCKET_CHANNEL,
  ACTION_NAME
} from "../../constants/options";
import socket from "../../helper/socket";

const PostSession = (props) => {
  const { customerId, isPresentation, isPostEngagement = false } = props;

  const [preference, setPreference] = useState();
  const [customer, setCustomer] = useState();
  const pageRef = useRef(null);

  useEffect(() => {
    if (customerId) {
      getCustomerDetail();
      getCustomerPreference();
    }
  }, [customerId]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollPercentage * (pageRef.current.scrollHeight - pageRef.current.clientHeight);
          }
        }
      });
    }
  }, []);

  const getCustomerDetail = async () => {
    const res = await customerApi.getCustomerDetail(customerId);
    setCustomer(res.data);
  };
  const getCustomerPreference = async () => {
    try {
      const res = await preferenceApi.getCustomerPreference({
        customer: customerId,
      });
      setPreference(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderFavoriteUnits = () => {
    const units = preference?.units || [];

    if (!units.length) {
      return (
        <div className="no-data">
          <span className="title-name">No favorite units</span>
        </div>
      )
    }

    return units.map((unit, index) => {
      if (index % 2 == 0) {
        return (
          <div key={unit.id} className="unit-section-wrap">
            {renderDivider(index)}
            <UnitDetailOne unit={unit} />
          </div>
        )
      }

      return (
        <div key={unit.id} className="unit-section-wrap">
          {renderDivider(index)}
          <UnitDetailTwo unit={unit} />
        </div>
      )
    });
  }

  const renderDivider = (index) => {
    if (index === 0) return;

    if (index % 2 == 0) {
      return <Divider style={{ height: 700 }} imgSrc="/images/post-session-divider-1.png" />;
    }

    return <Divider style={{ height: 700 }} imgSrc="/images/post-session-divider-2.png" />;
  };

  const sendToast = (message, type) => {
    if(!props.isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type
      })
    }
    toast[type](message, {
      toastId: "socket-toast"
    });
  }

  const onCopyURL = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/post-engagement/${customerId}`);
    sendToast('Copied customer session url', 'success');
  }

  const onScroll = (event) => {
    if (!isPresentation) {
        const { scrollHeight, scrollTop, clientHeight } = event.currentTarget; 
        socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
          scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  }

  return (
    <div
      ref={pageRef} onScroll={onScroll}
      className="static-page-wf sessionPage"
      style={{ backgroundColor: "#fff" }}
    >
      <main>
        <div
          className={`info-user ${(isPresentation || isPostEngagement) ? 'mt-0' : ''}`}
          style={{ backgroundImage: `url(/images/bg-onboard.png)` }}
        >
          <span className="title-name">{`Hi ${customer?.name || "CUSTOMER NAME"},`}</span>
          <span className="title-des">
            Here are the items you expressed interest in during your session
            with us at The St. Regis Residences, Miami.
          </span>
          {/* <a onClick={onCopyURL} className="btn-end-session" href="#">COPY URL</a> */}
        </div>
        <div className="favorite-units">
          {renderFavoriteUnits()}
        </div>
        <div className={`footer ${(isPostEngagement) ? 'mb-0' : ''}`}>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default PostSession;
