import React, { useEffect, useState } from "react";
import "./index.scss";
import jwtAuth from "../../apis/utils/jwtAuth";
import authApi from "../../apis/api/auth";
import { validateEmail, validatePassword } from "../../helper/validation";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reqGetUserProfile } from "../../reduxs/user/action";
import {
  reqSetPage
} from "../../reduxs/home/action";
import { PAGES } from "../../constants/options";

const HoldingPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    redirectIfAuthenticated();
  }, [authUser]);

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken() && authUser) {
      history.push("/");
    }
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
      history.push(history.location?.state?.path || "/");
    } else {
      setErrorMessage("Email not found or password does not match");
    }
  };

  const getUserProfile = async () => {
    try {
      dispatch(reqGetUserProfile());
    } catch (error) {
      return;
    }
  };

  return (
    <div
      className="wrap-holding-page h-100"
      style={{ backgroundImage: `url(./images/bg-holding.png)` }}
    >
      <div className="holding-wrapper">
        <img
          className="logo-brickell"
          src="./images/logo-brickell.png"
          alt="logo-brickell"
        />
        <div className="content-login">
          <span className="title-login">The St. Regis Residences, Miami</span>
          <div className="login-form">
            <div className="form-input">
              <label>*Email</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                placeholder="Enter Email"
              />
            </div>
            <div className="form-input">
              <label>*Password</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                aria-describedby="passwordHelp"
                placeholder="Enter Password"
              />
              <small id="passwordHelp" className="form-text text-danger">
                {errorMessage}
              </small>
            </div>
          </div>
          <button
            type="submit"
            onClick={handleLogin}
            className="btn-enter"
          >
            Enter
          </button>
        </div>
        <div className="logo-brickell" />
      </div>
    </div>
  );
};

export default HoldingPage;
