import React from "react";
import { getUploadedMediaUrl } from "../../../helper/media";

const ImageBlock = (props) => {
  const { path, onClick } = props
  return (
    <img
      alt=""
      onClick={onClick}
      className="image-block-common img-fluid cursor-pointer"
      src={getUploadedMediaUrl(path)}
    />
  )
}

export default ImageBlock;
