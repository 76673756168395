import React, { useRef } from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMediaUrl, getUploadedMediaUrl } from "../../../helper/media";

const ImageGallery = (props) => {
  const { onClose, mediaItems, initialIndex } = props;
  const galleryRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
    initialSlide: initialIndex,
  };

  return (
    <div className="wrap-session-gallery">
      <div className="session-gallery">
        <Slider ref={galleryRef} {...settings}>
          {(mediaItems || []).map((item, idx) => {
            return (
              <div key={idx} className="gallery-item">
                <img src={getUploadedMediaUrl(item.path)} />
              </div>
            );
          })}
        </Slider>
        <img
          width="30"
          className="img-close"
          src="/icons/icClose.svg"
          alt=""
          onClick={onClose}
        />
        <span className="btn-gallery prev-slide">
          <img
            width="30"
            className="img-fluid"
            src="/icons/arrow.svg"
            alt=""
            onClick={() => galleryRef.current.slickPrev()}
          />
        </span>
        <span className="btn-gallery next-slide">
          <img
            width="30"
            className="img-fluid"
            src="/icons/arrow.svg"
            alt=""
            onClick={() => galleryRef.current.slickNext()}
          />
        </span>
      </div>
    </div>
  );
};

export default ImageGallery;
