import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getMediaList = (data) => client.get(endPoint.MEDIA_GET_LIST, data);
const createMedia = (data) => client.postWithAuth(endPoint.MEDIA_CREATE, data);
const getMediaDetail = (mediaId, data) =>
  client.get(endPoint.MEDIA_GET_DETAIL.replace(':mediaId', mediaId), data);
const uploadMedia = (data) => defaultClient.uploadFormData(endPoint.MEDIA_UPLOAD, data);
const updateMedia = (id, data) => defaultClient.editFormData(endPoint.MEDIA_UPDATE.replace(':id', id), data);
const updateMediaCMS = (id, data) => client.ediFormDataWithAuth(endPoint.MEDIA_UPDATE.replace(':id', id), data);

const deleteMedia = (id) => defaultClient.deleteWithAuth(endPoint.MEDIA_DELETE.replace(':id', id));

export default {
  getMediaList,
  getMediaDetail,
  uploadMedia,
  updateMedia,
  createMedia,
  deleteMedia,
  updateMediaCMS
};
