import React, { useRef, useEffect } from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMediaUrl } from "../../helper/media";
import socket from "../../helper/socket";
import {
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import { getUploadedMediaUrl, getUploadedServerMediaUrl } from "../../helper/media";

export const GalleryPrimaryModal = ({ indexInit, data, onClose, isPresentation }) => {
  const galleryRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
    initialSlide: indexInit,
    afterChange: (index) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
          index,
        });
      }
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
      onGoToSlideIndex(content.data.index);
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const slickPrev = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_PREV_GALLERY_PRIMARY);
    }
    galleryRef?.current?.slickPrev();
  }

  const slickNext = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_NEXT_GALLERY_PRIMARY);
    }
    galleryRef?.current?.slickNext();
  }

  const onSwipe = (e) => {
    switch (e) {
      case 'left':
        slickNext();
        break;
      case 'right':
        slickPrev();
        break;
      default:
        return;
    }
  }

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  }

  const parseUrl = imgPath => (imgPath?.startsWith('/media/media_images') || imgPath?.startsWith('/media/media_films')) ?  getUploadedMediaUrl(imgPath) : getUploadedServerMediaUrl(imgPath);

  return (
    <div className="wrap-gallery-primary">
      <Slider onSwipe={onSwipe} ref={galleryRef} {...settings}>
        {data?.map((item, idx) => {
          return (
            <div key={idx} className="gallery-item">
              <img src={parseUrl(item.urlImg)}/>
            </div>
          );
        })}
      </Slider>
      <img
        width="30"
        className="img-close"
        src="/icons/icClose.svg"
        alt=""
        onClick={onClose}
      />
      <span className="btn-gallery prev-slide">
        <img
          width="30"
          className="img-fluid"
          src="/icons/arrow.svg"
          alt=""
          onClick={() => galleryRef.current.slickPrev()}
          />
      </span>
      <span className="btn-gallery next-slide">
        <img
          width="30"
          className="img-fluid"
          src="/icons/arrow.svg"
          alt=""
          onClick={() => galleryRef.current.slickNext()}
          />
      </span>
    </div>
  );
};
