import React, { useEffect, useRef } from "react";
import "./style.scss";
import { PAGES, ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useState } from "react";
import LoadingIcon from "../loading-icon";
import { useDispatch, useSelector } from "react-redux";
import { reqSetIsShowVideo, reqSetPage } from "../../reduxs/home/action";
import {
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetUserSelectTypeModal,
} from "../../reduxs/guide-session/action";
import { reqGetUserProfile } from "../../reduxs/user/action";
import customerAPi from "../../apis/api/customer";
import { FormControl, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CreateRemoteGuideCustomer from "../customers/remote";
import socket from "../../helper/socket";

const Loading = (props) => {
  const history = useHistory();
  const { isLoading, setIsIntroduction, isPresentation } = props;
  const isShowVideo = useSelector((state) => state.home.isShowVideo);
  const authUser = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const listUsersRef = useRef(null);
  const customer = useSelector((state) => state.user.customer);
  const [stepSession, setStepSession] = useState(0); // 0: start session; 1: choose session; 2: add user
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );
  const [searchInput, setSearchInput] = useState("");

  const [listUserSelect, setListUserSelect] = useState([]);

  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_START_SESSION) {
          onStartSession();
        }
        if (content.action === ACTION_NAME.CLICK_START_DISCOVER) {
          startDiscover();
        }
        if (content.action === ACTION_NAME.SELECT_USER_SESSION) {
          onSelectUser(content?.data?.user);
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(listUsersRef.current && content.data) {
            listUsersRef.current.scrollTop = content.data.scrollTop * listUsersRef.current.scrollHeight
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isShowListInvestorModal) {
      getListInvestor();
    }
    if (isShowListConsultantModal) {
      getListConsultant();
    }
  }, [isShowListInvestorModal, isShowListConsultantModal]);

  const getListInvestor = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const getListConsultant = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }, [authUser]);

  const startDiscover = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_START_DISCOVER);
    }
    //setIsIntroduction(true);
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetIsShowVideo(false));
    analytics.track("Agent Started Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    });
  };

  const onExplore = () => {
    // dispatch(reqSetIsShowUserTypeModal(true));
    setStepSession(1);
  };

  const onStartSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_START_SESSION);
    }
    dispatch(reqSetIsShowVideo(true));
    videoRef?.current?.play();
    // dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    // dispatch(reqSetIsShowCreateTenantProfileModal(false));
  };

  const onScrollListUsers = (event) => {
    if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
          scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
        });
    }
  }

  const renderExploreButton = () => {
    if (!authUser) return;
      return (
        <button type="submit" className="explore_button" onClick={() => setStepSession(1)}>
          START NEW SESSION
        </button>
      );
  };

  const showListConsultantModal = () => {
    dispatch(reqSetIsShowListConsultantModal(true));
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetUserSelectTypeModal("consultant"));
    setStepSession(2);
  };

  const showListInvestorModal = () => {
    dispatch(reqSetIsShowListInvestorModal(true));
    dispatch(reqSetIsShowListConsultantModal(false));
    dispatch(reqSetUserSelectTypeModal("investor"));
    setStepSession(2);
  };

  const getActiveClass = (user) => {
    return selectedUser?.id === user?.id
      ? "cursor-pointer active"
      : "cursor-pointer";
  };

  const onSelectUser = (user) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_USER_SESSION, {
        user
      });
    }
    history.push({
      search: `?customer=${user?.id}`,
    });
    setSelectedUser(user);
  };

  const handleCancelSession = () => {
    if(customer) {
      history.push({
        search: "",
      });
    }
    dispatch(reqSetIsShowListConsultantModal(false));
    setSelectedUser(null);
    setStepSession((step) => step - 1);
    setSearchInput("")
  };

  const renderSession = () => {
    switch (stepSession) {
      case 0:
        return (
          <div className={`wrap-intro ${isShowVideo ? "hide" : "show"}`}>
            <div
              id="intro-video"
              style={{ backgroundImage: `url(./images/bg-holding.png)` }}
            />
            <div className="intro-content">
              <div className="wrap-text b-fade-text">
                <span className="title-top">
                  To Live Exquisite is to Live Extraordinary
                </span>
                <span className="description">
                  Situated along the beautiful coastline of South Brickell with
                  exquisite bayfront views, The St. Regis Residences, Miami
                  epitomizes elegant living. It mirrors the sensibilities of
                  those who are driven by connoisseurship, demand excellence,
                  respect tradition, and value individuality above all else.
                </span>
                <span className="description">
                  The St. Regis Residences, Miami represents a future propelled
                  by a historic past. A place where new generations will create
                  a lifetime of legendary moments, in one-of-a-kind homes for
                  individuals who perceive their personal legacies innately
                  intertwined with the rich heritage of Miami.
                </span>
              </div>
              {isLoading ? (
                <LoadingIcon />
              ) : (
                <div className="wrap-button">{renderExploreButton()}</div>
              )}
              {/* Used to cache video */}
              <div style={{ display: "none" }}>
                <video
                  autoPlay
                  onEnded={() => startDiscover()}
                  preload="auto"
                  muted
                // style={{ backgroundImage: `url("/images/video_bg.png")` }}
                >
                  <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={`wrap-intro ${isShowVideo ? "hide" : "show"}`}>
            <div
              id="intro-video"
              style={{ backgroundImage: `url(./images/bg-onboard.png)` }}
            />
            <div className="wrap-choose-session">
              <div className="header-session">
                <div className="wrap-dot">
                  <div className="dot active" />
                  <div className="dot" />
                </div>
                <span className="title-session">
                  Session Setup
                </span>
                <span className="des-session">
                  Please select your session type to experience The St. Regis Residences, Miami.
                </span>
              </div>
              <div className="bottom-session">
                <button
                  type="submit"
                  onClick={showListConsultantModal}
                  className="btn-enter"
                >
                    SALES GALLERY SESSION
                </button>
                <button
                  type="submit"
                  onClick={showListInvestorModal}
                  className="btn-enter"
                >
                  REMOTE GUIDED SESSION
                </button>
                <button
                    type="submit"
                    onClick={handleCancelSession}
                    className="btn-enter-line"
                  >
                    CANCEL SETUP
                </button>
              </div>
            </div>
          </div>
        );
      case 2: 
        return (
          <div className={`wrap-intro ${isShowVideo ? "hide" : "show"}`}>
            <div
              id="intro-video"
              style={{ backgroundImage: `url(./images/bg-onboard.png)` }}
            />
            <div className="wrap-choose-session">
              <div className="header-session">
                <div className="wrap-dot">
                  <div className="dot" />
                  <div className="dot active" />
                </div>
                <span className="title-session">
                  {isShowListInvestorModal
                    ? "Remote Guided Session"
                    : "Sales Gallery Session"}
                </span>
                <span className="des-session">
                  Please select the customer for today’s session.
                </span>
                <div className="search-session">
                  <div className="input-wrapper">
                    <InputGroup>
                      <FormControl
                        placeholder="Search..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </InputGroup>
                    <button
                      type="submit"
                      onClick={showListInvestorModal}
                      className="btn-enter"
                    >
                      Search
                    </button>
                  </div>
                  <div className="wrap-user-list" ref={listUsersRef} onScroll={onScrollListUsers}>
                    {listUserSelect
                      ?.filter((user) => {
                        if (searchInput == "") {
                          return user;
                        } else if (
                          user?.name
                            .toLowerCase()
                            .includes(searchInput.toLocaleLowerCase())
                        ) {
                          return user;
                        }
                      })
                      .map((user) => {
                        return (
                          <span
                            className={getActiveClass(user)}
                            key={user.id}
                            tabIndex={-1}
                            onClick={() => onSelectUser(user)}
                          >
                            {user?.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="bottom-selected-session">
                <div className="bottom-selected-btn-group">
                  <button
                    type="submit"
                    onClick={handleCancelSession}
                    className="btn-enter-line"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => { setStepSession(3) }}
                    className="btn-enter-line"
                  >
                    CREATE NEW CUSTOMER
                  </button>
                </div>
                <button
                  type="submit"
                  onClick={onStartSession}
                  className="btn-enter"
                >
                  START SESSION
                </button>
              </div>
            </div>
          </div>
        )
      case 3:
        return !isShowVideo && (<CreateRemoteGuideCustomer setStepSession={setStepSession} onStartSession={onStartSession} isRemoteGuideCustomer={isShowListInvestorModal} />);
      default:
        return <div />;
    }
  };

  return (
    <div id="loading-screen">
      {renderSession()}
      <div className={`wrap-slide-loading ${isShowVideo ? "show" : "hide"}`}>
        {
          // isShowVideo &&
          <>
            <video
              ref={videoRef}
              autoPlay={false}
              onEnded={startDiscover}
              preload="auto"
              id="intro-video-2"
              muted={false}
            // style={{ backgroundImage: `url("/images/video_bg.png")` }}
            >
              <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
            </video>
            {isLoading && <LoadingIcon />}
            {!isPresentation && (
              <div className="btn-skip-video">
                <button onClick={startDiscover} className="skip-video" disabled={isLoading}>
                  SKIP VIDEO
                  <img src="./images/icons/arrow-left.svg" />
                </button>
              </div>
            )} 
          </>
        }
      </div>
      {/* <UserTypeModal /> */}
      {/*<ListUserSelectModal startDiscover={onStartSession} />*/}
      {/* <CreateUserProfileModal startDiscover={onStartSession} /> */}
    </div>
  );
};

export default Loading;
