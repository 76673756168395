import React from "react";
import './index.scss'

const SearchIcon = ({onClick}) => {
    return(
        <div className='search-component' onClick={onClick}>
            <img className="img-fluid" src="/icons/icSearch.svg" alt="" />
        </div>
    )
};

export default SearchIcon;
