import React, { useCallback, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const MultiRangeSlider = ({ min, max, onChange, minValue, maxValue }, ref) => {
  const [minVal, setMinVal] = useState(minValue || min);
  const [maxVal, setMaxVal] = useState(maxValue || max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useImperativeHandle(ref, () => ({
    updateRange({ min, max }) {
      setMinVal(min);
      setMaxVal(max);
      minValRef.current = min;
      maxValRef.current = max;
    },
    reset() {
      setMinVal(min);
      setMaxVal(max);
      minValRef.current = min;
      maxValRef.current = max;
    }
  }));

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, maxVal, getPercent]);

  return (
    <div className="multi-range-slider-container">
      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">MIN</div>
        <div className="slider__right-value">MAX</div>
      </div>
      <input
        type="range"
        name="min"
        min={min}
        max={max}
        value={Number(minVal)}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          onChange({ min: value, max: maxVal })
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        name="max"
        min={min}
        max={max}
        value={Number(maxVal)}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          onChange({ min: minVal, max: value })
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />
    </div>
  );
};

export default forwardRef(MultiRangeSlider);
