import './browse-file.scss'
import React, {useRef, useState, useCallback} from "react";
import {isValidHttpUrl, toBase64} from "../../../apis/utils/helpers";
import uploadIcon from "../../../assets/images/upload.svg";

const GalleryCMSBrowseFile = ({clearErrors, setFileError, fileType, type: galleryType, onChange, accept = 'image', notInflight}) => {
  const ref = useRef()
  const videoRef = useRef()
  const [value, setValue] = useState('')
  const [file, setFile] = useState()

  const isVideo = accept === 'video'

  const onFileChange = async (e) => {
    if(e.target.files.length > 0) {
      const files = Array.from(e.target.files)
      //validate file format
      if (isVideo) {
        if (!files[0].type.startsWith('video')) {
          setFileError(fileType, {message: 'The file failed to upload: the format is not supported.'})
          return
        }
      } else {
        if (!files[0].type.startsWith('image')) {
          setFileError(fileType, {message: 'The file failed to upload: the format is not supported.'})
          return
        }
      }
      onChange(files[0])
      setFile(files[0])
      clearErrors(fileType)
    } else {
      onChange && onChange(null)
    }
    setValue('')
    clearErrors(fileType)
  }

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return "/images/image.png";
  };

  const renderThumbnailPreview = () => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }

    return "/images/image.png";
  };

  const handleInputUrlChange = async (value) => {
      const url = isValidHttpUrl(value)

      if(url?.host === 'inflightllc.com'){
          const base64Value = await toBase64(value,true);
          onChange && onChange({name: 'inflightllc-'+Date.now(),value: base64Value})
      } else {
          onChange && onChange(null)
      }
  }

  const onOpenInput = () =>{
    ref.current?.click()
  }

  return(
    <div className={'browse-file'}>
      {file ? (
        <div style={{position: 'relative'}} className='mb-4'>
          {isVideo ? (                
            <video key={file.name} className="img-fluid cursor-pointer">
              <source src={renderImagePreview()} type="video/mp4" />
            </video>
          ) : (
            <img className="img-fluid cursor-pointer thumbnail" src={renderImagePreview()} alt="" />
          )} 
          <div
            onClick={onOpenInput}
            className={`upload-btn`}
          >
            <img  className="upload-icon" src={uploadIcon} alt="upload-icon" />
          </div>
        </div>
      ) : (
        <>
          <button type={'button'} className={'browse-file-btn'} onClick={()=>ref.current?.click()}>
            BROWSE FILES
          </button>
        </>
      )}
      <input type={'file'} ref={ref} style={{display: "none"}} onChange={onFileChange} accept={`${accept}/*`}/>
      {!notInflight && (
      <div>
        <p className={'browse-file-desc'}>Or import from an Inflight URL</p>
        <div className={'browse-file-text-url'}>
          <input
            value={value}
            placeholder={'Add an Inflight URL'}
            onChange={(e)=>setValue(e.target.value)}
          />
          <button type={'button'} className={'browse-file-text-url-btn'} onClick={handleInputUrlChange}>
            IMPORT
          </button>
        </div>
      </div>
      )}

    </div>
  )
}
export default GalleryCMSBrowseFile
