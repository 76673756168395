import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getFaqList = (data) => client.get(endPoint.FAQ_LIST, data);

export default {
  getFaqList,
};
