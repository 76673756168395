import React from "react";

const UnitDescription = (props) => {
  const { unit } = props;
  return (

    <div>
      <div className="unit-name">Residence {unit?.name}</div>
      <div className="unit-description">
        <div><span>{unit?.bedrooms || 0}</span> <span>BEDROOMS</span></div>
        <div><span>{unit?.bathrooms || 0}</span> <span>BATHROOMS</span></div>
        <div><span>{unit?.powderrooms || 0}</span> <span>POWDER ROOM</span></div>
        <div className="interior">
          <div>INTERIOR</div>
          <div>
            <span>{`${unit?.interiorSF}SF | ${Math.round(unit?.interiorSF * 0.092903)} SQM`}</span>
          </div>
        </div>
        <div className="exterior">
          <div>EXTERIOR</div>
          <div>
            <span>{`${unit?.exteriorSF}SF | ${Math.round(unit?.exteriorSF * 0.092903)} SQM`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitDescription;
