import React, { useState, useEffect, useRef, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import './overplay.scss'
import GalleryCMSImageChoose from "../form/image-choose";
import GalleryCMSVideoChoose from "../form/video-choose";
import GalleryCMSInput from "../form/input";
import GalleryCMSSelect from "../form/select";
import GalleryCMSSelectResidence from "../form/select-residence";
import {useDispatch} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import Error from "../form/error";
import mediaAPI from '../../../apis/api/media'
import galleryAPI from '../../../apis/api/gallery'
import { toast } from "react-toastify";
import closeIcon from '../../../assets/images/close-white.svg'
import { createPortal } from 'react-dom';
import GalleryOrderSelect from "../form/select-order";

const GALLERY_TYPE_IMAGE = 'media_images'
const GALLERY_TYPE_VIDEO = 'brickell_film'

const GalleryCMSUpdateOverplay = ({ uniqueOrder, show, handleClose, item, image, unitList = []}) => {
  const dispatch = useDispatch();
  const [isDisable, setDisable] = useState(false)
  const [screenDisplay,setScreenDisplay] = useState('update')
  const [isReplacing, setIsReplacing] = useState(false)
  const [acceptType, setAcceptType] = useState('')
  const [processingFileType, setProcessingFileType] = useState('')

  const fileInputRef = useRef()
  const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      setError,
      clearErrors,
      getValues
  } = useForm({
      defaultValues: {
          name: item?.name || '',
          type: item?.type || '',
          unitId: item?.unitId || '',
          order: item?.media?.find(media => media.type === 'video')?.order || item?.media[0]?.order || item?.media[1]?.order || null
      }
  })
  const oldOrder = useMemo(() => getValues().order);
  const unitSelectOptions = unitList.map(unit => ({
    value: unit.id,
    text: unit.name || ''
  }))

  const orderSelectOptions = useMemo(() => uniqueOrder.map(order => ({
      value: order,
      text: order
  })), [uniqueOrder]);

  const isVideo = item.type === GALLERY_TYPE_VIDEO
  useEffect(() => {
    show && setScreenDisplay('update')
  }, [show]);

  const onUpdateVideo = async (data) => {
    try {
      toast.info("Please wait, media file uploading!");
      let thumbnail = item.thumbnail
      let videoUrl = item.videoUrl
      let media = item.media.sort((a, b) => {
        if (a.type > b.type) {
          return -1;
        }
        if (a.type < b.type) {
          return 1;
        }
        return 0;
      }) || []
      let mediaChanged = false

      if (data?.file) {
        const formData = new FormData();
        formData.append("type", "video");
        formData.append("name", data?.name);
        formData.append("path", "media/media_videos/films");
        formData.append("file", data.file);
        const uploaded = await mediaAPI.uploadMedia(formData);

        mediaChanged = true
        media = media.map(m => {
          if (m.path === videoUrl) {
            return uploaded?.data.id
          } 
          return m.id
        }) 
        videoUrl = uploaded?.data?.path
      }

      if (data.thumbnail) {
        const formDataThumbnail = new FormData();
        formDataThumbnail.append('type', 'image');
        formDataThumbnail.append('name', data?.name)
        formDataThumbnail.append('file', data.thumbnail);
        formDataThumbnail.append("path", "media/media_images/image_gallery");
        mediaChanged = true
        const uploadedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);

        if (data?.file) {
          media = media.map((m, i) => {
            if (i === 1) {
              return uploadedThumbnail?.data.id
            } 
            return m;
          }) 
          thumbnail = uploadedThumbnail?.data?.path
        } else {
          media = media.map(m => {
            if (m.path === thumbnail) {
              return uploadedThumbnail?.data.id
            } 
            return m.id
          }) 
          thumbnail = uploadedThumbnail?.data?.path
        }
      }

      const payload = data?.unitId ? {
        name: data?.name || item.name,
        thumbnail, 
        videoUrl,
        media: mediaChanged ? media : media.map(m => (m.id)),
        unitId: data?.unitId
      } : {
        name: data?.name || item.name,
        thumbnail, 
        videoUrl,
        media: mediaChanged ? media : media.map(m => (m.id)),
      }
      const mediaData = new FormData();
      mediaData.append("type", "video");
      mediaData.append("order", !!data?.order ? Number(data?.order) : uniqueOrder.sort((a, b) => a - b)[uniqueOrder.length - 1] + 1);
      mediaData.append("oldOrder", oldOrder);
      await mediaAPI.updateMedia(mediaChanged ? media[0] : media[0].id, mediaData)
      await galleryAPI.updateGalleryV2(item.id, {...item, ...payload});
      toast.success('Update content successfully!');
      handleClose && handleClose(true);
    } catch (error) {
      setError('file', { message: 'The file failed to upload: an unknown error occurred.' })
      setError('thumbnail', { message: 'The file failed to upload: an unknown error occurred.' })
      return
    }
  }
  const onUpdateImage = async (data) => {
    try {
      let media = item.media.map(m => m.id) || []
      if (data.file) {
        const formData = new FormData();
        formData.append('type', 'image');
        formData.append('name', data?.name)
        formData.append('file', data.file);
        formData.append("path", "media/media_images/image_gallery");
        const uploaded = await mediaAPI.uploadMedia(formData);

        if (uploaded?.data) {
          media = [uploaded.data.id] 
        }
      }

      const payload = data?.unitId ? {
        ...item,
        type: "media_images",
        media,
        name: data?.name,
        path: '',
        unitId: data?.unitId
      } : { 
        ...item,
        type: "media_images",
        media,
        name: data?.name,
        path: '',
      }
      await galleryAPI.updateGalleryV2(item.id, payload);
      const mediaData = new FormData();
      mediaData.append("type", "image");
      mediaData.append("order", !!data?.order ? Number(data?.order) : uniqueOrder.sort((a, b) => a - b)[uniqueOrder.length - 1] + 1);
      mediaData.append("oldOrder", oldOrder);
      await mediaAPI.updateMedia(data.file ? media[0] : item.media[0].id, mediaData)
      toast.success('Update content successfully!');
      handleClose && handleClose(true)
      setDisable(false)
    } catch (err) {
      setError('file', { message: 'The file failed to upload: an unknown error occurred.' })
      return
    }
  }

  const handleUpdate = (data) => {
    if (isVideo) {
      onUpdateVideo(data)
      return
    }
    onUpdateImage(data)
  };

  const renderComponentUpdate = () => {
    return(
      <form onSubmit={handleSubmit(handleUpdate)}>
        <h3 className={'overplay-cms-title'}>Update Content</h3>
        <div className={'overplay-cms-body update'}>
            {isVideo ? (
              <div>
                <div>CONTENT</div>
                <div style={{margin: '15px 0'}} className={'overplay-cms-body-border'}/>
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                      <GalleryCMSVideoChoose onSetReplace={() => {
                        setIsReplacing(!isReplacing)
                        setAcceptType('video')
                        setProcessingFileType('file')
                      }} item={item} value={value} onChange={onChange} image={image} />
                  )}
                />
                {errors?.file?.message != null && <Error style={{marginTop: '15px'}} value={errors?.file?.message === '' ? 'Please fill out this field.' : errors?.file?.message } />}

                <div>THUMBNAIL</div>
                <div style={{margin: '15px 0'}} className={'overplay-cms-body-border'}/>

                <Controller
                  control={control}
                  name="thumbnail"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                      <GalleryCMSImageChoose onSetReplace={() => {
                        setIsReplacing(!isReplacing)
                        setAcceptType('image')
                        setProcessingFileType('thumbnail')
                      }} item={item} value={value} onChange={onChange} image={image} />
                  )}
                />
                {errors?.thumbnail?.message != null && <Error style={{marginTop: '15px'}} value={errors?.thumbnail?.message === '' ? 'Please fill out this field.' : errors?.thumbnail?.message } />}

                <div style={{margin: '15px 0'}} className={'overplay-cms-body-border'}/>

              </div>
            ) : (
              <>
                <Controller
                    control={control}
                    name="file"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <GalleryCMSImageChoose onSetReplace={() => {
                          setIsReplacing(!isReplacing)
                          setAcceptType('image')
                          setProcessingFileType('file')
                        }} item={item} value={value} onChange={onChange} image={image} />
                    )}
                  />
                {errors?.file?.message != null && <Error style={{marginTop: '15px'}}  value={errors?.file?.message === '' ? 'Please fill out this field.' : errors?.file?.message } />}

                <div className={'overplay-cms-body-border'}/>
              </>
            )} 
            <Controller
                control={control}
                name="name"
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <div style={{marginBottom: '20px'}}>
                    <GalleryCMSInput label={"*Content Title"} name={"name"} value={value} onChange={onChange} />
                  </div>
                )}
            />
            {errors?.name?.message != null && <Error value={errors?.name?.message === '' ? 'Please fill out this field.' : errors?.name?.message } />}
            <Controller
                control={control}
                name="type"
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <div style={{marginBottom: '20px'}}>
                    <GalleryCMSSelect disabled={true} label={"*Content Section"} name={"type"} value={value} onChange={onChange} />
                  </div>
                )}
            />
            {errors?.type?.message != null && <Error value={errors?.type?.message === '' ? 'Please fill out this field.' : errors?.type?.message } />}
                
            {!isVideo && (
              <Controller
                control={control}
                name="unitId"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <div style={{marginBottom: '20px'}}>
                    <GalleryCMSSelectResidence options={unitSelectOptions} placeholder={'Select a residence'} label={"Associated Residence"} name={"unitId"} value={value} onChange={onChange} />
                  </div>
                )}
              />
            )}
            <Controller
              control={control}
              name="order"
              rules={{
                  validate: value => !Number.isNaN(Number(value)) || 'The order must be number'
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                  <GalleryOrderSelect options={orderSelectOptions} label={"Content Order"} name={"order"} value={value} onChange={onChange} />
              )}
            />
            {errors?.order?.message != null && <Error value={errors?.order?.message === '' ? 'Please fill out this field.' : errors?.order?.message } />}
        </div>
        <div className={'overplay-cms-footer'}>
          <div className={'overplay-cms-footer-delete'}>
            <span className={'cursor-pointer'} onClick={()=>setScreenDisplay('delete')}>DELETE CONTENT</span>
          </div>
          <div className={'overplay-cms-footer-save'}>
            <button type={'submit'}>
              SAVE
            </button>
          </div>
        </div>
      </form>
    )
  }

  const handleDelete = async () => {
    if (!item) return;
    setDisable(true)

    try {
      const deleted = await mediaAPI.deleteMedia(item.type === 'image' || item.type === 'media_images' ? item.media[0].id : item.media.find(media => media.type === 'video')?.id);
      if (deleted.data) {
        await galleryAPI.deleteGallery(item?.id);

        toast.success("Content deleted successfully!");
        handleClose && handleClose(true)
        setDisable(false)
      }
    } catch (err) {
      setDisable(false)
      toast.error(err.message);
    }
  };

  const renderComponentDelete = () => {
    return(
      <>
        <h3 className={'overplay-cms-title'}>Delete Content</h3>
        <div className={'overplay-cms-body delete'}>
          <p>Are you sure you want to delete {item?.name || ''}?</p>
        </div>
        <div className={'overplay-cms-footer'} style={{justifyContent: 'center'}}>
          <div className={'overplay-cms-footer-save'}>
            <button type={'button'} onClick={handleDelete} disabled={isDisable}>
              YES, DELETE
            </button>
          </div>
        </div>
      </>
    )
  }

  const onFileChange = async (e) => {
    const files = Array.from(e.target.files)

    if (acceptType === 'video') {
      if (!files[0].type.startsWith('video')) {
        setError(processingFileType, {message: 'The file failed to upload: the format is not supported.'})
        setIsReplacing(false)

        return
      }
    } else {
      if (!files[0].type.startsWith('image')) {
        setError(processingFileType, {message: 'The file failed to upload: the format is not supported.'})
        setIsReplacing(false)
        return
      }
    }

    if(e.target.files.length > 0) {
      const files = Array.from(e.target.files)
      setValue(processingFileType, files[0])
      setIsReplacing(false)
      clearErrors(processingFileType)
    } 
  }

 return (
   <Modal className={'overplay-cms'} show={show} onHide={handleClose}>
      {createPortal(
          <div style={{position: 'fixed', right: '30px', top: '30px', zIndex: 2000}}>
              <div className="close-btn">
        <img src={closeIcon} alt="close-icon" onClick={() => handleClose()} />
      </div>
          </div>,
          document.body
      )}
      {isReplacing ? (
        <div>
          <h3 className={'overplay-cms-title'}>{processingFileType === 'file' ? 'Replace Content' : 'Replace Thumbnail'}</h3>
          <button type={'button'} className={'browse-file-btn'} onClick={()=>fileInputRef.current?.click()}>
            BROWSE FILES
          </button>
          <input type={'file'} ref={fileInputRef} style={{display: "none"}} onChange={onFileChange} accept={`${acceptType}/*`}/>
          <div>
            <p className={'browse-file-desc'}>Or import from an Inflight URL</p>
            <div className={'browse-file-text-url'}>
              <input
                placeholder={'Add an Inflight URL'}
              />
              <button type={'button'} className={'browse-file-text-url-btn'} >
                IMPORT
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={'overplay-cms-component '+(screenDisplay === 'update' ? 'active' : '')}>
            {renderComponentUpdate()}
          </div>
          <div className={'overplay-cms-component '+(screenDisplay === 'delete' ? 'active' : '')}>
            {renderComponentDelete()}
          </div>
        </>
      )}
   </Modal>
 )
}

export default GalleryCMSUpdateOverplay
