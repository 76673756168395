import React from "react";

import "./index.scss";

const CMSInput = (props) => {
  const { error, label, placeholder, type, onChange, value } = props;

  return (
    <div className="custom-input">
      <label htmlFor="input">{label}</label>
      <input
        type={type}
        id={label}
        value={value}
        placeholder={placeholder}
        onChange={(val) => onChange?.(val)}
      />
      {!!error && <span className="error">{error}</span>}
    </div>
  );
};

export default CMSInput;
