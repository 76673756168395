import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import GallerySecondary from "../gallery-secondary";
import { getMediaUrl } from "../../helper/media";
import socket from "../../helper/socket";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";

const data = [
  {
    urlImg: "/media/team-01-v2.png",
    title: "Related Group",
    type: "Developer",
    description:
      "Founded in 1979, Related Group has enhanced skylines with iconic development characterized by innovative design, enduring quality and inclusive living. Through groundbreaking partnerships with world-renowned architects, designers and artists, Related redefines urban environments on a global scale, fostering distinctive dynamic communities and symbolic landmarks that have become local sources of pride. Since its inception, the company has built, rehabilitated and managed over 100,000 condominium, rental and commercial units. The firm is one of the largest privately owned businesses in the U.S. with a development portfolio in excess of $40 billion in 40 years.",
  },
  {
    urlImg: "/media/team_02.png",
    title: "Integra Investments",
    type: "Developer",
    description:
      "Founded in 2009, Integra Investments is a Miami-based, multi-disciplinary real estate investment and development firm dedicated to creating value, fostering growth, and positively impacting the communities it serves. The firm’s creative approach to real estate, supported by a diverse team of best-in-class professionals with specialized expertise, has allowed Integra to successfully invest across several asset classes. With its focus being on the residential, office, mixed-use, hotel and marina asset classes, Integra targets opportunistic investments in niche markets with high barriers to entry. Since its inception, Integra has managed over 6.3 million square feet of real estate developments across 20 different markets.",
  },
  {
    urlImg: "/media/team_03.png",
    title: "Robert A.M Stern Architects",
    type: "design architect",
    description:
      "RAMSA’s signature style — known as modern classicism — epitomizes timeless design at its most elegant. Enhanced by opulent amenities and modern conveniences, RAMSA buildings possess a “built-in history” that transcends their cornerstone dates, and have a track record of selling at a premium to the market.",
  },
  {
    urlImg: "/media/team_04.png",
    title: "Rockwell Group",
    type: "interior architect",
    description:
      "David Rockwell has consistently expanded the parameters of interior design. His firm’s work has transformed hospitality and residential spaces into experiential environments that are among the most creative, desirable, and talked about places in the world.",
  },
  {
    urlImg: "/media/team_05.png",
    title: "Enea Garden Design",
    type: "landscape architect",
    description:
      "Founded on the belief that everyone should be able to experience the beauty and value of nature daily, Enea has grown to become one of the leading landscape architecture firms in the world. The company’s hallmark is its success at achieving architectural synergy between home and garden, where all details — materials, artwork, furniture, and plantings — complement and support one another.",
    position: 'right'
  },
  {
    urlImg: "/media/team_06.png",
    title: "Related Realty",
    type: "exclusive marketing and sales",
    description:
      "Exclusive marketing and sales",
  },
];

const Team = ({ isPresentation }) => {
  const [isShowGallery, setShowGallery] = useState(false);
  const [idxSelected, setIdxSelected] = useState(0);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_GALLERY_TEAM) {
          handleShowGallery(content?.data?.galleryIdx);
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY_TEAM) {
          handleCloseGallery();
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, []);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_TEAM, {
        galleryIdx: idx,
      });
    }
    setIdxSelected(idx);
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_TEAM);
    }
    setShowGallery(false);
    setIdxSelected(0);
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <>
      <div
        ref={pageRef}
        onScroll={onScroll}
        className="static-page-wf teamPage bg-image-page"
        style={{ backgroundImage: `url(./images/bg-holding.png)` }}
      >
        <div className="wrapper row">
          {data.map((item, idx) => {
            return (
              <div key={`${idx}-units`} className="col-md-4 col-5 mb-5">
                <div
                  className="card-image"
                  onClick={() => handleShowGallery(idx)}
                >
                  <img src={`${getMediaUrl(item.urlImg)}`} />
                  <div className="bg-bottom">
                    <span>{item.title}</span>
                    <span>{item.type}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isShowGallery && (
        <GallerySecondary
          data={data}
          indexInit={idxSelected}
          onClose={handleCloseGallery}
          isPresentation={isPresentation}
        />
      )}
    </>
  );
};

export default Team;
