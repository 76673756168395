import React from "react";

import "./sidenav.scss";

const SideNav = (props) => {
  return (
    <div
      id={props.id}
      className={`sidenav ${props.isDark && "sidenav--dark"} ${
        props.position === "left" ? "float-start" : "float-end"
      } d-flex-column h-100 ${props.isTransparent ? "invisible" : ""}`}
    >
      {!!props.heading && (
        <div className="sidenav__heading">
          <h2 className="text-capitalize f-title">{props.heading}</h2>
        </div>
      )}
      <div className="sidenav__body d-flex-column">{props.children}</div>
    </div>
  );
};

export default SideNav;
