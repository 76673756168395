import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import closeIcon from "../../../assets/images/close-white.svg";
import preferenceApi from '../../../apis/api/preference';
import { getMediaUrl } from "../../../helper/media";
import LoadingIcon from "../../../components/loading-icon";

const CustomerFavoritesModal = ({ customer, show, setShow }) => {
	const [preference, setPreference] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		customer && getCustomerPreference();
	}, [customer]);

	const getCustomerPreference = async () => {
		try {
			setLoading(true);
			const res = await preferenceApi.getCustomerPreference({
				customer: customer?.id,
			});
			setLoading(false);
			setPreference(res.data);
		} catch (error) {
			setLoading(false);
		}
	}
	
	const handleClose = () => {
		setShow(false);
	};

	const renderListProperty = () => {
    return (preference?.units || []).map((item, index) => {
      return (
        <CSSTransition key={item.id} timeout={200} classNames="fade-item">
          <tr
						key={item?.id}
						style={{
							display: "table",
							width: "100%",
							tableLayout: "fixed",
						}}
					>
						<td className="col-2">Residence {item?.name}</td>
						<td className="col-1">{item?.floorplanNumber}</td>
						<td className="col-1">{item?.level}</td>
						<td className="col-1">{item?.availabilityStatus}</td>
						<td className="col-1">{item?.bedrooms}</td>
						<td className="col-1">{item?.bathrooms}</td>
						<td className="col-1">{item?.powderrooms}</td>
						<td className="col-1">{item?.interiorSF}</td>
						<td className="col-1">{item?.exteriorSF}</td>
						<td className="col-1">{item?.price}</td>
					</tr>
        </CSSTransition>
      );
    });
  };

	const renderGallery = () => {
		if (!preference || !preference?.units) return null;
		const sampleGalleries = preference?.units[0]?.gallery?.media || [];
		return (
			<div className="gallery">
				{
					sampleGalleries.slice(0, 3).map((item, index) => {
						return (
							<div key={item.id} className="gallery-item">
								<img src={`${getMediaUrl(item.path)}`} />
								<span>Content Title</span>
							</div>
						);
					})
				}
			</div>
		);
	}

	const renderContent = () => {
		if (loading) return <div className="loading"><LoadingIcon /></div>

		if (!preference || !preference?.units) return <div className="content">No favorites found.</div>;

		return (
			<div className="content">
				<div className="table-responsive-custom">
					<table className="table-custom">
						<thead>
							<tr
								style={{
									display: "table",
									width: "100%",
									tableLayout: "fixed",
								}}
							>
								<th className="col-2">Name</th>
								<th className="col-1">Residence</th>
								<th className="col-1">Floor</th>
								<th className="col-1">Availability</th>
								<th className="col-1">Beds</th>
								<th className="col-1">Baths</th>
								<th className="col-1">Powder</th>
								<th className="col-1">SF (Int.)</th>
								<th className="col-1">SF (Ext.)</th>
								<th className="col-1">Price</th>
							</tr>
						</thead>
						<tbody
							style={{
								display: "block",
								maxHeight: "200px",
								overflowY: "auto",
								overflowX: "hidden",
							}}
						>
							<TransitionGroup>
								{renderListProperty()}
							</TransitionGroup>
						</tbody>
					</table>
				</div>
				{/* {renderGallery()} */}
			</div>
		);
	}

	return (
		<Modal
			className="wrap-customer-favorites-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{customer?.firstname} {customer?.surname}’s Favorites</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				{renderContent()}
			</Modal.Body>
		</Modal>
	);
};

export default CustomerFavoritesModal;
