export const groupBy = (array, key, condition = () => true) => {
  const result = [];
  (array || []).map((item) => {
    if (!result.includes(item[key]) && condition(item)) {
      result.push(item[key]);
    }
  });
  return result;
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const formatMoney = (price) => {
  if (!price) return 0;

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
  });

  return formatter.format(price);
}
