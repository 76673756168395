import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import "../../home-gallery/index.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getUploadedMediaUrl, getUploadedServerMediaUrl } from "../../../helper/media";
import galleryApi from "../../../apis/api/gallery";
import {
  GALLERY_TYPE,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../../constants/options";
import { GalleryPrimaryModal } from "../../gallery-primary-modal";
import socket from "../../../helper/socket";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Image from "../../image";

const imageMatrix = [
  [0, 1],
  [2],
  [3, 4],
  [5, 6],
  [7, 8],
  [9, 10],
  [11, 12],
  [13],
  [14, 15],
  [16, 17],
  [18],
  [19],
  [20, 21],
  [22, 23],
  [24, 25],
  [26],
  [27],
  [28, 29],
  [30]
];

const generateExtendedPattern = (pattern, repeatedTimes = 3) => {
  const extendedPattern = Array(repeatedTimes).fill(pattern).flat();

  const matrix = [];
  let currentNumber = 0;

  for (let row of extendedPattern) {
    const newRow = row.map(() => currentNumber++);
    matrix.push(newRow);
  }

  return matrix;
}

export const MediaImages = ({ isPresentation }) => {
  const [isShowGallery, setShowGallery] = useState(false);
  const [idxSelected, setIdxSelected] = useState(0);
  const [data, setData] = useState([]);
  const mediaData = useMemo(() => data?.map((item) =>
    item?.media?.length > 0 ? item?.media[0] : null
  ), [data]);
  const repeatedTimes = useMemo(() => Math.ceil(mediaData.length / imageMatrix.length), [mediaData])
  const pattern = generateExtendedPattern(imageMatrix, repeatedTimes);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setData(res?.data.filter(e => e.media[0]?.isActive !== undefined ? e.media[0]?.isActive : true).sort((a, b) => (a.media?.[0]?.order ?? 999) - (b.media?.[0]?.order ?? 999)));
      }
    } catch (e) {
      console.log("error get floor");
    }
  };
  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_MEDIA_GALLERY_PAGE) {
          handleShowGallery(content?.data?.galleryIdx);
        }
        if (content.action === ACTION_NAME.CLOSE_MEDIA_GALLERY_PAGE) {
          handleCloseGallery();
        }
      });
    }
  }, []);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_MEDIA_GALLERY_PAGE, {
        galleryIdx: idx,
      });
    }
    setIdxSelected(idx);
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_MEDIA_GALLERY_PAGE);
    }
    setShowGallery(false);
    setIdxSelected(0);
  };

  const parseUrl = imgPath => (imgPath?.startsWith('/media/media_images') || imgPath?.startsWith('/media/media_films')) ?  getUploadedMediaUrl(imgPath) : getUploadedServerMediaUrl(imgPath);

  return (
    <>
      <div className="media-images-content">
        <div className="media-wrapper">
          {pattern?.map((mediaRow, index) => mediaRow[0] < mediaData?.length && (
                <div key={index} className="media-row">
                  {mediaRow?.map((mediaIndex) => mediaIndex < mediaData?.length && (
                    <div
                      key={mediaIndex}
                      onClick={() => handleShowGallery(mediaIndex)}
                      className="media-item"
                    >
                      <Image
                        alt={mediaData[mediaIndex]?.name}
                        src={parseUrl(mediaData[mediaIndex]?.path)}
                        imgClassName="media-img"
                      />
                      <div className="media-item-text">
                        {mediaData[mediaIndex]?.name}
                      </div>
                    </div>
                  ))}
                </div>
              ))
            }
        </div>
      </div>
      {isShowGallery && (
        <div className="media-images-gallery">
          <GalleryPrimaryModal
            data={mediaData?.map((item) => ({
              id: item?.id,
              urlImg: item?.path,
              title: item?.name,
            }))}
            indexInit={idxSelected}
            onClose={handleCloseGallery}
            isPresentation={isPresentation}
          />
        </div>
      )}
    </>
  );
};
