import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "./../components/Search";
import { reqGetListCustomers } from "../../../reduxs/cms/action";
import EditCustomerModal from "./EditCustomerModal";
import iconSearch from "../../../assets/images/cms-search.svg";
import iconCMSAdd from "../../../assets/images/cms-customer-add.svg";
import "./index.scss";
import AddCustomerModal from "./AddCustomerModal";
import { toast } from "react-toastify";
import CustomerFavoritesModal from "./CustomerFavoritesModal";

export const PurchaseOptions = [
  { label: "Primary Residence", value: "Primary Residence" },
]

const Row = ({ data, onEditCustomer, onViewFavorite }) => {
  const handleCopyURL = () => {
    if (!data) return;

    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/post-engagement/${data.id}`);
    toast.success('Copied customer session url!');
  }

  return (
    <tr
      key={data?.id}
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }}
      className="collapsed"
    >
      <td className="col-2">{data?.name}</td>
      <td className="col-2">{data?.email}</td>
      <td className="col-2">{data?.mobile}</td>
      <td className="col-2">{data?.city}</td>
      <td className="col-2">{data?.country}</td>
      <td className="col-2">{data?.nationality}</td>
      <td className="col-1" onClick={() => onViewFavorite?.(data)}>
        VIEW
      </td>
      <td className="col-1 action" onClick={() => handleCopyURL()}>
        COPY
      </td>
      <td className="col-1 action edit" onClick={() => onEditCustomer?.(data)}>
        EDIT
      </td>
    </tr>
  );
}

const CMSCustomers = () => {
  const dispatch = useDispatch();

  const [isShowEditCustomerModal, setIsShowEditCustomerModal] = useState(false);
  const [isShowAddCustomerModal, setIsShowAddCustomerModal] = useState(false);
  const [isShowCustomerFavoritesModal, setIsShowCustomerFavoritesModal] = useState(false);
  const [seletedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);

  const customers = useSelector((state) => state.cms.customers);

  useEffect(() => {
    getListCustomers();
  }, [search, isSortAsc])

  useEffect(() => {
    getListCustomers();
  }, []);

  const getListCustomers = () => {
    dispatch(reqGetListCustomers({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onSaveSuccess = () => {
    getListCustomers();
  }

  const onCreateSuccess = () => {
    getListCustomers();
  }

  const onEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsShowEditCustomerModal(true);
  }

  const onAddNewCustomer = () => {
    setIsShowAddCustomerModal(true);
  }

  const onViewFavorite = (customer) => {
    setSelectedCustomer(customer);
    setIsShowCustomerFavoritesModal(true);
  }

  const renderListProperty = () => {
    return (customers || []).map((item, index) => {
      return (
        <CSSTransition key={item.id} timeout={200} classNames="fade-item">
          <Row
            data={item}
            index={index}
            onEditCustomer={onEditCustomer}
            onViewFavorite={onViewFavorite}
          />
        </CSSTransition>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="table-responsive-custom">
        <table className="table-custom">
          <thead>
            <tr
              style={{
                display: "table",
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <th className="col-2">Name</th>
              <th className="col-2">Email Address</th>
              <th className="col-2">Phone</th>
              <th className="col-2">City</th>
              <th className="col-2">Country</th>
              <th className="col-2">Nationality</th>
              <th className="col-1">{screen.availWidth < 1200 ? "Fav." : "Favorites"}</th>
              <th className="col-1 center">URL</th>
              <th className="col-1" />
            </tr>
          </thead>
          <tbody
            style={{
              display: "block",
              maxHeight: "calc(100vh - 400px)",
            }}
          >
            <TransitionGroup>
              {renderListProperty()}
            </TransitionGroup>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title">Customers CMS</h1>
        <div className="functinalities flex">
          <div className="add-new-customer" onClick={onAddNewCustomer}>
            <img src={iconCMSAdd} alt="icon-cms-customer" />
            <span>Add New Customer</span>
          </div>
          <div className="page-header__input-search">
            <img src={iconSearch} alt="icon-search" />
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="form-control"
              type="text"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="page-body customers-page-body">
        {renderTable()}
      </div>
      { isShowEditCustomerModal &&
        <EditCustomerModal 
          show={isShowEditCustomerModal}
          setShow={setIsShowEditCustomerModal}
          customer={seletedCustomer}
          onSaveSuccess={onSaveSuccess}
        />
      }
      {
        isShowAddCustomerModal &&
        <AddCustomerModal
          show={isShowAddCustomerModal}
          setShow={setIsShowAddCustomerModal}
          onCreateSuccess={onCreateSuccess}
        />
      }
      {
        isShowCustomerFavoritesModal && 
        <CustomerFavoritesModal
          show={isShowCustomerFavoritesModal}
          setShow={setIsShowCustomerFavoritesModal}
          customer={seletedCustomer}
        />
      }
    </div>
  );
};
export default CMSCustomers;
