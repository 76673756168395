import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import GallerySecondary from "../gallery-secondary";
import socket from "../../helper/socket";
import {
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";

const data = [
  {
    urlImg: "/media/vision.png",
    title: "The Tower",
    type: "",
    description:
      "50 stories featuring 152 residences, including penthouses, sky villas and townhomes</br></br>" +
      "A collection of two- to six-bedroom homes</br></br>" +
      "Stunning views of the Miami skyline, Biscayne Bay and the Atlantic Ocean</br></br>" +
      "Porte-cochère with commissioned art installation and signature water feature</br></br>" +
      "Private residential lobby attended 24/7</br></br>" +
      "24-hour concierge and butler</br></br>" +
      "On-site valet parking and self-parking spaces</br></br>" +
      "EV charging stations</br></br>" +
      "Luxury house car service",
  },
];
const Vision = ({ isPresentation }) => {
  const [isShowGallery, setShowGallery] = useState(false);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_GALLERY_VISION) {
          handleShowGallery();
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY_VISION) {
          handleCloseGallery();
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      });
    }
  }, []);

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_VISION);
    }
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_VISION);
    }
    setShowGallery(false);
  };

  const onScroll = (event) => {
    if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div className="basic-page vision-page" style={{ padding: isPresentation ? "0" : undefined }}>
      <div
        ref={pageRef} onScroll={onScroll}
        className="bg-image-page"
        style={{ backgroundImage: `url(./images/bg-holding.png)` }}
      >
        <div className="visibility"/>
        <div className="wrap-text">
          <span className="title-top">The Best of All Worlds</span>
          <span className="description">
            Designed by the renowned New York–based firm Robert A.M. Stern
            Architects (RAMSA), The St. Regis Residences, Miami blend the
            aesthetic of golden-age ocean liners with the rigor and appeal of
            modern-day architecture. Melding in effective yet surprising ways,
            those two sources of inspiration yield a design that is at once
            fluid, purposeful, and eminently livable.
          </span>
          <span className="description">
            The St. Regis Residences, Miami are first among equals, and are
            precisely situated on the site to ensure every residence has the
            most expansive views possible. The design of the building changes as
            it reaches skyward—the facade revealing artful setbacks with private
            terraces; undulating curves that echo the waves of the bay below.
          </span>
        </div>
        <button
          type="submit"
          onClick={handleShowGallery}
          className="btn-enter-white btn-absolute"
        >
          ST. REGIS BUILDING SPECIFICATIONS
        </button>
      </div>
      {isShowGallery && (
        <GallerySecondary
          data={data}
          indexInit={0}
          onClose={handleCloseGallery}
          isPresentation={isPresentation}
        />
      )}
    </div>
  );
};

export default Vision;
