import { getUploadedMediaUrl } from "../../helper/media";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import './list-item.scss'
import GalleryCMSUpdateOverplay from "./overplay/update";
import {getUploadedServerMediaUrl} from '../../helper/media'
import { toast } from "react-toastify";
import mediaAPI from "../../apis/api/media";
// render film floorPlan
const GalleryCmsListItem = ({ uniqueOrder, item, getListGalleries, unitList}) => {
  const checkedDefault = useMemo(() => {
    return item.type === 'image' || item.type === 'media_images' ?
    (item.media[0]?.isActive !== undefined ? item.media[0]?.isActive : true) :
    (item.media.find(media => media.type === 'video')?.isActive !== undefined ? item.media.find(media => media.type === 'video')?.isActive : true);
  })
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(checkedDefault);
  const dispatch = useDispatch();

  const { media } = item;
  const imgPath =  (item?.thumbnail ?? media[0]?.thumbnail ?? media[0]?.path) || ''
  
  //backwards compatible
  const thumbnail = (imgPath.startsWith('/media/media_images') || imgPath.startsWith('/media/media_films')) ?  getUploadedMediaUrl(imgPath) : getUploadedServerMediaUrl(imgPath)
  const getType = (type) => {
    if(type === "media_images") return "Image";
    if(type === "brickell_film") return "Film";
    if(type === "floorPlan") return "Floorplans";
    return ""
  }

  const handleUpdateActive = async (e, selectedItem) => {
    if (!selectedItem) return;
    setChecked(e.target.checked);
    try {
      const formData = new FormData();
      formData.append("type", item.type === 'image' || item.type === 'media_images' ? "image" : 'video');
      formData.append("isActive", e.target.checked);
      const updated = await mediaAPI.updateMedia(selectedItem.id, formData);
      await getListGalleries();
      if (updated?.data) {
        toast.success("Content updated successfully!");
      }
    } catch (err) {
      toast.error("Content updated failed!");
    }
  };

  useEffect(() => {
    setChecked(checkedDefault);
  }, [checkedDefault]);
  return(
    <div className="wrap-gallery-item">
      <div className={"list-item-gallery-image-container"}>
        {/*<img src={getImagePath(thumbnail)} />*/}
        <img src={thumbnail} alt={item?.name} />

      </div>
      <div className="list-item-gallery-details"   style={{display: "flex", alignItems: 'center'}}>
        <div className="gallery-title">
          <p className={'gallery-title-name'}>
            {item?.name ? item?.name : getType(item?.type)}
          </p>
          <p className={'gallery-title-desc'}>Order number: {item?.media?.find(media => media.type === 'video')?.order || item?.media[0]?.order || item?.media[1]?.order || '#'}</p>
        </div>
        <div className={'gallery-title-edit'} style={{display: "flex", alignItems: 'center'}}>
          <div className="form-switch my-n1">
            <input
              className="form-check-input"
              type="checkbox"
              id="content-state-1"
              checked={checked}
              style={{width: "3rem"}}
              onChange={(e) => handleUpdateActive(e, item.type === 'image' || item.type === 'media_images' ? media[0] : media.find(media => media.type === 'video'))}
            />
            <label className="form-check-label" htmlFor="content-state-1" />
          </div>
          <p className={'cursor-pointer'} onClick={()=>setShow(true)} style={{textDecoration: "underline", fontFamily: "Suisse Intl", fontSize: '13px'}}>EDIT</p>
        </div>
      </div>
      {show && (
        <GalleryCMSUpdateOverplay uniqueOrder={uniqueOrder} unitList={unitList} image={thumbnail} item={item} show={show} handleClose={(isRefresh)=> {
          if (isRefresh) {
              getListGalleries()
          }
          setShow(false)
          }}
        />
      )} 

    </div>
  )
}
export default GalleryCmsListItem
