import React from 'react';
import { Provider } from 'react-redux';
import { Router } from './routers/Router';
import store from './reduxs/store';
import './App.scss';
import { ToastContainer, toast } from 'react-toastify';

const App = () => (
  <Provider store={store}>
    <Router />
    <ToastContainer />
  </Provider>
);

export default App;
