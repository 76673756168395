import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getAmenitiesList = (data) => client.get(endPoint.AMENITIES_GET_LIST, data);
const getAmenityDetail = (hotspotId, data) =>
  client.get(endPoint.AMENITIES_DETAIL.replace(':id', hotspotId), data);
const updateAmenity = (amenityId, data) =>
  client.put(endPoint.AMENITIES_UPDATE.replace(':id', amenityId), data);
export default {
  getAmenitiesList,
  getAmenityDetail,
  updateAmenity,
};
