import React, { useEffect, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import './overplay.scss'
import GalleryCMSInput from "../form/input";
import GalleryCMSSelect from "../form/select";
import GalleryCMSResidenceSelect from "../form/select-residence";
import GalleryCMSBrowseFile from "../form/browse-file";
import {Controller, useForm} from "react-hook-form";
import Error from "../form/error";
import {reqCreateGalleryCMS} from "../../../reduxs/cms/action";
import {useDispatch} from "react-redux";
import {useState, useRef} from "react";
import mediaAPI from '../../../apis/api/media'
import unitApi from "../../../apis/api/unit";
import galleryAPI from '../../../apis/api/gallery'
import { toast } from "react-toastify";
import { createPortal } from 'react-dom';
import closeIcon from '../../../assets/images/close-white.svg'
import GalleryOrderSelect from "../form/select-order";

const GALLERY_TYPE_IMAGE = 'media_images'
const GALLERY_TYPE_VIDEO = 'brickell_film'

export function addNextNumber(arr) {
    let newArr = [...arr];
    if (arr.length === 0) {
        newArr.push(1);
    } else {
      const maxNumber = Math.max(...newArr);
      newArr.push(maxNumber + 1);
    }
    return newArr;
}

const GalleryCMSCreateOverplay = ({ uniqueOrder, isImage, show, handleClose, unitList = []}) => {
    const dispatch = useDispatch();
    const [isDisable, setDisable] = useState(false)
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        setValue,
        getValues
    } = useForm({
        defaultValues: {
            name: '',
            type: '',
            unitId:  '', 
            order: ''
        }
    })

    useEffect(() => {
        setValue('type', isImage ? 'media_images' : 'brickell_film');
    }, [isImage, setValue]);

    const watchType = watch("type", false) || GALLERY_TYPE_IMAGE
    const unitSelectOptions = unitList.map(unit => ({
        value: unit.id,
        text: unit.name || ''
    }))
    const newOrderList = useMemo(() => addNextNumber(uniqueOrder), [uniqueOrder]);
    const orderSelectOptions = useMemo(() => newOrderList.map(order => ({
        value: order,
        text: order
    })), [newOrderList]);

    const onSubmit = async (data) => {
        toast.info("Please wait, media file uploading!");
        if (data.type === GALLERY_TYPE_IMAGE) {
            onSubmitImage(data)
        } else {
            onSubmitVideo(data)
        }
    }

    const onSubmitImage = async (data) => {
        const values = getValues();
        setDisable(true)
        try {
            const formData = new FormData();
            formData.append('type', 'image');
            formData.append('name', data?.name)
            formData.append('file', data.file);
            formData.append("path", "media/media_images/image_gallery");
            const uploaded = await mediaAPI.uploadMedia(formData);
            if (uploaded?.data) {
                const payload = {
                    type: "media_images",
                    media: [uploaded?.data?.id],
                    name: data?.name,
                    path: '',
                    unitId: data?.unitId || undefined,
                }
                await galleryAPI.createGalleryV2(payload);
                const mediaData = new FormData();
                mediaData.append("type", "image");
                mediaData.append("order", !!data?.order ? Number(data?.order) : newOrderList[newOrderList.length - 1]);
                await mediaAPI.updateMedia(uploaded?.data?.id, mediaData)
                toast.success('Add new content successfully!');
            }
            reset({
                name: '',
                type: values.type,
                unitId:  '', 
                order: ''
            })
            handleClose && handleClose(true)
        } catch (err) {
            setError('file', { message: 'The file failed to upload: an unknown error occurred.' })
        }
        setDisable(false)
    }

    const onSubmitVideo = async (data) => {
        const values = getValues();
        setDisable(true)
        try {
            const formData = new FormData();
            formData.append("type", "video");
            formData.append("name", data?.name);
            formData.append("path", "media/media_videos/films");
            formData.append("file", data.file);

            const formDataThumbnail = new FormData();
            formDataThumbnail.append('type', 'image');
            formDataThumbnail.append('name', data?.name)
            formDataThumbnail.append('file', data.thumbnail);
            formDataThumbnail.append("path", "media/media_images/image_gallery");

            const uploaded = await mediaAPI.uploadMedia(formData);
            const uploadedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);
            const payload = data?.unitId ? {
                type: 'brickell_film',
                name: data?.name,
                media: [uploaded?.data?.id, uploadedThumbnail?.data?.id],
                thumbnail: uploadedThumbnail?.data?.path,
                videoUrl: uploaded?.data?.path,
                unitId: data?.unitId
            } : {
                type: 'brickell_film',
                name: data?.name,
                media: [uploaded?.data?.id, uploadedThumbnail?.data?.id],
                thumbnail: uploadedThumbnail?.data?.path,
                videoUrl: uploaded?.data?.path,
            }
            if (uploaded?.data && uploadedThumbnail?.data) {
                await galleryAPI.createGalleryV2(payload);
                const mediaData = new FormData();
                mediaData.append("type", "video");
                mediaData.append("order", !!data?.order ? Number(data?.order) : newOrderList[newOrderList.length - 1]);
                await mediaAPI.updateMedia(uploaded?.data?.id, mediaData)
                toast.success("Add new content successfully!");
                reset({
                    name: '',
                    type: values.type,
                    unitId:  '', 
                    order: ''
                })
                handleClose && handleClose(true)
            }
        } catch (err) {
            setError('file', { message: 'The file failed to upload: an unknown error occurred.' })
            setError('thumbnail', { message: 'The file failed to upload: an unknown error occurred.' })
        }
        setDisable(false)
    }

    const onHide = () => {
        const values = getValues();
        reset({
            name: '',
            type: values.type,
            unitId:  '', 
            order: ''
        })
        clearErrors()
        handleClose && handleClose()
    }
  return (
    <Modal className={'overplay-cms'} show={show} onHide={onHide} centered>
        {createPortal(
            <div style={{position: 'fixed', right: '30px', top: '30px', zIndex: 2000}}>
                <div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={() => onHide()} />
				</div>
            </div>,
            document.body
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className={'overplay-cms-title'}>Add New Content</h3>
            <div className={'overplay-cms-body update'}>
                {watchType === GALLERY_TYPE_IMAGE && (
                    <>
                        <div style={{margin: '15px 0' }} className={'overplay-cms-body-border'}/>
                        <Controller
                            control={control}
                            name="file"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <GalleryCMSBrowseFile clearErrors={clearErrors} fileType='file' setFileError={setError} type={watchType} onChange={onChange} />
                                )
                            }}
                        />
                        {errors?.file?.message != null && <Error style={{marginTop: '15px'}} value={errors?.file?.message === '' ? 'Please fill out this field.' : errors?.file?.message } />}
                    </>
                )}

                {watchType === GALLERY_TYPE_VIDEO && (
                    <>
                        <div>CONTENT</div>
                        <div style={{margin: '15px 0'}} className={'overplay-cms-body-border'}/>
                        <Controller
                            control={control}
                            name="file"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <GalleryCMSBrowseFile clearErrors={clearErrors} fileType='file' setFileError={setError} notInflight accept="video" type={watchType} onChange={onChange} />
                                )
                            }}
                        />
                        {errors?.file?.message != null && <Error value={errors?.file?.message === '' ? 'Please fill out this field.' : errors?.file?.message } />}

                        <div>THUMBNAIL</div>
                        <div style={{margin: '15px 0'}} className={'overplay-cms-body-border'}/>
                        <Controller
                            control={control}
                            name="thumbnail"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <GalleryCMSBrowseFile clearErrors={clearErrors} fileType='thumbnail' setFileError={setError} notInflight type={watchType} onChange={onChange} />
                                )
                            }}
                        />
                        {errors?.thumbnail?.message != null && <Error value={errors?.thumbnail?.message === '' ? 'Please fill out this field.' : errors?.thumbnail?.message } />}
                    </>
                )}  

                <div className={'overplay-cms-body-border'}/>

                <Controller
                    control={control}
                    name="name"
                    rules={{
                        required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div style={{marginBottom: '20px'}}>
                            <GalleryCMSInput placeholder={'Content Title'} label={"*Content Title"} name={"name"} value={value} onChange={onChange} />
                        </div>
                    )}
                />
                {errors?.name?.message != null && <Error value={errors?.name?.message === '' ? 'Please fill out this field.' : errors?.name?.message } />}

                <Controller
                    control={control}
                    name="type"
                    rules={{
                        required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div style={{marginBottom: '20px'}}>
                            <GalleryCMSSelect disabled={true} placeholder={'Content Section'} label={"*Content Section"} name={"type"} value={value} onChange={onChange} />
                        </div>
                    )}
                />
                {errors?.type?.message != null && <Error value={errors?.type?.message === '' ? 'Please fill out this field.' : errors?.type?.message } />}
                {watchType === GALLERY_TYPE_IMAGE && (
                    <div style={{marginBottom: '20px'}}>
                        <Controller
                            control={control}
                            name="unitId"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <GalleryCMSResidenceSelect options={unitSelectOptions} placeholder={'Select a residence'} label={"Associated Residence"} name={"unitId"} value={value} onChange={onChange} />
                            )}
                        />
                    </div>
                )}
                <Controller
                    control={control}
                    name="order"
                    rules={{
                        validate: value => !Number.isNaN(Number(value)) || 'The order must be number'
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <GalleryOrderSelect options={orderSelectOptions} placeholder={'Select an order'} label={"Content Order"} name={"order"} value={value} onChange={onChange} />
                    )}
                />
                {errors?.order?.message != null && <Error value={errors?.order?.message === '' ? 'Please fill out this field.' : errors?.order?.message } />}

            </div>
            <div className={'overplay-cms-footer justify-center'}>
                <div className={'overplay-cms-footer-save'}>
                    <button type={'submit'} disabled={isDisable}>
                        ADD
                    </button>
                </div>
            </div>
        </form>

    </Modal>
  )
}
export default GalleryCMSCreateOverplay
