import "./index.scss";
import Markdown from "react-markdown";
import React, { useEffect, useState } from "react";
import { handleCollapse } from "../../../helper/utils";
import { useDispatch, useSelector } from "react-redux";
import { reqGetListFAQs } from "../../../reduxs/cms/action";
import { ChevronDown, ChevronUp, IconPlusSign, IconSearch } from "../../../components/svgs/icons";
import { SubmitSupportTicketModal } from "../../../components/aw-modal/submit-support-ticket-modal";

const tabs = [
  { name: 'ipad application', type: 'app', },
  { name: 'hardware', type: 'hardware', },
  { name: 'tv display', type: 'tv_display', },
  { name: 'browser', type: 'browser', },
  { name: 'content loading', type: 'loading', },
  { name: 'bug reporting', type: 'bug_report', },
];

const CMSFrequentlyAQS = () => {
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.cms.faqs);

  const [activeTab, setActiveTab] = useState(0);
  const [faqList, setFaqList] = useState(null);
  const [search, setSearch] = useState(null);
  const [showSubmitSupportTicketModal, setShowSubmitSupportTicketModal] = useState(false);

  useEffect(() => {
    dispatch(reqGetListFAQs());
  }, []);

  useEffect(() => {
    if (!faqList && faqs?.length) {
      setFaqList((faqs || []).filter(item => item.type === tabs[0].type));
    }
  }, [faqs])

  useEffect(() => {
    if (search === '') {
      setFaqList((faqs || []).filter(item => item.type === tabs[activeTab].type));
    } else if (!!search) {
      setFaqList((faqs || [])
        .filter(item => (
          item.type === tabs[activeTab].type &&
          (String(item.question).toLowerCase().includes(search.toLowerCase()) ||
          String(item.answer).toLowerCase().includes(search.toLowerCase()))
        ))
      );
    }
  }, [search]);

  const handleTabChange = (type, tabIndex) => () => {
    setSearch('');
    setFaqList((faqs || []).filter(item => item.type === type));
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div className="cms-container">
        <div className="cms-header">
          <h1 className="cms-header-title">FAQs</h1>
          <div className="cms-header-content">
            <div className="cms-header-content-left">
              {tabs.map((tab, i) => (
                <div
                  key={i}
                  className={`cms-header-item ${activeTab === i ? 'active' : ''}`}
                  onClick={handleTabChange(tab.type, i)}
                >{tab.name}</div>
              ))}
            </div>
            <div className="cms-header-content-div"/>
            <div className="cms-header-content-right">
              <div
                onClick={() => setShowSubmitSupportTicketModal(true)}
                className="cms-header-item item-with-icon">
                <IconPlusSign style={{width: 24}} fill={'#B2B2B2'}/>
                <span>SUBMIT SUPPORT TICKET</span>
              </div>

              <div className="page-header__input-search">
                <IconSearch style={{width: 24}} fill={'#00000000'}/>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="cms-content">
          <div className="cms-content-wrap">
            {faqList?.length
              ? faqList?.map((item, i) => (
                <div key={i} className="cms-content-faq">
                  <div
                    onClick={handleCollapse} data-target={`.faq-${item?.id}`}
                    className="cms-content-faq-header"
                  >
                    <div className="cms-content-faq-text">{item?.question}</div>
                    <span className={`collapse faq-${item?.id}`}>
                      <ChevronUp style={{ width: 11.4 }} fill={'#1A1A1A'} />
                    </span>
                    <span className={`collapse show faq-${item?.id}`}>
                      <ChevronDown style={{ width: 11.4 }} fill={'#1A1A1A'} />
                    </span>
                  </div>
                  <div className={`cms-content-faq-body collapse faq-${item?.id}`} id={`faq-${item?.id}`}>
                    <Markdown>{item?.answer}</Markdown>
                  </div>
                  <div className="cms-content-faq-line" />
                </div>
              ))
              : <div>No Content</div>
            }
          </div>
        </div>
      </div>
      <SubmitSupportTicketModal
        show={showSubmitSupportTicketModal} setShow={setShowSubmitSupportTicketModal}/>
    </>
  );
};
export default CMSFrequentlyAQS;
