import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PANOLENS from "panolens";
import { ImageLoader } from "three";

import "./index.scss";
import { reqIsShowVirtualTour } from "../../reduxs/unit-explore/action";
import galleryApi from "../../apis/api/gallery";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { getMediaUrl } from "../../helper/media";

const VirtualTour = (props) => {
  const dispatch = useDispatch();

  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const loader = new ImageLoader();
  const [gallery, setGallery] = useState("");
  const [panos, setPanos] = useState([]);
  const [viewer, setViewer] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);
  const isTransparent = useSelector((state) => state.home.isTransparent);

  useEffect(async () => {
    const res = await galleryApi.getGalleryDetail(activeGalleryId);

    setGallery(res.data);
  }, [activeGalleryId]);

  const handleClickCancel = () => {
    viewer?.dispose();
    dispatch(reqIsShowVirtualTour(false));
    dispatch(reqSetIsTransparent(false));
  };

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama");
    if (selector != undefined) {
      selector.replaceChildren();
    }
    let testPanos = [];
    if (gallery == undefined || gallery.media == undefined) {
      return;
    }
    const length = gallery.media.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      testPanos.push(undefined);
    }
    setPanos(testPanos);

    const firstChild = gallery.media[0];
    const image1stURL = getMediaUrl(firstChild.path);
    loader.load(
      image1stURL,
      function (image) {
        let pano = new PANOLENS.ImagePanorama(image);

        panos[0] = pano;
        viewer.add(pano);
        viewer.setPanorama(pano);
        setPanos(panos);

        // for (let j = 0; j < firstChild.infospots.length; j++) {
        //   let spot = firstChild.infospots[j];
        //   let infospot = new PANOLENS.Infospot(spot.scale, spot.image);
        //
        //   infospot.position.set(
        //     spot.position[0],
        //     spot.position[1],
        //     spot.position[2]
        //   );
        //   infospot.addEventListener("click", () => {
        //     console.log(spot.targetIndex);
        //     setActiveIndex(spot.targetIndex);
        //     if (panos[spot.targetIndex] != undefined) {
        //       viewer.add(panos[spot.targetIndex]);
        //       viewer.setPanorama(panos[spot.targetIndex]);
        //     }
        //   });
        //
        //   pano.add(infospot);
        // }

        for (let index = 1; index < gallery.media.length; index++) {
          const child = gallery.media[index];
          const imageURL = getMediaUrl(child.path);
          console.log(imageURL);

          loader.load(
            imageURL,
            function (image) {
              const panoOther = new PANOLENS.ImagePanorama(image);

              panos[index] = panoOther;
              //viewer.add(panoOther);
              setPanos(panos);

              // for (let j = 0; j < child.infospots.length; j++) {
              //   let spot = child.infospots[j];
              //   const infospot = new PANOLENS.Infospot(spot.scale, spot.image);
              //
              //   infospot.position.set(
              //     spot.position[0],
              //     spot.position[1],
              //     spot.position[2]
              //   );
              //   infospot.addEventListener("click", function () {
              //     if (panos[spot.targetIndex] != undefined) {
              //       viewer.add(panos[spot.targetIndex]);
              //       viewer.setPanorama(panos[spot.targetIndex]);
              //       setActiveIndex(spot?.targetIndex);
              //     }
              //   });
              //   infospot.show();
              //
              //   panoOther.add(infospot);
              // }
            },
            undefined,
            function (error) {
              console.error("An error happened.", error);
            }
          );
        }
      },
      undefined,
      function (error) {
        console.error("An error happened.", error);
      }
    );

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama"),
      controlButtons: [],
      autoHideInfospot: false,
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);

    //let control = viewer.getControl();
    //control.noZoom = true;
  };

  useEffect(() => {
    onInitPanorama();

    return () => {
      // viewer?.dispose();
    };
  }, [gallery]);

  const onClickDotItem = (index) => {
    if (panos[index] == undefined) return;
    const pano = panos[index];
    viewer.add(pano);
    setActiveIndex(index);
    viewer.setPanorama(pano);
  };

  const renderDotted = () => {
    return (
      <div className="dots-container">
        <div className="wrap-dots">
          {(gallery?.media || []).map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => onClickDotItem(index)}
                className={`dot-item ${activeIndex === index ? "active" : ""}`}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const showFilter = () => {
    dispatch(reqSetIsTransparent(false));
  };

  const hideGallery = () => {
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(reqSetIsTransparent(false));
  };

  return (
    <>
      <div className="main-modal view-line">
        <div className="bg-image">
          <div id="wrap-panorama" className="img-panorama" />
          {/*<button onClick={handleClickCancel} className="btn-square">*/}
          {/*  <img className="img-fluid" src="/icons/close.svg" alt="" />*/}
          {/*</button>*/}
          {renderDotted()}
        </div>
        <div className="black-content" />
      </div>
      {isTransparent && (
        <div className="wrap-close-btn">
          <div onClick={hideGallery}>
            <img src="./images/icons/arrow-left.svg" alt="" className="rotate"/>
            <span>Close viewline</span>
          </div>
          <div onClick={showFilter}>
            <img src="/icons/add.svg" alt="" />
            <span>Reopen panels</span>
          </div>
        </div>
      )}
    </>
  );
};

export default VirtualTour;
