import React, {useRef, useState} from "react";
import './image-choose.scss'
import {getUploadedServerMediaUrl} from '../../../helper/media'

const GalleryCMSImageChoose = ({item, value, onChange, image, onSetReplace}) => {
  const ref = useRef();
  const oldImage = image;
  const [src, setSrc] = useState('')
  const handleClick = () => {
    onSetReplace()
  } 
  const renderImage = () => {
    if (value) {
      return URL.createObjectURL(value);
    }
    return "/images/image.png";  
  }

  const handleChange = async (e)  => {
      if(e.target.files.length > 0) {
          const files = Array.from(e.target.files)
          setSrc(files[0])
          onChange(files[0])
      } else {
          setSrc(oldImage)
          onChange && onChange(null)
      }
  }

  return(
    <div className={'image-choose cursor-pointer'} onClick={()=>handleClick()}>
      <img src={value ? renderImage() : image} style={{width: '100%'}} width={"100%"} />
      <div className={'image-choose-icon-upload'}>
        <div className={'image-choose-icon-upload-container'}>
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.3965 32.6667V15.6528L32.6881 22.9444L33.7298 22.0278L24.702 13L15.6743 22.0278L16.7159 22.9444L24.0076 15.6528V32.6667H25.3965ZM12.8965 33.8333H14.2854V36.6111H35.1187V33.8333H36.5076V38H12.8965V33.8333Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default GalleryCMSImageChoose
