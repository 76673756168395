import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faTimesCircle,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  reqSetIsShowBookingAppointmentForm,
  reqSetIsShowVideo,
  reqSetPage,
} from "../../reduxs/home/action";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  reqGetCustomerProfile,
  reqGetUserProfile,
} from "../../reduxs/user/action";
import preferenceApi from "../../apis/api/preference";
import { toast } from "react-toastify";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import jwtAuth from "../../apis/utils/jwtAuth";
import { PAGES, WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import { classNames } from "../../helper/utils";
import { reqSetIsShowFilter } from "../../reduxs/unit-explore/action";
import { IconStar, IconStarFill } from "../svgs/icons";
import socket from "../../helper/socket";
import userIcon from "../../assets/images/user.svg";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

library.add(
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faCalendarCheck,
  faTimesCircle
);
const BottomNavigation = ({ isPresentation }) => {
  const page = useSelector((state) => state.home.page);
  const history = useHistory();
  const dispatch = useDispatch();
  const customerId = useQuery().get("customer");
  const customer = useSelector((state) => state.user.customer);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const preference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const authUser = useSelector((state) => state.user.data);
  const isShowBookingAppointmentForm = useSelector(
    (state) => state.home.isShowBookingAppointmentForm
  );
  const isShowVideo = useSelector((state) => state.home.isShowVideo);

  useEffect(() => {
    dispatch(reqGetUserProfile());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_LOGOUT) {
          handleLogout();
        }
        if (content.action === ACTION_NAME.CLICK_END_SESSION) {
          handleShowPostSessionPopup();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      getCustomerPreference();
      getCustomerDetail();
    }
  }, [customerId]);

  const onClickUserIcon = () => {
    window.location.href = "/cms";
  };

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleShowPostSessionPopup = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION);
    }
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetIsShowVideo(false))
    dispatch(reqSetPage(PAGES.SESSION_PAGE));
    // dispatch(reqSetIsShowPostSessionPopup(true));
  };

  const renderEndSessionBtn = () => {
    if (!customer) return;
    if(page === PAGES.ONBOARD_PAGE && !isShowVideo) return;

    return (
      <span className="btn-text" onClick={() => handleShowPostSessionPopup()}>
        END SESSION
      </span>
    );
  };

  const handleSavePreference = () => {
    if (!selectedUnit) {
      return toast.error("Please select an unit!");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi
        .savePreference(data)
        .then(() => {
          toast.success("Saved Preference successfully!");
          getCustomerPreference();
          analytics.track("Agent Saved Property", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientEmail: customer?.email,
            clientPhone: customer?.mobile,
            propertyId: selectedUnit?.id,
          });
        });
    }
  };

  const handleUnsavePreference = () => {
    if (!selectedUnit) {
      return toast.error("Please select an unit!");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi
        .savePreference(data)
        .then(() => {
          toast.success("Unsaved Preference successfully!");
          getCustomerPreference();
          analytics.track("Agent Unsaved Property", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientEmail: customer?.email,
            clientPhone: customer?.phone,
            propertyId: selectedUnit?.id,
          });
        });
    }
  };

  const renderStarBtn = () => {
    if (page === PAGES.AMENITIES_PAGE) return null
    if (!selectedUnit) {
      return (
        <span className="btn-item" onClick={() => { }}>
          <IconStar />
        </span>
      );
    }

    const unitIds = getUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span className="btn-item" onClick={() => handleUnsavePreference()}>
          <IconStarFill />
        </span>
      );
    }

    return (
      <span className="btn-item" onClick={() => handleSavePreference()}>
        <IconStarFill fill='#1A1A1A' />
      </span>
    );
  };

  const getUnitIds = () => {
    const units = preference?.units || [];

    return units.map((item) => item.id);
  };

  const handleLogout = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LOGOUT);
    }
    dispatch(reqSetCustomerProfile(""));
    jwtAuth.removeToken();
    history.push("/holding");
  };

  const handleShowMakeAppointmentForm = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(true));
    dispatch(reqSetIsShowFilter(false));
    dispatch(unitExploreAct.reqSetIsShowUnitList(false));
  };

  const handleCloseMakeAppointmentForm = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(false));
    dispatch(reqSetIsShowFilter(true));
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const renderLogoutBtn = () => {
    if (!authUser) return;

    return (
      <span
        onClick={handleLogout}
        className="me-3 cursor-pointer"
        style={{ fontSize: "20px" }}
      >
        <FontAwesomeIcon icon={["fas", "sign-out-alt"]} />
      </span>
    );
  };

  const goToCms = () => {
    history.push("/cms");
  }

  const renderMakeAppointmentBtn = () => {
    if (!authUser) return;

    if (isShowBookingAppointmentForm) {
      return (
        <span
          onClick={handleCloseMakeAppointmentForm}
          className="me-2 cursor-pointer"
          style={{ fontSize: "20px" }}
        >
          <FontAwesomeIcon icon={["fas", "times-circle"]} />
        </span>
      );
    }

    return (
      <span
        onClick={handleShowMakeAppointmentForm}
        className="me-2 cursor-pointer"
        style={{ fontSize: "20px" }}
      >
        <FontAwesomeIcon icon={["fas", "calendar-check"]} />
      </span>
    );
  };

  return (
    <div
      className={classNames(
        "wrap-bottom-navigation",
        page === PAGES.SESSION_PAGE && "hide-bottom",
        isPresentation && "hide",
      )}
    >
      <div className="left-group-btn">
        {page === PAGES.ONBOARD_PAGE && !isShowVideo && renderLogoutBtn()}
        {/* BAILEY removed while in dev
        {renderMakeAppointmentBtn()} 
        */}
        {renderEndSessionBtn()}
      </div>
      <div className="right-group-btn">
        {customer && (page !== PAGES.ONBOARD_PAGE || isShowVideo) && <span className="customer-name">{customer.name}</span>}
        {page === PAGES.ONBOARD_PAGE && !isShowVideo ? <img className="cursor" src={userIcon} onClick={goToCms} /> : renderStarBtn()}
      </div>
    </div>
  );
};

export default BottomNavigation;
