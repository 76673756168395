import * as Types from './type';
import modalApi from '../../apis/api/modal';

const actSetExploreModal = (data) => ({
  type: Types.SET_EXPLORE_MODAL,
  data,
});

export const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

export const actSetIsShowCenterExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_CENTER_EXPLORE_MODAL,
  data,
});

export const actSetIsExploreAmenities = (data) => ({
  type: Types.SET_IS_EXPLORE_AMENITIES,
  data,
});

export const reqSetExploreModal = (id) => (dispatch) => {
  if (!id) return;

  return modalApi
    .getModalDetail(id)
    .then((data) => {
      dispatch(actSetExploreModal(data));
    })
};

export const reqSetIsShowExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowExploreModal(data));
}

export const reqSetIsShowCenterExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowCenterExploreModal(data));
}

export const reqSetIsExploreAmenities = (data) => (dispatch) => {
  return dispatch(actSetIsExploreAmenities(data));
}
