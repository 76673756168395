import React, { useMemo, useRef, useState } from "react";
import classNames from "classnames";

import dropdownIcon from "../../../assets/images/cms-dropdown.svg";

import "./index.scss";

export const CMSSelect = ({
  value,
  onChange,
  placeholder,
  renderOption,
  error,
  type,
  index,
  options,
  label,
}) => {
  const divRef = useRef(null);
  const [opened, setOpened] = useState(true);

  const currentValue = useMemo(() => {
    if (value === null || value === undefined) return placeholder;

    const selected =
      index === "available"
        ? options.find((op) => op.value === value)
        : options.find((op) => op.label === value);

    return selected?.label ?? placeholder;
  }, [options, placeholder, value]);

  return (
    <div className="select">
      <label htmlFor="input">{label}</label>
      <div ref={divRef} onClick={() => setOpened(!opened)}>
        {!!currentValue && (
          <div className="label">
            <p>{currentValue}</p>
            <div className="img">
              <img src={dropdownIcon} alt="cms-dropdown-icon" />
            </div>
          </div>
        )}
        <div
          className={classNames("drop-item", {
            open: opened,
          })}
        >
          {options.map((option, index) => (
            <div
              key={`drop-item-${index}`}
              className={classNames("hover", {
                active: value === option.value,
              })}
              onClick={() => {
                onChange?.(option);
                setOpened(false);
              }}
            >
              {renderOption ? (
                renderOption(option, index)
              ) : (
                <div className="item-text">
                  <p>{option.label}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CMSSelect;
