export const formatMoney = (price, showUsd = false) => {
  if (!price) return 0;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const fraction = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let value = ""

  if(Number(price) % 1 === 0) {
    value =  formatter.format(price)
  } else {
    value = fraction.format(price);
  }

  return showUsd ? value.substring(1) + ' USD': value;
  
};

export default {
  formatMoney,
};
