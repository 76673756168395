import React, { useRef, useState, useEffect } from "react";
import iconPlay from "../../../assets/images/play.svg";
import { getImagePath, getUploadedServerMediaUrl } from "../../../helper/media";
import "../../home-gallery/index.scss";
import "./index.scss";
import { AwModal } from "../../aw-modal/aw-modal";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../constants/options";
import socket from "../../../helper/socket";

const PlayVideo = ({ urlBg, urlVideo = '' }) => {
  const videoLink = urlVideo.startsWith('https') ? urlVideo : getImagePath(urlVideo)
  const videoRef = useRef(null);
  return (
    <div className="wrap-video-film">
      <video
        loop
        ref={videoRef}
        autoPlay={true}
        preload="auto"
        muted
        // style={{ backgroundImage: `url(${getImagePath(urlBg)})` }}
      >
        <source src={videoLink} type="video/mp4" />
      </video>
    </div>
  );
};

const Films = ({ data, isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PLAY_FILM_GALLERY_PAGE) {
          handlePlayVideo(content?.data?.url);
        }
        if (content.action === ACTION_NAME.STOP_FILM_GALLERY_PAGE) {
          onStopVideo();
        }
      });
    }
  }, []);
  const handlePlayVideo = (url) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_FILM_GALLERY_PAGE, {
        url
      });
    }
    setUrlVideo(url);
    setShowVideo(true);
  };
  const onStopVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_FILM_GALLERY_PAGE);
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  const films = (data || []).filter(e => e.media[0].isActive !== undefined ? e.media[0].isActive : true).sort((a, b) => (a.media?.[0]?.order ?? a.media?.[1]?.order ?? 999) - (b.media?.[0]?.order ?? b.media?.[1]?.order ?? 999));

  return (
    <div className="films-content">
      {films.map((i, idx) => {
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity ? "infinity" : ""}`}
            style={{
              backgroundImage: `url(${getUploadedServerMediaUrl(i?.thumbnail)}`,
            }}
            onClick={() => handlePlayVideo(getUploadedServerMediaUrl(i.videoUrl))}
          >
            <div className="card-films-title">{`${i.name}`}</div>
            <div className="card-films-sub">{i.description}</div>
            <img src={iconPlay} alt="play-icon" />
          </div>
        )
      })}
      {isShowVideo && (
        <AwModal show={isShowVideo} onClose={onStopVideo}>
          <PlayVideo
            urlBg={data.thumbnail}
            urlVideo={urlVideo}
            onStopVideo={onStopVideo}
          />
        </AwModal>
      )}
    </div>
  );
};
export default Films;
