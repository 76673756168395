import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import unitApi from "../../apis/api/unit";
// import amenityApi from "../../apis/api/amenities";
import { FormControl, InputGroup } from "react-bootstrap";
import { debounce } from "../../helper/unit";
import { useDispatch } from "react-redux";
import {
  reqSetIsShowFilter,
  reqSetSelectedUnit,
} from "../../reduxs/unit-explore/action";
import { reqSetIsTransparent, reqSetPage } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { PAGES } from "../../constants/options";

const SearchV2 = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState();
  const [units, setUnits] = useState([]);
  // const [amenities, setAmenities] = useState([]);
  useEffect(async () => {
    //
    // const poiRes = await poiApi.getPoisList(data);
    // // setPois(poiRes.data);
    handleSearch();
  }, [keyword]);

  const getSearch = async () => {
    const data = {
      search: keyword,
    };
    const unitRes = await unitApi.getUnitList(data);
    // const amenityRes = await amenityApi.getAmenitiesList(data);
    // setAmenities(amenityRes.data);
    setUnits(unitRes.data);
  };

  const handleSearch = useCallback(debounce(getSearch, 600), [keyword]);

  const handleClickUnit = (item) => {
    dispatch(reqSetSelectedUnit(item.id));
    dispatch(reqSetIsShowFilter(true));
    dispatch(reqSetIsTransparent(false));
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  return (
    <div className="basic-page search-page">
      <div
        className="bg-image-page"
        style={{ backgroundImage: `url(./images/bg-holding.png)` }}
      >
        {/*<div className="search-input">*/}
        {/*  <input*/}
        {/*    onChange={(e) => setKeyword(e.target.value)}*/}
        {/*    type="text"*/}
        {/*    placeholder="Search..."*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="wrapper row">*/}
        {/*  {units.map((i, idx) => {*/}
        {/*    return (*/}
        {/*      <div key={`${idx}-units`} className="col-md-3 col-4 mb-4">*/}
        {/*        <div className="card-search">*/}
        {/*          <span className="title">{i.unit}</span>*/}
        {/*          <span className="textSub">{i.description}</span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*  {amenities.map((i, idx) => {*/}
        {/*    return (*/}
        {/*      <div key={`${idx}-amenities`} className="col-md-3 col-4 mb-4">*/}
        {/*        <div className="card-search">*/}
        {/*          <span className="title">{i.unit}</span>*/}
        {/*          <span className="textSub">{i.description}</span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}
        <div className="search-page-session">
          <span className="title-search-page">Search</span>
          <div className="input-wrapper">
            <InputGroup>
              <FormControl
                className
                placeholder="Search..."
                onChange={(e) => setKeyword(e.target.value)}
              />
            </InputGroup>
            <button type="submit" className="btn-enter">
              Search
            </button>
          </div>
          <div className="wrap-search-list">
            {units.map((unit, idx) => {
              return (
                <span
                  key={`${idx}-units`}
                  className="item-search"
                  onClick={() => handleClickUnit(unit)}
                >
                  Residence {unit.name}
                </span>
              );
            })}
            {/* {amenities.map((amenity, idx) => {
              return (
                <span key={`${idx}-amenity`} className="item-search">
                  {amenity.name}
                </span>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchV2;
