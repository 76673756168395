import React from "react";
import './input.scss'
import { ChevronDown } from '../../../components/svgs/icons'

const GalleryOrderSelect = ({name, value, onChange, label, placeholder, disabled, options = []}) => {
  const isDefault = value === ""
  return(
    <div className={'gallery-cms-wrapper-input'}>
      <div className={'gallery-cms-wrapper-input-container'}>
        <label>{label}</label>
        <div  style={{position: 'relative'}}>
          <select disabled={disabled} style={{marginLeft: '-5px', color: !isDefault ? "#1A1A1A" : "#B2B2B2"}} name={name} value={value} onChange={onChange}>
            {placeholder && <option value="">{placeholder}</option>}
            {options.map(opt => (
              <option key={opt.value} value={opt.value}>{`${opt.text}`}</option>
            ))}
          </select>
          <ChevronDown style={{ width: 11.4, position: 'absolute', top: '45%', right: '0px' }} fill={'#1A1A1A'} />
        </div>
      </div>
    </div>
  )
}
export default GalleryOrderSelect
