import React from "react";
import "./search.scss";

export const CMSFaqSearch = () => {
  return (
    <div className="cms-search-wrapper">
      <div className="cms-search-header">
        <div className="cms-search-header-text">
          Search
        </div>
      </div>

      <div className="cms-search-container">
        <div className="cms-search-body">
          <div className="cms-search-input-container">
            <div className="cms-search-input">
              <input placeholder="Search"/>
            </div>
            <div className="cms-search-line"/>
          </div>
          <button className="cms-search-btn">
            <span className="cms-search-btn-text">
              SEARCH
            </span>
          </button>
        </div>

        <div className="cms-search-result">
          {1 === 1 ? [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
            <div key={item} className="cms-search-result-item">
              <span>Alphabetical search results</span>
            </div>
          )) : (
            <div className="cms-search-no-result">
              <span>No results found</span>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};
