import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import Select from 'react-select';
import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from '../../../apis/api/customer';
import { phoneRegExp } from "../../../helper/validation";

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("Please fill out this field."),
	surname: yup.string().trim().required("Please fill out this field."),
	email: yup.string().trim().email("Please enter a valid email").required("Please fill out this field."),
	mobile: yup.string().trim().min(8).required("Please fill out this field.").matches(phoneRegExp, 'Please enter a valid phone'),
	city: yup.string().trim().required("Please fill out this field."),
	country: yup.string().trim().required("Please fill out this field."),
	nationality: yup.string().trim().required("Please fill out this field."),
	purchaseReason: yup.string().trim().required("Please fill out this field."),
});

const AddCustomerModal = ({ show, setShow, onCreateSuccess }) => {
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [city, setCity] = useState('');
	const [country, setCountry] = useState('');
	const [nationality, setNationality] = useState('');
	const [purchaseReason, setPurchaseReason] = useState('');
	const [errors, setErrors] = useState({}) 

	const handleCreateCustomer = async () => {		
		try {
			const data = {
				userGroup: "606d6f356af09f50f367ee23", //investor
				firstname,
				surname,
				email,
				mobile,
				city,
				country,
				nationality,
				purchaseReason,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.createCustomer(result);
			if (res.data) {
				toast.success("Customer created successfully!"); 
				handleClose && handleClose();
				onCreateSuccess && onCreateSuccess();
			}
		} catch (err) {
			if(err.inner) {
				const errs = {}
				err.inner.forEach(err => {
					errs[err.path] = err.message;
				})
				setErrors(errs);
			}
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal
			className="wrap-create-customer-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>CREATE NEW CUSTOMER</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>*First Name</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							className="form-control"
							placeholder="First Name"
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<span className="error">{errors?.firstname}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>*Last Name</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							className="form-control"
							placeholder="Last Name"
							onChange={(e) => setSurName(e.target.value)}
						/>
						<span className="error">{errors?.surname}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>*Email</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							className="form-control"
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<span className="error">{errors?.email}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>*Phone</Form.Label>
						<FormControl
							id="mobile"
							name="mobile"
							type="input"
							value={mobile}
							className="form-control"
							placeholder="Phone"
							onChange={(e) => setMobile(e.target.value)}
						/>
						<span className="error">{errors?.mobile}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>*City</Form.Label>
						<FormControl
							id="city"
							name="city"
							type="input"
							value={city}
							className="form-control"
							placeholder="City"
							onChange={(e) => setCity(e.target.value)}
						/>
						<span className="error">{errors?.city}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>*Country</Form.Label>
						<FormControl
							id="country"
							name="country"
							type="input"
							value={country}
							className="form-control"
							placeholder="Country"
							onChange={(e) => setCountry(e.target.value)}
						/>
						<span className="error">{errors?.country}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>*Nationality</Form.Label>
						<FormControl
							id="nationality"
							name="nationality"
							type="input"
							value={nationality}
							className="form-control"
							placeholder="Nationality"
							onChange={(e) => setNationality(e.target.value)}
						/>
						<span className="error">{errors?.nationality}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>*Reason for Purchase</Form.Label>
						<Select
							className=""
							classNamePrefix="select"
							value={{ value: purchaseReason || '', label: purchaseReason || 'Select a reason for purchase'}}
							options={[
								{
									value: 'Primary Residence',
									label: 'Primary Residence',
								},
							]}
							name="purchaseReason"
							isSearchable={false}
							menuPortalTarget={document.body}
							styles={{
								menuPortal: base => ({...base, zIndex: 9999})
							}}
							onChange={(item) => setPurchaseReason(item?.value)}
            />
						<span className="error">{errors?.purchaseReason}</span>
					</Form.Group>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button onClick={handleCreateCustomer}>Create</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCustomerModal;
