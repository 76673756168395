import React, { useRef } from "react";
import './input.scss'

const scrollToTop = () => {
  window.scrollTo({
    top: (document.documentElement.scrollHeight / 2) - 100,
    behavior: 'smooth'
  });
}

const GalleryCMSInput = ({name, value, onChange, label, placeholder}) => {
  const inputRef = useRef()


  const onInputFocus = () => {
    document.addEventListener("touchend", scrollToTop);
  }
  
  const onOutFocus = () => {
    document.removeEventListener("touchend", scrollToTop);
  }
  return(
    <div className={'gallery-cms-wrapper-input'}>
      <div className={'gallery-cms-wrapper-input-container'}>
        <label>{label}</label>
        <input onBlur={onOutFocus} onFocus={onInputFocus} ref={inputRef} placeholder={placeholder} name={name} value={value} onChange={onChange}/>
      </div>
    </div>
  )
}
export default GalleryCMSInput
