import Parser from "html-react-parser";
import React, {
  useRef,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  Polygon,
  GroundOverlay,
  BicyclingLayer,
} from "@react-google-maps/api";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
import _ from "lodash";
import { config, mapStyles } from "./map-config";
import { POIs as FAKE_DATA } from "./test-data";

import "./index.scss";
import {
  reqGetPoiList,
  reqSetActivePoi,
  reqSetActiveType,
} from "../../reduxs/locations/action";
import poi from "../../apis/api/poi";
import { RGBA_ASTC_10x5_Format } from "three";

const markerIcon = {
  path: "M18 36C22.7739 36 27.3523 34.1036 30.7279 30.7279C34.1036 27.3523 36 22.7739 36 18C36 13.2261 34.1036 8.64773 30.7279 5.27208C27.3523 1.89642 22.7739 0 18 0C13.2261 0 8.64773 1.89642 5.27208 5.27208C1.89642 8.64773 0 13.2261 0 18C0 22.7739 1.89642 27.3523 5.27208 30.7279C8.64773 34.1036 13.2261 36 18 36ZM20.0925 14.823L17.8425 25.4093C17.685 26.1742 17.9078 26.6085 18.5265 26.6085C18.963 26.6085 19.6222 26.451 20.07 26.055L19.872 26.991C19.2262 27.7695 17.802 28.3365 16.5758 28.3365C14.994 28.3365 14.3212 27.387 14.7577 25.3687L16.4182 17.5658C16.5623 16.9065 16.4318 16.668 15.7725 16.5082L14.7577 16.326L14.9422 15.4688L20.0947 14.823H20.0925ZM18 12.375C17.4033 12.375 16.831 12.1379 16.409 11.716C15.9871 11.294 15.75 10.7217 15.75 10.125C15.75 9.52826 15.9871 8.95597 16.409 8.53401C16.831 8.11205 17.4033 7.875 18 7.875C18.5967 7.875 19.169 8.11205 19.591 8.53401C20.0129 8.95597 20.25 9.52826 20.25 10.125C20.25 10.7217 20.0129 11.294 19.591 11.716C19.169 12.1379 18.5967 12.375 18 12.375Z",
  fillColor: "#FFFFFF",
  fillOpacity: 1.0,
  strokeWeight: 0,
  scale: 0.75,
};

const activeMarkers = [];

let map;
let maps;

const onMapLoaded = (mapRef, mapsRef) => {
  map = mapRef;
  maps = mapsRef;
  map.setOptions({ styles: mapStyles.default.rules });
};

const Location = (props) => {
  const pois = useSelector((state) => state.poi.data);
  const activePoi = useSelector((state) => state.poi.activePoi);
  const activeType = useSelector((state) => state.poi.activeType);
  const dispatch = useDispatch();
  const mapRef = useRef(false);
  const [shouldFit, setShouldFit] = useState(false);

  useEffect(async () => {
    dispatch(reqGetPoiList());
    setShouldFit(true);
  }, []);

  useEffect(() => {
    // Fit the bounds to the currently shown markers
    return;
    if (shouldFit && activeMarkers.length && mapRef) {
      let bounds = new google.maps.LatLngBounds();
      activeMarkers.forEach((marker) => {
        bounds.extend(marker);
      });

      if (!!mapRef?.current?.state?.map) {
        mapRef.current.state.map.setOptions({ maxZoom: 16 });
        mapRef.current.state.map.fitBounds(bounds);
        mapRef.current.state.map.setOptions({ maxZoom: null });
      }
    }
    if (shouldFit) {
      setShouldFit(false);
    }
  });

  useEffect(() => {
    // Set the color of the map
    const key = activeType === "" ? "default" : activeType;
    const styles =
      mapStyles[Object.keys(mapStyles).includes(key) ? key : "default"].rules;
    if (!!mapRef?.current?.state?.map) {
      mapRef.current.state.map.setOptions({ styles });
    }
  });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI",
  });

  const setActivePoi = (data) => {
    dispatch(reqSetActivePoi(""));
    dispatch(reqSetActivePoi(data.id));
  };

  const clearActivePoi = () => {
    dispatch(reqSetActivePoi(""));
  };

  const getPoiNavIcon = (poi) => {
    let a = (
      <div
        className={`navigation-item type-${poi.category} ${
          activeType === poi.category ? "active" : ""
        }`}
        dangerouslySetInnerHTML={{ __html: poi?.navigation?.svg }}
      />
    );
  };
  const renderPoiNav = () => {
    function htmlToReactWithReplace(poi) {
      const replace = (domNode) => {
        if (activeType == poi.category && domNode.name == "circle") {
          domNode.attribs.class = "circle active";
        }
      };
      return Parser(
        !!poi?.navigation?.svg ? poi?.navigation?.svg : "<div></div>",
        { replace }
      );
    }

    const navGroups = pois.filter((poi) =>
      poi.action_types.includes("navigation")
    );
    return (
      <ul className="list-group">
        {navGroups.map((poi) => {
          return (
            <li
              key={`group_${poi.category}`}
              style={{ position: "relative" }}
              className={`navigation-item type-${poi.category} ${
                activeType === poi.category ? "active" : ""
              }`}
              onClick={() => {
                if (activeType === poi.category) {
                  dispatch(reqSetActivePoi(""));
                  dispatch(reqSetActiveType(""));
                } else {
                  dispatch(reqSetActivePoi(""));
                  dispatch(reqSetActiveType(poi.category));
                }
              }}
            >
              {htmlToReactWithReplace(poi)}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderMarker = (poi) => {
    if (!poi.action_types.includes("marker")) return;
    return (
      <Marker
        key={`${poi.id}_marker`}
        position={{
          lat: poi.marker.coordinates.lat,
          lng: poi.marker.coordinates.lng,
        }}
        icon={{
          ...markerIcon,
          path: poi.marker.path ? poi.marker.path : markerIcon.path,
          anchor: new google.maps.Point(0, 0),
        }}
        onClick={(e) => {
          setActivePoi(poi);
        }}
        onLoad={(e) => {
          activeMarkers.push(e.getPosition());
          setShouldFit(true);
        }}
        onUnmount={(e) => {
          let lat = e.getPosition().lat();
          let lng = e.getPosition().lng();
          activeMarkers.splice(
            activeMarkers.findIndex((am) => {
              return am.lng() === lng && am.lat === lat;
            }, 1)
          );
          setShouldFit(true);
        }}
        data={poi}
      >
        {activePoi === poi.id && poi.action_types.includes("info_window") && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.marker.coordinates.lat,
              lng: poi.marker.coordinates.lng,
            }}
            options={{ closeBoxMargin: "100px 20px 2px 2px" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: poi.info_window.content }}
            />
          </InfoWindow>
        )}
      </Marker>
    );
  };

  const renderOverlay = (poi) => {
    if (!poi.action_types.includes("overlay")) return;
    return (
      <>
        <GroundOverlay
          url={poi.overlay.url}
          bounds={poi.overlay.bounds}
          opacity={0.5}
          onClick={() => {
            setActivePoi(poi);
          }}
          onDblClick={(e) => {
            e.domEvent.preventDefault();
          }}
        />
        {activePoi === poi.id && poi.action_types.includes("info_window") && (
          <InfoWindow
            onCloseClick={clearActivePoi}
            position={{
              lat: -37.81321293099207,
              lng: 144.93801607899866,
            }}
            options={{ closeBoxMargin: "100px 20px 2px 2px", padding: "100px" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: poi.info_window.content }}
            />
          </InfoWindow>
        )}
      </>
    );
  };
  const renderPolygon = (poi) => {
    if (!poi.action_types.includes("polygon")) return;
    return (
      <>
        <Polygon
          key={`${poi.id}_drawing`}
          paths={poi.polygon.coordinates}
          onClick={() => {
            setActivePoi(poi);
          }}
          onLoad={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => activeMarkers.push(item));
            });
            setShouldFit(true);
          }}
          onUnmount={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => {
                let lat = item.lat();
                let lng = item.lng();
                activeMarkers.splice(
                  activeMarkers.findIndex((am) => {
                    return am.lng() === lng && am.lat === lat;
                  }, 1)
                );
              });
            });
            setShouldFit(true);
          }}
          options={poi.polygon.options}
        />
        {poi.action_types.includes("info_window") && activePoi === poi.id && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.polygon.coordinates[0].lat,
              lng: poi.polygon.coordinates[0].lng,
            }}
            options={{ closeBoxMargin: "100px 20px 2px 2px", padding: "100px" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: poi.info_window.content }}
            />
          </InfoWindow>
        )}
      </>
    );
  };

  const renderMap = () => {
    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={config.center}
        zoom={config.zoom}
        onLoad={(map, maps) => {
          onMapLoaded(map, maps);
          setShouldFit(true);
        }}
        options={{
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          isFractionalZoomEnabled: true,
        }}
      >
        {/* <BicyclingLayer></BicyclingLayer> */}

        {pois.map((poi) => {
          if (
            (poi?.visibility?.default && activeType === "") ||
            poi?.visibility?.categories?.includes(activeType)
          )
            return (
              <>
                {renderMarker(poi)}
                {renderPolygon(poi)}
                {renderOverlay(poi)}
              </>
            );
        })}
      </GoogleMap>
    );
  };

  const renderPanel = () => {
    if (!activePoi) return;
    const poi = pois.find((poi) => poi.id === activePoi);
    if (!poi.action_types.includes("panel")) return;
    return (
      <div className="panel">
        <span className="close" role="button" onClick={() => clearActivePoi()}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM14.707 8.707C14.8892 8.5184 14.99 8.2658 14.9877 8.0036C14.9854 7.7414 14.8802 7.49059 14.6948 7.30518C14.5094 7.11977 14.2586 7.0146 13.9964 7.01233C13.7342 7.01005 13.4816 7.11084 13.293 7.293L11 9.586L8.707 7.293C8.61475 7.19749 8.50441 7.12131 8.3824 7.0689C8.2604 7.01649 8.12918 6.9889 7.9964 6.98775C7.86362 6.9866 7.73194 7.0119 7.60905 7.06218C7.48615 7.11246 7.3745 7.18671 7.28061 7.28061C7.18671 7.3745 7.11246 7.48615 7.06218 7.60905C7.0119 7.73194 6.9866 7.86362 6.98775 7.9964C6.9889 8.12918 7.01649 8.2604 7.0689 8.3824C7.12131 8.50441 7.19749 8.61475 7.293 8.707L9.586 11L7.293 13.293C7.19749 13.3852 7.12131 13.4956 7.0689 13.6176C7.01649 13.7396 6.9889 13.8708 6.98775 14.0036C6.9866 14.1364 7.0119 14.2681 7.06218 14.391C7.11246 14.5139 7.18671 14.6255 7.28061 14.7194C7.3745 14.8133 7.48615 14.8875 7.60905 14.9378C7.73194 14.9881 7.86362 15.0134 7.9964 15.0123C8.12918 15.0111 8.2604 14.9835 8.3824 14.9311C8.50441 14.8787 8.61475 14.8025 8.707 14.707L11 12.414L13.293 14.707C13.4816 14.8892 13.7342 14.99 13.9964 14.9877C14.2586 14.9854 14.5094 14.8802 14.6948 14.6948C14.8802 14.5094 14.9854 14.2586 14.9877 13.9964C14.99 13.7342 14.8892 13.4816 14.707 13.293L12.414 11L14.707 8.707Z"
              fill="black"
            />
          </svg>
        </span>
        <div
          className="wrapper"
          dangerouslySetInnerHTML={{ __html: poi.panel.content }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="wrap-location">
        {isLoaded && renderMap()}
        <div className="card wrap-list-location side-card left-card">
          <h2 className="card-title">
            Transport
            <br />
            Options
          </h2>
          <div className="card-body">{renderPoiNav()}</div>
        </div>
        {renderPanel()}
      </div>
      {/* <h1 className="title-center-bottom f-title">Location</h1> */}
    </>
  );
};

export default Location;
