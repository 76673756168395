import React, { useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { debounce } from "lodash";

import SideNav from "../sidenav";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import MultiRangeSlider from "../multi-range-slider";
import {
  reqSetIsShowBookingAppointmentForm,
  reqSetIsShowEndGuideTenantSession,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
// import { reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import { reqSetIsShowGalleryModal } from "../../reduxs/district-future-detail/action";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import {
  BEDROOM_OPTION,
  AVAILABILITY_OPTIONS,
  PAGES,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import socket from "../../helper/socket";

export const MIN = 2560;
export const MAX = 10100;

const UnitFilter = (props) => {
  const { controls, refScene, setActiveObjectIds, isPresentation } = props;

  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const refFilterRangeLot = useRef();
  const transportOptionDistricts = useSelector((state) => state.home.transportOptionDistricts);
  const filterUnitBedroom = useSelector((state) => state.unitExplore.filterUnitBedroom);
  // const filterUnitPrice = useSelector((state) => state.unitExplore.filterUnitPrice);
  const filterUnitLotSize = useSelector((state) => state.unitExplore.filterUnitLotSize);
  const filterUnitAvailability = useSelector((state) => state.unitExplore.filterUnitAvailability);
  const isShowUnitList = useSelector((state) => state.unitExplore.isShowUnitList);
  const debounceTime = isPresentation ? 0 : 250;

  useEffect(() => {
    function handleUnitExplorerPageEvent(e) {
      onResetAllFilter({ auto_available: e?.detail === 'auto_available' });
    }
    document.addEventListener("UNIT_EXPLORER_PAGE", handleUnitExplorerPageEvent);
    return () => {
      document.removeEventListener("UNIT_EXPLORER_PAGE", handleUnitExplorerPageEvent);
    };
  }, []);

  useEffect(() => {
    const isFiltered =
      filterUnitBedroom.length ||
      filterUnitAvailability.length ||
      (filterUnitLotSize?.max !== MAX && filterUnitLotSize?.max != -1) ||
      (filterUnitLotSize?.min != -1 && filterUnitLotSize?.min !== MIN);

    if (!isFiltered) {
      setActiveObjectIds([]);
      if (!isPresentation) {
        socket.emitLightUp([], { turnAllOff: true });
      }
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(isFiltered));
  }, [filterUnitAvailability, filterUnitBedroom, filterUnitLotSize]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
      handleApplyFilter(content.data);
    } else if (content.action === ACTION_NAME.RESET_FILTER) {
      onResetAllFilter(content?.data?.options || {});
    } else if (content.action === ACTION_NAME.HIDE_FILTER) {
      onHideFilter();
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };

  const handleApplyFilter = (data) => {
    const filterName = data.name;
    const item = data?.item;

    switch (filterName) {
      case 'FILTER_AVAILABILITY':
        onFilterUnitAvailability(item, data?.filterUnitAvailability);
        break;
      case 'FILTER_BEDROOM':
        onChangeBedroom(item, data?.filterUnitBedroom);
        break;
      case 'FILTER_LOT_SIZE':
        onChangeLotSize(data.lotSize);
        break;
      default:
        break;
    }
  };

  const onChangeBedroom = (item, filterUnitBedroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item,
        filterUnitBedroom,
        name: 'FILTER_BEDROOM',
      });
    }
    let bedRoom;
    if (_.find(filterUnitBedroom, (el) => el.id === item.id)) {
      bedRoom = filterUnitBedroom.filter((i) => i.id !== item.id);
    } else {
      bedRoom = [...filterUnitBedroom, item];
    }
    dispatch(unitExploreAct.reqFilterUnitBedroom(bedRoom));
  };

  const onChangeLotSize = debounce((data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        lotSize: data,
        name: 'FILTER_LOT_SIZE',
      });
    }
    if (isPresentation && refFilterRangeLot?.current) {
      refFilterRangeLot?.current?.updateRange(data);
    }
    dispatch(unitExploreAct.reqFilterUnitLotSize(data));
  }, debounceTime);

  const onFilterUnitAvailability = (item, filterUnitAvailability) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item,
        filterUnitAvailability,
        name: 'FILTER_AVAILABILITY',
      });
    }
    let newAvailability;
    if (filterUnitAvailability?.find((i) => i.id == item.id)) {
      newAvailability = filterUnitAvailability.filter((i) => i.id !== item.id);
    } else {
      newAvailability = [...filterUnitAvailability, item];
    }
    dispatch(unitExploreAct.reqFilterUnitAvailability(newAvailability));
  };

  const handleClickTransportOptions = useCallback((ids, model) => {
    if (refScene?.current == null) {
      return;
    }
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const onResetAllFilter = (options = {}) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.RESET_FILTER, { options });
      socket.emitLightUp([], { turnAllOff: true });
    }
    if (refFilterRangeLot.current !== null) {
      refFilterRangeLot?.current?.updateRange({ min: MIN, max: MAX });
    }
    if (!options.auto_available) {
      dispatch(unitExploreAct.reqFilterUnitAvailability([]));
      dispatch(unitExploreAct.reqSetIsShowUnitList(false));
    } else {
      if (
        !filterUnitAvailability.length ||
        filterUnitAvailability.length !== 1 ||
        !filterUnitAvailability.every(f => f.value === AVAILABILITY_OPTIONS[0].value)
      ) {
        dispatch(unitExploreAct.reqFilterUnitAvailability([AVAILABILITY_OPTIONS[0]])); // set default to available
      }
    }
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(
      unitExploreAct.reqFilterUnitLotSize({
        min: MIN,
        max: MAX,
      })
    );

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;
    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach((id) => {
          let object = refScene.current.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        });
      });
    }
    dispatch(unitExploreAct.reqIsShowUnitDetail(false));
    dispatch(unitExploreAct.reqSetSelectedUnit(""));
    handleClickTransportOptions([], {});
    setActiveObjectIds([]);
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
    dispatch(reqSetIsShowBookingAppointmentForm(false));
    // dispatch(reqSetIsShowExploreModal(true));
    // dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
    dispatch(unitExploreAct.reqSetIsShowFilter(true));
    dispatch(reqSetIsTransparent(false));
  };

  const onHideFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  };

  if (isPresentation) {
    return null
  }

  return (
    <SideNav
      position="left"
      heading="Filters"
      id="filter"
      isDark={false}
      isTransparent={props.isTransparent}
    >
      <div className="scroll-filter" ref={pageRef}>
        {/* filter availability */}
        <div className="filter-group mb-1">
          <h2 className="heading">Availability</h2>
          <ul className="list-group flex flex-col">
            {AVAILABILITY_OPTIONS.map((item, key) => (
              <li
                onClick={() =>
                  onFilterUnitAvailability(item, filterUnitAvailability)
                }
                className={`rounded-0 border-1 mb-1 list-group-item ${
                  _.find(filterUnitAvailability, (i) => i.id == item.id) &&
                  "active"
                }`}
                key={`filter-item-${key}`}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>

        {/* filter bedrooms  */}
        <div className="filter-group mb-4">
          <h2 className="heading">Bedrooms</h2>
          <ul className="list-group flex flex-row justify-content-between flex-wrap">
            {BEDROOM_OPTION.map((item, key) => (
              <li
              onClick={() => onChangeBedroom(item, filterUnitBedroom)}
              className={`list-group-item rounded-0 ${
                _.find(filterUnitBedroom, (i) => i.id == item.id) && "active"
              }`}
                key={`filter-item-${key}`}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>

        {/* filter celling height */}
        <div className="filter-group mb-4">
          <h2 className="heading">SQFT</h2>
          <div className="d-flex justify-content-between flex-col">
            <div className="me-3 input-with-label">
              <input
                value={filterUnitLotSize.min || MIN}
                onChange={() => {}}
                className="form-control rounded-0"
                disabled
                type="number"
                placeholder="####"
              />
              <small className="input-label">Min sq.ft</small>
            </div>
            <div className="input-with-label">
              <input
                disabled
                value={filterUnitLotSize.max || MAX}
                onChange={() => {}}
                className="form-control rounded-0"
                type="number"
                placeholder="####"
              />
              <small className="input-label">Max sq.ft</small>
            </div>
          </div>
          <MultiRangeSlider
            key={2}
            min={MIN}
            max={MAX}
            minValue={filterUnitLotSize.min}
            maxValue={filterUnitLotSize.max}
            onChange={onChangeLotSize}
            ref={refFilterRangeLot}
          />
        </div>

        <div className="wrapper-button">
          <div
            className={`btn-bottom ${
              isShowUnitList ? "unActive-btn" : "active-btn"
            }`}
            onClick={() => onResetAllFilter()}
          >
            <span className={isShowUnitList && "txt-active"}>Clear filter</span>
          </div>
          <div
            className={`btn-bottom ${
              isShowUnitList ? "unActive-btn" : "active-btn"
            }`}
            onClick={() => onHideFilter()}
          >
            <span className={isShowUnitList && "txt-active"}>Hide filter</span>
          </div>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitFilter;
