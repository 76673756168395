import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import { PAGES } from "../../constants/options";

const TopNavCMS = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const logout = () => {
    jwtAuth.removeToken();
    history.push("/holding");
  };

  const isActive = (path) => {
    return path === pathname ? "active" : "";
  };

  const onCLickLogo = () => {
    history.push('/');
  }

  return (
    <div className="wrap-header-navigation">
      <img
        onClick={onCLickLogo}
        src="../images/logo-brickell.png"
        className="img-logo"
      />
      <div className="menu-items">
        <ul>
          <li
            className={isActive('/')}
          >
            <Link to="/cms/residences" className="text-decoration-none">
              <span>RESIDENCES</span>
            </Link>
          </li>
          <li
            className={isActive(PAGES.AMENITIES_PAGE)}
          >
            <Link to="/cms/amenities" className="text-decoration-none">
              <span>AMENITIES</span>
            </Link>
          </li>
          <li
            className={isActive(PAGES.EXPERIENCE_PAGE)}
          >
            <Link to="/cms/customers" className="text-decoration-none">
              <span>CUSTOMERS</span>
            </Link>
          </li>
          <li
            className={isActive(PAGES.VIEW_PAGE)}
          >
            <Link to="/cms/galleries" className="text-decoration-none">
              <span>GALLERY</span>
            </Link>
          </li>
          <li
            className={isActive(PAGES.TEAM_PAGE)}
          >
            <Link to="/cms/frequently-asked-questions" className="text-decoration-none">
              <span>FAQS</span>
            </Link>
          </li>
          <li
            onClick={logout}
            className={isActive(PAGES.GALLERY_LANDING_PAGE)}
          >
            <span>LOG OUT</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopNavCMS;
