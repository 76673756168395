import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import { IconArrowThin } from "../../svgs/icons";
import { useDispatch, useSelector } from "react-redux";
import { setUnitView, setWhereView } from "../../../reduxs/scene/action";
import { reqSetPage } from "../../../reduxs/home/action";
import { PAGES, WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../constants/options";
import { threePosition } from "../../../helper/threeHeper";
import socket from "../../../helper/socket";
import * as unitExploreAct from "../../../reduxs/unit-explore/action";

const ContentFloor = ({data, onClick, numberFloorSelected}) => {
  return (
    <>
      <div className="content-floor-header">
        <span className="content-floor-title">The Tower</span>
      </div>
      <div className="body-content">
        <div className="body-row">
          {data.map((item, idx) => {
            return (
              <div
                key={`renders-${idx}`}
                className={`car-floor ${(!numberFloorSelected || numberFloorSelected === item.id) ? "isSelected" : ""}`}
                onClick={() => onClick(item?.id)}
              >
                <div className="car-floor-title">Residence</div>
                <div className="car-floor-content">{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
const ContentPlans = ({data, numberFloorSelected, handleShowViewPlan}) => {
  const itemSelected = data?.find((i) => (i?.id === numberFloorSelected));

  return (
    <div className="content-plans">
      <div className="header-content">
        <span className="content-plans-title">Floors</span>
      </div>
      <div className="content-plans-container">
        {itemSelected?.floorplans?.map((item, cpIndex) => (
          <div
            key={cpIndex} className="content-plans-item"
            onClick={() => handleShowViewPlan(item)}
          >
            <span className="content-plans-text">{item?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
const PageView = ({data, controls, handleUnitClick, isPresentation }) => {
  const isInfinity = false
  const [numberFloorSelected, setNumberFloorSelected] = useState("");

  const selectedFloorplan = useSelector((state) => state.scene.unitView);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const dispatch = useDispatch();
  const setSelectedFloorplan = (data) => {
    dispatch(setUnitView(data))
  }
  const currentMediaList = selectedFloorplan?.media
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const isWhereView = useSelector((state) => state.scene.whereView);
  const pageRef = useRef(null);

  useEffect(() => {
    if(selectedFloorplan?.media?.length === undefined){
      handleHideViewPlan();
    }
    setSelectedMedia(selectedFloorplan?.media?.length > 0 ? selectedFloorplan?.media[0] : null)
  }, [selectedFloorplan])

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_FLOOR_VIEW_PAGE) {
          onSelectedFloor(content?.data?.floorId);
        }
        if (content.action === ACTION_NAME.SHOW_PLAN_VIEW_PAGE) {
          handleShowViewPlan(content?.data?.item);
        }
        if (content.action === ACTION_NAME.HIDE_PLAN_VIEW_PAGE) {
          handleHideViewPlan();
        }
        if (content.action === ACTION_NAME.SELECT_MEDIA_VIEW_PAGE) {
          handleSelectMedia(content?.data?.mediaItem);
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      });
    }
  }, []);

  const onSelectedFloor = (_id) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_FLOOR_VIEW_PAGE, {
        floorId: _id
      });
    }
    if (numberFloorSelected === _id) {
      setIsFloorSelected(false);
      setNumberFloorSelected("");
    } else {
      setIsFloorSelected(true);
      setNumberFloorSelected(_id);
    }
  };

  const handleShowViewPlan = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_PLAN_VIEW_PAGE, {
        item
      });
    }
    setSelectedFloorplan(item);
    dispatch(setWhereView('view'));
  };
  
  const handleHideViewPlan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_PLAN_VIEW_PAGE);
    }
    if (isWhereView === 'residence') {
      dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
      dispatch(unitExploreAct.reqSetSelectedUnit(selectedUnit?.id));
    } else {
      setSelectedFloorplan(null);
    }
  };

  const handleSelectMedia = (mediaItem) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_MEDIA_VIEW_PAGE, {
        mediaItem
      });
    }
    setSelectedMedia(mediaItem);
  }

  const onScroll = (event) => {
    if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div
      className="static-page-wf gallery-landing"
      ref={pageRef}
      onScroll={onScroll}
      style={{ backgroundImage: isPresentation ? "none" : undefined }}
    >
      {selectedFloorplan ? (
        <div className="page-view-detail">
          <div className="tab-wrapper">
            <div className="tab-title">Views</div>
            <div className="tab">
              <div className={`pvd-btn-back`} onClick={handleHideViewPlan}>
                <IconArrowThin style={{width: 31}} fill={'#B2B2B2'}/>
                <span className="tab-item">{isWhereView === 'residence' ? "CLOSE VIEW" : "ALL VIEWS"}</span>
              </div>
              {currentMediaList?.length > 0 && (
                <div className="tab-sep"/>
              )}
              {currentMediaList?.length > 0 && (
                <div className="tab-item-wrap">
                  {currentMediaList?.map((mediaItem) => {
                    return (
                      <div
                        key={`subTab-${mediaItem?.id}`}
                        className={`pvd-btn-tab`}
                        onClick={() => handleSelectMedia(mediaItem)}
                      >
                        <span className={`tab-item ${selectedMedia?.name === mediaItem.name ? 'active' : ''}`}>{mediaItem?.name}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <iframe
            style={{width: '100%', height: '100%'}}
            src={selectedMedia?.path}/>
        </div>
      ) : (
        <div className={`floor-plans-content ${isInfinity ? "infinity" : ""}`}>
          <ContentFloor numberFloorSelected={numberFloorSelected} onClick={onSelectedFloor} data={data}/>
          {isFloorSelected ? (
            <ContentPlans
              numberFloorSelected={numberFloorSelected}
              handleShowViewPlan={handleShowViewPlan}
              data={data}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default PageView;
