import React from "react";

const Divider = (props) => {
  const { imgSrc, style = {} } = props;

  return (
    <div className="divider" style={style}>
      <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} data-src={imgSrc} src={imgSrc} alt="" />
    </div>
  )
}

export default Divider;
