export const IS_INTRODUCTION = 'IS_INTRODUCTION';
export const RECEIVE_3D_SETTINGS = 'RECEIVE_3D_SETTINGS';
export const RECEIVE_ASSETS_LIST = 'RECEIVE_ASSETS_LIST';
export const RECEIVE_HOTSPOTS_LIST = 'RECEIVE_HOTSPOTS_LIST';
export const RECEIVE_FBX_FILES = 'RECEIVE_FBX_FILES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const RECEIVE_HOTSPOT_TEXTURES = 'RECEIVE_HOTSPOT_TEXTURES';
export const RECEIVE_PAGES_SETTINGS = 'RECEIVE_PAGES_SETTINGS';
export const SET_UNIT_VIEW = 'SET_UNIT_VIEW';
export const SET_WHERE_VIEW = 'SET_WHERE_VIEW';
