import React, { Fragment } from "react";

import { useImageLoaded } from "../../hooks";

const Image = ({ src, styles, className, imgClassName, styleImage }) => {
  const [ref, loaded, onLoad] = useImageLoaded();

  return (
    <Fragment>
      <img
        ref={ref}
        onLoad={onLoad}
        src={src}
        className={imgClassName || ""}
        style={styleImage}
      />
      {!loaded && (
        <div
          className={`first-loading absolute ${className ?? ""}`}
          style={{
            ...styles,
          }}
        />
      )}
    </Fragment>
  );
};

export default Image;
