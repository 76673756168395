import React, { useState } from "react";
import ImageBlock from "./ImageBlock";
import UnitDescription from "./UnitDescription";
import ImageGallery from "./ImageGallery";

const UnitDetailOne = (props) => {
  const { unit } = props;
  const [isShowGallery, setIsShowGallery] = useState(false);
  const [isShowFloorplan, setIsShowFloorplan] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const getGalleryImage = (index = 0) => {
    return unit?.gallery?.media[index]?.path;
  }

  const getFloorplanImage = (index = 0) => {
    return unit?.floorPlan?.media[index]?.path;
  }

  const onCloseGallery = () => {
    setIsShowGallery(false);
  }

  const onCloseFloorplan = () => {
    setIsShowFloorplan(false);
  }

  const onClickGalleryImage = (index) => {
    setActiveIndex(index);
    setIsShowGallery(true);
  }

  const onClickFloorplanImage = (index) => {
    setActiveIndex(index);
    setIsShowFloorplan(true);
  }

  return (
    <div className="wrap-unit-detail wrap-1 container">
      <div className="wrap-left-1">
        <div className="d-flex align-items-center">
          <UnitDescription unit={unit} />
        </div>
        <div className="unit-floorplan">
          <ImageBlock onClick={() => onClickFloorplanImage(0)} path={getFloorplanImage()} />
        </div>
      </div>
      <div className="wrap-right-1">
        <div className="unit-gallery-0">
          <ImageBlock onClick={() => onClickGalleryImage(0)} path={getGalleryImage(0)} />
        </div>
        <div className="unit-gallery-1">
          <ImageBlock onClick={() => onClickGalleryImage(1)} path={getGalleryImage(1)} />
        </div>
      </div>
      {
        isShowGallery && 
        <ImageGallery
          initialIndex={activeIndex}
          onClose={onCloseGallery}
          mediaItems={[
            unit?.gallery?.media[0],
            unit?.gallery?.media[1],
          ]}
        />
      }
      {
        isShowFloorplan &&
        <ImageGallery
          initialIndex={activeIndex}
          onClose={onCloseFloorplan}
          mediaItems={[ unit?.floorPlan?.media?.[0] ]}
        />
      }

    </div>
  );
}

export default UnitDetailOne;
